import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App.vue'
import router from './router'
import 'vuetify/dist/vuetify.min.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import mixin from 'mixin'
import Toast from "vue-toastification";
import VueConfirmDialog from 'vue-confirm-dialog'
import "vue-toastification/dist/index.css";
import store from './store/index'
import moment from 'moment'
import duration from 'moment-duration-format'

import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Sparklines from "vue-sparklines";

Vue.use(Sparklines);


Vue.use(VueSimpleAlert);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false


 

Vue.use(VueAxios, axios)

 axios.defaults.baseURL = 'https://bb.supreme7.eu/api'
 axios.defaults.headers.post['Content-Type'] = 'application/json';
 
//axios.defaults.headers["Content-Type"] = "application/json"
 

axios.interceptors.response.use((response) => response, (error) => {
  // whatever you want to do with the error
  let response = error.response
  store.state.snackbar = {show: true, message: '<i class="fas fa-bug deep-orange--text"></i> HIBA TÖRTÉNT! <br/><i class="fas fa-times red--text"></i> ' + error + ' <br/> <i class="fas fa-bug purple--text text--lighten-2"></i> ('+  response.config.url +')'}
 
  throw error;
});

//Vue.prototype.$http = $axios



Vue.use(VueConfirmDialog)
Vue.prototype.moment = moment
Vue.prototype.duration = duration


const toastOptions = {
  // You can set your default options here

  maxToasts: 3,
  pauseOnFocusLoss: false,
  timeout: 3000,
  toastClassName: "pa-1"

};


Vue.use(Toast, toastOptions);



new Vue({
  router,
  vuetify,
  mixin,
  Vuex,
  store,
  Sparklines,
  render: h => h(App)
}).$mount('#app')



