<template>
    <v-card class="my-2 pa-0" color="" elevation="1" >
      <v-toolbar dense elevation="0" class="grey lighten-3 pa-0 px-1">
        <i class="fas fa-hashtag fa-xs mr-1"></i> 
        <span>{{ settings_item.settings_param }}</span>
        <v-spacer></v-spacer>
          <v-btn @click="$emit('emitFunc', {function: 'editThisSetting', setting_data: settings_item})" color="grey" outlined small><i class="far fa-edit"></i></v-btn>
          <v-btn @click="$emit('emitFunc', {function: 'deleteThisSetting', setting_data: settings_item})" color="red white--text" outlined small><i class="far fa-trash-alt"></i></v-btn>
    
      </v-toolbar>
      <v-list-item  three-line class="py-2">
        <v-list-item-content >
          <v-list-item-subtitle class="grey--text ml-2 mb-3" v-if="settings_item.settings_hint"> # {{ settings_item.settings_hint }} </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-textarea hide-details rows="1" readonly background-color="white" auto-grow outlined :value="settings_item.settings_value"> </v-textarea>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        prop_settings_data: Object
    },

    methods: {


    },


    data(){

        return {
           
        }

    },

    mounted() {

        

    },   
    computed: {

      settings_item: function(){
        return this.prop_settings_data 
      }

    } 

    
}
</script>