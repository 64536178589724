<template>
  <div>
    <v-dialog v-model="saveInProgress" scrollable max-width="790">
      <v-card>
        <v-card-title :class="saveMessage.class" class="text-caption lighten-3 text-center d-block">
          {{ saveMessage.title }}
        </v-card-title>
        <v-card-text :class="saveMessage.class" class="text-caption lighten-5 pa-5 text-center">{{ saveMessage.message }}</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showfilterdialog" scrollable max-width="790">
      <v-card>
        <v-card-title class="text-caption lighten-3 text-center d-block">
          Szűrés
        </v-card-title>
        <v-card-text class="text-caption lighten-5 pa-5 text-center">

          <v-text-field @keydown.enter="getShopUrlCategories()" dense outlined v-model="globalFilters.search_text"></v-text-field>



        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent scrollable v-model="showEditDialog" max-width="100%">
      <v-card class="white" :loading="editDialogLoading">
        <v-card-title class="orange lighten-4" v-if="editShopurlData.id && editShopurlData.id != ''">Szerkesztés ({{ editShopurlData.id }})</v-card-title>
        <v-card-title class="green lighten-4" v-else>Új hozzáadása</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-2">
          <div v-for="(field, index) in editFieldList" :key="index">
            <div v-if="field.name == 'id'"></div>
            <div v-else-if="field.name == 'session_id'"></div>
            <div v-else-if="field.name == 'calculated_url'">
              <v-textarea outlined v-model="editShopurlData[field.name]" :label="field.name"></v-textarea>
            </div>

            <div v-else-if="field.name == 'priority_range'">
              <v-combobox search-input v-model="editShopurlData[field.name]" :loading="loading_all_unique_data" @input.native="editShopurlData[field.name] = $event.srcElement.value" :items="Object.keys(JSON.parse($store.state.settings.priority_ranges))" :label="field.name" outlined dense></v-combobox>
            </div>
              <div v-else-if="field.name == 'priority_sound_range'">
              <v-combobox search-input v-model="editShopurlData[field.name]" :loading="loading_all_unique_data" @input.native="editShopurlData[field.name] = $event.srcElement.value" :items="Object.keys(JSON.parse($store.state.settings.priority_sounds))" :label="field.name" outlined dense></v-combobox>
            </div>
            <div v-else-if="field.name == 'site_cms'">
              <v-combobox search-input v-model="editShopurlData[field.name]" :loading="loading_all_unique_data" @input.native="editShopurlData[field.name] = $event.srcElement.value" :items="allCms" :label="field.name" outlined dense></v-combobox>
            </div>
            <div v-else-if="field.name == 'site_prefix'">
              <v-combobox search-input v-model="editShopurlData[field.name]" :loading="loading_all_unique_data" @input.native="editShopurlData[field.name] = $event.srcElement.value" :items="allSitePrefix" :label="field.name" outlined dense></v-combobox>
            </div>

            <div v-else-if="field.name == 'category'">
              <v-combobox search-input v-model="editShopurlData[field.name]" :loading="loading_all_unique_data" @input.native="editShopurlData[field.name] = $event.srcElement.value" :items="allCategory" :label="field.name" outlined dense></v-combobox>
            </div>

            <div v-else-if="field.type == 'varchar'">
              <v-text-field outlined v-model="editShopurlData[field.name]" :label="field.name"></v-text-field>
            </div>
            <div v-else-if="field.name == 'url'">
              <span class="grey--text">URL FILTER PÉLDA: #settings_param#---PARAMÉTER---#</span>
              <v-textarea outlined v-model="editShopurlData[field.name]" :label="field.name"></v-textarea>
              <v-textarea outlined :label="field.name + ' DECODED'" :value="decodeURIComponent(editShopurlData[field.name])"></v-textarea>
            </div>
             
            <div v-else-if="field.type == 'longtext' || field.type == 'mediumtext'">
              <v-textarea outlined v-model="editShopurlData[field.name]" :label="field.name"></v-textarea>
            </div>

            <div v-else-if="field.type == 'int' && field.max_length === 1">
              <v-switch v-model="editShopurlData[field.name]" true-value="1" false-value="0" flat :label="field.name"></v-switch>
            </div>
            <div v-else-if="field.type == 'int' && field.max_length > 1">
              <v-text-field outlined type="number" v-model="editShopurlData[field.name]" :label="field.name"></v-text-field>
            </div>

            <div v-else>
              <v-text-field outlined v-model="editShopurlData[field.name]" :label="field.name"></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="align-center justify-center flex-row d-flex">
          <v-btn v-if="editShopurlData != ''" class="orange white--text" @click="saveShopUrl('edit')" small>Szerkesztés</v-btn>
          <v-btn small @click="saveShopUrl('new')" class="green white--text">Mentés újként</v-btn>
          <v-btn @click="closeEditDialog()" small class="green white--text">Mégse</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="globalFilters.show_url_list" max-width="800">
      <v-card class="pa-0" v-if="Object.keys(shopUrls).length > 0"  :loading="loading_shopurl_list">
        <v-card-text v-if="shopUrls.data.length > 0" class="pa-0">
          <v-card style="border: 1px dotted #444" class="my-2 pa-1" v-for="(item, index) in shopUrls.data" :key="index" :class="item.active == '1' ? 'active green lighten-3' : 'grey lighten-1'">
            <v-card-title class="shopurl-title justify-center align-center" :class="item.active == '1' ? 'green lighten-2' : 'grey lighten-2'">
              <span class="mr-2">[ {{ item.id }} ]</span> <span class="orange--text text--darken-2"> {{ item.site_prefix }} </span> <i class="fas fa-chevron-right fa-sm mx-2"></i> {{ item.category }} <i class="fas fa-chevron-right fa-sm mx-2"></i> {{ item.description }}
            </v-card-title>
            <v-divider class="grey lighten-1"></v-divider>
            <v-card-text class="pa-0">
              <v-row v-if="item.no_product_error_count > 0 || item.connect_error_count > 0 || item.no_product_on_first_page > 0">
                <v-row>
                  <v-col cols="6" md="4" class="pa-1">
                    <v-col cols="12" :class="item.no_product_error_count > 0 ? 'blue lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Nem található termék hiba: {{ item.no_product_error_count }}</v-col>
                  </v-col>
                  <v-col cols="6" md="4" class="pa-1">
                    <v-col cols="12" :class="item.connect_error_count > 0 ? 'red lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Kapcsolódási hiba: {{ item.connect_error_count }}</v-col>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-1">
                    <v-col cols="12" :class="item.no_product_on_first_page > 0 ? 'purple lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Nincs termék az első oldalon: {{ item.no_product_on_first_page }}</v-col>
                  </v-col>
                </v-row>
              </v-row>
              <v-row v-if="!compactView" :class="item.active == '1' ? ' green lighten-4' : 'grey lighten-4'">
                <v-col cols="4" md="4" class="pa-0 pr-1">
                  <v-col cols="12" :class="item.use_proxy ? 'purple lighten-5' : 'grey lighten-4'" class="pa-0 text-center">Proxy?</v-col>
                  <v-col cols="12" class="pa-0 pt-2 d-flex flex-column justify-center align-center">
                    <v-checkbox dense inset color="purple darken-2 " class="ma-0 pa-0 active-checkbox" readonly v-model="item.use_proxy" @click.native.stop @click="change(index, id, 'use_proxy', item.use_proxy ? 0 : 1)"></v-checkbox>
                  </v-col>
                </v-col>
                <v-col cols="4" md="4" class="pa-0 pr-1">
                  <v-col cols="12" :class="item.search_until_has_discount ? 'orange lighten-5' : 'grey lighten-4'" class="pa-0 text-center px-1 text-truncate">Kedvezményig keres?</v-col>
                  <v-col cols="12" class="pa-0 pt-2 d-flex flex-column justify-center align-center">
                    <v-checkbox dense inset color="orange darken-2 " class="ma-0 pa-0 active-checkbox" readonly v-model="item.search_until_has_discount" @click.native.stop @click="change(index, item.id, 'search_until_has_discount', item.search_until_has_discount ? 0 : 1)"></v-checkbox>
                  </v-col>
                </v-col>

                <v-col cols="4" md="4" class="pa-0">
                  <v-col cols="12" :class="item.active ? 'green lighten-5' : 'grey lighten-4'" class="pa-0 text-center">Aktív?</v-col>
                  <v-col cols="12" class="pa-0 pt-2 d-flex flex-column justify-center align-center">
                    <v-checkbox dense inset color="green darken-2 " class="ma-0 pa-0 active-checkbox" readonly v-model="item.active" @click.native.stop @click="change(index, item.id, 'active', item.active ? 0 : 1)"></v-checkbox>
                  </v-col>
                </v-col>
              </v-row>

              <v-row style="font-size: 12px">
                <v-row>
                  <v-col cols="4" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Utolsó indulás?</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_parser_start }}
                    </v-col>
                  </v-col>
                  <v-col cols="4" class="pr-0 pr-1 px-0">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Utolsó befejezés?</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_parser_end }}
                    </v-col>
                  </v-col>
                  <v-col cols="4" class="pr-0 px-0">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center font-weight-bold lighten-4">Utoljára frissült?</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center lighten-5">
                      {{ item.last_refresh }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-row>
              
              <v-row style="font-size: 12px">
                <v-row>
                  <v-col cols="4" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Utolsó oldal</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_page }}
                    </v-col>
                  </v-col>
                  <v-col cols="4" class="pr-0 pr-1 px-0">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Utolsó HTTP CODE?</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_http_code }}
                    </v-col>
                  </v-col>
                  <v-col cols="4" class="pr-0 px-0">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center font-weight-bold lighten-4">SESSION?</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center lighten-5">
                      {{ item.session_id }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-row>
              <v-row style="font-size: 12px">
                <v-row>
                  <template v-if="item.true_url != ''">
                  <v-col cols="12" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">TRUE Url</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                     <a :href="item.true_url" target="_blank"> {{ item.true_url }} </a>
                    </v-col>
                   
                  </v-col>
                  
                  </template>
                  <template v-else>

                    <v-col cols="12" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">URL</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ decodeURIComponent(item.url) }}
                    </v-col>
                    </v-col>
                    <v-col cols="12" v-if="item.calculated_url != ''" class="pr-0 ma-0 pr-1" >
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Kalkulált URL</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'green' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ decodeURIComponent(item.calculated_url) }}
                    </v-col>
                  </v-col>

                  </template>
                </v-row>
              </v-row>
              <v-row style="font-size: 12px">
                <v-row>
                   <v-col cols="2" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'red' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Priority</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'red' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.shopurl_priority }}
                    </v-col>
                  </v-col>
                  <v-col cols="5" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'purple' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Priority Range</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'purple' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.priority_range }}
                    </v-col>
                  </v-col>
                  <v-col cols="5" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'purple' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Priority Sound Range</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'purple' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.priority_sound_range }}
                    </v-col>
                  </v-col>
                 
                </v-row>
              </v-row>
              <v-row style="font-size: 12px">
                <v-row>
                  <v-col cols="6" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Last Session Pages</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_session_found_pages }}
                    </v-col>
                  </v-col>
                  <v-col cols="6" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Last Session Products</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_session_found_products }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-row>
              <v-row style="font-size: 12px">
                <v-row>
                  <v-col cols="12" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">DISCORD WEBHOOK</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.discord_webhook }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-row>
              <v-row style="font-size: 12px">
                <v-row>
                  <v-col cols="6" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Last Session Start</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_session_start }}
                    </v-col>
                  </v-col>
                  <v-col cols="6" class="pr-0 ma-0 pr-1">
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 text-center lighten-4 font-weight-bold">Last Session End</v-col>
                    <v-col cols="12" :class="item.active == '1' ? 'blue' : 'grey'" class="pa-0 d-flex flex-column justify-center align-center text-center lighten-5">
                      {{ item.last_session_end }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
            <v-divider class="grey lighten-1"></v-divider>
            <v-card-actions :class="item.active == '1' ? 'active green lighten-4' : 'grey lighten-2'">
              <v-spacer></v-spacer>
              <v-btn class="red white--text" @click="deleteShopUrl(item.id)" x-small>Törlés</v-btn>
              <v-btn text class="orange white--text" elevation="0" outlined x-small @click="editShopUrl('edit', item)"><i class="far fa-edit mr-1"></i> Szerkesztés </v-btn>
              <v-btn v-if="item.calculated_url != ''" text class="red white--text" elevation="0" outlined x-small :href="item.calculated_url" target="_blank"><i class="far fa-edit mr-1"></i> URL </v-btn>
              <v-btn text class="green white--text" elevation="0" outlined x-small :href="'https://bb.supreme7.eu/api/shopurlparser/start?shop_url_id=' + item.id + '&force_run=1'" target="_blank"> Futtatás </v-btn>
              <v-btn text class="blue white--text" elevation="0" outlined x-small :to="{ path: '/log?log_shop_url_id=' + item.id + '&filterLogCategories=true' }" target="_blank"><i class="far fa-edit mr-1"></i> LOG </v-btn>
              <v-btn x-small class="mx-1" :to="'/sessions?url_id=' + item.id" target="_blank"><i class="fas fa-at red--text"></i>SESSIONS</v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <v-alert text prominent type="error" icon="mdi-cloud-alert" dense class="ma-2"> Nincs találat</v-alert>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="grey lighten-3 py-1" v-if="globalFilters.page">
              <v-pagination v-model="globalFilters.page" :length="shopUrls.all_pages" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="12">
              <v-tabs background-color="grey lighten-3" class="grey lighten-2 py-2" v-model="tabselected" height="25" color="secondary" slider-color="grey" centered slider-size="0">
                <v-tabs-slider class="red py-2"></v-tabs-slider>

                <v-tab active-class="green lighten-3" class="text-caption" :disabled="shopUrls.active == 0" @click="setActiveInactive('1')"> Aktív ({{ shopUrls.active }}) </v-tab>

                <v-tab active-class="red lighten-3" class="text-caption" :disabled="shopUrls.inactive == 0" @click="setActiveInactive('0')"> Inaktív ({{ shopUrls.inactive }}) </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" align="right" class="pa-1 px-2">
              <v-btn small class="green white--text mx-1" @click="editShopUrl('add')">Új</v-btn>
              <v-btn small class="blue white--text mx-1" @click="compactView = !compactView">Részletes</v-btn>
              <v-btn small class="purple white--text mx-1" @click="getShopUrls()">Frissítés</v-btn>
              <v-btn small class="mx-1" @click="globalFilters.show_url_list = false">Bezárás</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-card>
    </v-dialog>
    <template>
      <v-container v-if="Object.keys(shopUrlCategories).length > 0">

        <v-row class="my-1"><v-spacer></v-spacer><v-btn @click="showfilterdialogaction()" class="green white--text">Szűrés</v-btn></v-row>

        <v-expansion-panels focusable  multiple >
          <v-expansion-panel v-for="(cms, i) in shopUrlCategories.cms" :key="i" class="my-2">

            <v-expansion-panel-header class="pa-0 px-2  text-overline" >
              <v-spacer></v-spacer>
            <v-img class="mx-2 " v-if="$store.state.settings['' + i + '_pushover_icon']" :src="$store.state.settings['' + i + '_pushover_icon']" max-height="30px" max-width="30"  />
            
            
              {{i.toUpperCase()}}

              <v-spacer></v-spacer>
            </v-expansion-panel-header>
             <v-system-bar class="purple lighten-5 text-caption">
                <v-spacer></v-spacer>
                [ <MomentAgo :date="cms.min_last_session_start"></MomentAgo> ] - [ <MomentAgo :date="cms.max_last_session_end"></MomentAgo>] [ <TimeDiff v-if="cms.min_last_session_start < cms.max_last_session_end" :old_date="cms.min_last_session_start" :new_date="cms.max_last_session_end" background="transpaernt"></TimeDiff> ]
                <v-spacer></v-spacer>
              </v-system-bar>
              <v-system-bar class="orange lighten-5 text-caption">
                <v-spacer></v-spacer>
                [ {{ cms.all_last_session_found_pages }} ] - [ {{ cms.all_last_session_found_products }} ]
                <v-spacer></v-spacer>
              </v-system-bar>
              <v-expansion-panel-content>

                <v-card outlined class="my-2" v-for="(item, index) in cms.site_prefixes" :key="index">
              <v-app-bar elevation="0" height="40" class="px-1 white--text" :class="globalFilters.site_prefix == item.site_prefix ? 'purple' : 'black'" dense>
                <v-btn v-if="!$store.state.ismobile" x-small @click="generateCliCommand(item.site_prefix)" class="mx-1"><i class="fas fa-terminal"></i></v-btn>
                <v-btn v-if="!$store.state.ismobile" text class="green white--text" elevation="0" outlined x-small :href="'https://bb.supreme7.eu/api/shopurlparser/start?site_prefix=' + item.site_prefix + '&active=1'" target="_blank"><i class="fas fa-paper-plane"></i></v-btn>
                <v-spacer></v-spacer>

                <v-btn x-small @click="select(item.site_prefix, '', item.all_active)" class="px-10 font-weight-bold text-caption mr-2"># {{ item.site_prefix }} </v-btn>

                <v-btn x-small v-if="item.all_active > 0" class="pa-0 px-3 green dotted white--text text-caption mx-1"><i class="fas fa-check white--text mr-2"></i> {{ item.all_active }}</v-btn>

                <v-btn x-small v-if="item.all_inactive > 0" class="text-caption pa-0 px-4 red white--text dotted mx-1"><i class="fas fa-times white--text mr-2"></i> {{ item.all_inactive }}</v-btn>

                <v-spacer></v-spacer>

                <v-btn x-small :to="'/sessions?site_prefix=' + item.site_prefix" class="mx-1" target="_blank"><i class="fas fa-at red--text"></i>SESSIONS</v-btn>
              </v-app-bar>
              <v-system-bar class="purple lighten-5 text-caption">
                <v-spacer></v-spacer>
                [ <MomentAgo :date="item.min_last_session_start"></MomentAgo> ] - [ <MomentAgo :date="item.max_last_session_end"></MomentAgo>] [ <TimeDiff v-if="item.min_last_session_start < item.max_last_session_end" :old_date="item.min_last_session_start" :new_date="item.max_last_session_end" background="transpaernt"></TimeDiff> ]
                <v-spacer></v-spacer>
              </v-system-bar>
              <v-system-bar class="orange lighten-5 text-caption">
                <v-spacer></v-spacer>
                [ {{ item.all_last_session_found_pages }} ] - [ {{ item.all_last_session_found_products }} ]
                <v-spacer></v-spacer>
              </v-system-bar>

              <v-divider class="my-1"></v-divider>
              <template v-if="urlCompactView">
                <v-card-text class="pa-2" v-if="item.categories">
                  <div v-for="(categories, cindex) in item.categories" :key="cindex">
                    <v-app-bar height="40" elevation="1" class="my-1 px-1" :class="globalFilters.site_prefix == item.site_prefix && globalFilters.category == categories.category ? 'purple lighten-3' : ''" dense>
                      <v-btn v-if="!$store.state.ismobile" x-small class="mx-1" @click="generateCliCommand(item.site_prefix, categories.category)"><i class="fas fa-terminal"></i></v-btn>
                      <v-btn v-if="!$store.state.ismobile" text class="green white--text" elevation="0" outlined x-small :href="'https://bb.supreme7.eu/api/shopurlparser/start?site_prefix=' + item.site_prefix + '&category=' + categories.category + '&active=1'" target="_blank"><i class="fas fa-paper-plane"></i></v-btn>
                      <v-spacer></v-spacer>
                      <v-btn class="px-10" x-small @click="select(item.site_prefix, categories.category, categories.all_active)"> {{ categories.category }} </v-btn>
                      <v-btn x-small v-if="categories.all_active > 0" class="pa-0 px-3 green lighten-4 text-caption mx-1">{{ categories.all_active }}</v-btn>

                      <v-btn x-small v-if="categories.all_inactive > 0" class="text-caption pa-0 px-4 red lighten-4 mx-1">{{ categories.all_inactive }}</v-btn>
                      <v-spacer></v-spacer>

                      <v-btn x-small class="mx-1" :to="'/sessions?site_prefix=' + item.site_prefix + '&category=' + categories.category" target="_blank"><i class="fas fa-at red--text"></i>SESSIONS</v-btn>
                    </v-app-bar>
                    <v-system-bar class="green lighten-5 text-caption">
                      <v-spacer></v-spacer>
                      [ <b>{{ categories.count_session }}</b
                      >x ] [ <span class="green--text" v-if="categories.min_last_session_start > categories.max_last_session_end"> Folyamatban ... </span> <MomentAgo :date="categories.min_last_session_start"></MomentAgo> ] - [ <MomentAgo v-if="categories.min_last_session_start < categories.max_last_session_end" :date="categories.max_last_session_end"></MomentAgo>] [
                      <TimeDiff v-if="categories.min_last_session_start < categories.max_last_session_end" :old_date="categories.min_last_session_start" :new_date="categories.max_last_session_end" background="transpaernt"></TimeDiff> ] <v-spacer></v-spacer>
                    </v-system-bar>
                    <v-system-bar class="orange lighten-5 text-caption">
                      <v-spacer></v-spacer>
                      [ {{ categories.all_last_session_found_pages }} ] - [ {{ categories.all_last_session_found_products }} ]
                      <v-spacer></v-spacer>
                    </v-system-bar>
                  </div>
                </v-card-text>
              </template>
            </v-card>

              </v-expansion-panel-content>
            
            </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <v-container v-else>
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-container>
    </template>
    <v-footer :style="this.$vuetify.breakpoint.mobile ? 'margin-bottom: 48px;' : 'margin-bottom: 0px'" fixed class="pa-0">
      <v-row>
        <v-col class="d-flex py-1">
          <v-spacer></v-spacer>
          <v-btn @click="reset()" small>RESET</v-btn>
          <v-btn @click="urlCompactView = !urlCompactView" v-model="urlCompactView" class="mx-1" active-class="orange white--text"><i :class="'fas ' + (urlCompactView ? ' fa-expand-alt' : '') + ' fa-compress-alt'"></i></v-btn>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import global_mixin from '../mixins/global_mixin'
export default {
  mixins: [global_mixin],
  data() {
    return {
      cli_command: false,
      loading: false,
      loading_shopurl_list: false,
      shopUrlCategories: {},
      shopUrls: {},
      editDialogLoading: this.editFieldList != false && this.editShopurlData != false ? false : true,
      editFieldList: false,
      showEditDialog: false,
      urlCompactView: false,
      editShopurlData: {},
      saveInProgress: false,
      saveMessage: {
        title: 'Mentés folyamatban',
        message: 'Kérlek várj ...',
        class: 'blue',
      },
      showfilterdialog: false,
      tabselected: 0,
      activeUrl: 0,
      compactView: true,
      inactiveUrl: 0,
      globalFilters: {
        page: 1,
      },
    }
  },
  methods: {
    showfilterdialogaction(){

      this.showfilterdialog = true

    },
    deleteShopUrl(id){


      this.$confirm(
        {
          message: `Biztosan törlöd?`,
          button: {
            no: 'Mégse',
            yes: 'Törlés'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm) {
             
              this.loading_shopurl_list = true
              this.shopUrls = []

              this.$http.get('shopurls/deleteshopurl?id=' + id + '').then((result) => {
                if (result.data.error === true) {
                
                this.alertMessage = result.data.message

                this.$toast.error(result.data.message, {
                  timeout: 2000,
                })
                
                } else {
                
                  this.loading_shopurl_list = false
                  this.getShopUrls()
                  this.$toast.success(result.data.message, {
                    timeout: 2000,
                  })
                }
              })



            

            }
          }
        }
      )

      

    },
    generateCliCommand(site_prefix = false, category = false) {
      var cli_command = 'Api Task ShopUrlTask start'
      var site_prefix_q_string = site_prefix ? 'site_prefix=' + site_prefix : ''
      var category_q_string = category ? 'category=' + category : ''

      var command = cli_command + " '" + (site_prefix_q_string !== '' ? site_prefix_q_string : '') + (category_q_string != '' ? '&' + category_q_string : '') + "'" // eslint-disable-line

      this.copyText(command)
    },
    closeEditDialog() {
      this.editShopurlData = false
      this.showEditDialog = false
    },
    editShopUrl(action, item = false) {
      this.getFieldList('shop_urls').then((response) => {
        this.getAllShopUrlUniqueData()
        if (action == 'edit' && item !== false) {
          this.editShopurlData = JSON.parse(JSON.stringify(item))
        }
        this.editFieldList = response.data.fields
        this.showEditDialog = true
      })
    },
    saveShopUrl(action) {
      var last_id = this.editShopurlData.id

      if (action == 'new') {
        this.editShopurlData.id = ''
        this.editShopurlData.last_page = ''
        this.editShopurlData.connect_error_count = ''
        this.editShopurlData.no_product_error_count = ''
        this.editShopurlData.no_product_on_first_page = ''
        this.editShopurlData.last_parser_start = ''
        this.editShopurlData.last_parser_end = ''

        this.editShopurlData.last_session_id = ''
        this.editShopurlData.last_session_start = ''
        this.editShopurlData.last_session_end = ''
        this.editShopurlData.last_session_found_pages = ''
        this.editShopurlData.last_session_found_products = ''

        this.editShopurlData.all_pages = ''
        this.editShopurlData.last_http_code = ''
        this.editShopurlData.calculated_url = ''
      }

      this.saveInProgress = true

      this.axios.post('shopurls/save', this.editShopurlData).then((result) => {
        if (result.data.error === true) {
          this.saveMessage.title = 'A mentés sikertelen!'
          this.saveMessage.message = result.data.message
          this.saveMessage.class = 'red'

          this.editShopurlData.id = last_id
        } else {
          this.saveMessage.title = 'A mentés sikeres!'
          this.saveMessage.message = result.data.message
          this.saveMessage.class = 'green'

          if (action == 'new') {
            this.editShopurlData.id = result.data.response.id
          }

          this.getAllShopUrlUniqueData()
          this.getShopUrlCategories()
          this.getShopUrls()
        }
      })

      setTimeout(() => {
        this.saveInProgress = false
      }, 3000)
    },

    setActiveInactive(state) {
      this.$set(this.globalFilters, 'active', state)
    },
    reset() {
      this.globalFilters = {}
      this.getShopUrlCategories()
    },

    select(site_prefix, site_category = false, all_active) {
      this.$set(this.globalFilters, 'site_prefix', site_prefix)
      this.$set(this.globalFilters, 'category', site_category)
      this.$set(this.globalFilters, 'show_url_list', true)
      if (all_active > 0) {
        this.$set(this.globalFilters, 'active', '1')
        this.tabselected = 0
      } else {
        this.$set(this.globalFilters, 'active', '0')
        this.tabselected = 1
      }
      this.$set(this.globalFilters, 'page', 1)
    },
    getShopUrls() {
      this.shopUrls = []

      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.$http.get('shopurls/search?' + queryString + '').then((result) => {
        if (result.data.error === true) {
          this.alertMessage = result.data.message
        } else {
          this.shopUrls = result.data.response

          this.alertMessage = false
        }
      })
    },

    getShopUrlCategories() {
      this.loading = true
      this.shopUrls = []

      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.$http.get('shopurls/shopurlbaselist?' + queryString + '').then((result) => {
        if (result.data.error === true) {
          this.alertMessage = result.data.message
        } else {
          this.shopUrlCategories = result.data.response
          this.alertMessage = false
        }
      })

      this.loading = false
      this.toTop()
    },
  },
  mounted() {
    this.getShopUrlCategories()
  },

  watch: {
    globalFilters: {
      deep: true,
      handler() {
        this.setRouterFromQueryFilterParams()

        if (this.globalFilters.show_url_list) {
          this.getShopUrls()
        }
      },
    },
  },
}
</script>
<style>
/deep/ .fill-width {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.shopurls-table td {
  font-size: 12px !important;
}
.shopurls-table tr {
  padding: 2px !important;
}
.shopurls-table.active tr {
  background: rgb(225, 238, 230) !important;
}

.shopurls-table.active th {
  background: rgb(193, 228, 206) !important;
}
.shopurls-table table {
  margin: 0 !important;
  border-collapse: separate !important;
  border-spacing: 1px 2px !important;
}
.shopurls-table table td {
  padding: 5px !important;
}
.shopurl-title,
.shopurl-title-actions {
  height: 32px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 2px;
}
.v-card.active .shopurl-title {
  background: rgb(230, 243, 231) !important;
}
.v-card.active .shopurl-title-actions {
  background: rgb(244, 248, 244) !important;
}
.active-checkbox .v-input__control {
  display: inline-block !important;
  height: 32px !important;
}
.dotted {
  border: 1px dotted rgb(156, 155, 155) !important;
}
</style>