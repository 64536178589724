import Vue from 'vue'
import VueRouter from 'vue-router'
import Log from '../views/Log.vue'
import Teszt from '../views/Teszt.vue'
import Settings from '../views/Settings.vue'
import Products from '../views/Products.vue'
import ProductFilters from '../views/ProductFilters.vue'
import Hahu from '../views/Hahu.vue'
import ShopUrls from '../views/ShopUrls.vue'
import ShopUrlss from '../views/ShopUrls__.vue'
import Curl from '../views/Curl.vue'
import Sessions from '../views/Sessions.vue'


Vue.use(VueRouter)



const routes = [
  { path: '*', redirect: '/log' },
  {
    path: '/log',
    name: 'Log',
    component: Log
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: Sessions
  },
  {
    path: '/hahu',
    name: 'Hahu',
    component: Hahu
  },
  {
    path: '/shopurls',
    name: 'Shopurls',
    component: ShopUrls
  },
  {
    path: '/shopurlss',
    name: 'Shopurlss',
    component: ShopUrlss
  },
  {
    path: '/curl',
    name: 'Curl',
    component: Curl
  },
  {
    path: '/teszt',
    name: 'Teszt',
    component: Teszt
  },
  {
    path: '/filters',
    name: 'Szűrők',
    component: ProductFilters
  },
  {
    path: '/beallitasok',
    name: 'Beállítások',
    component: Settings
  },
  {
    path: '/kedvencek',
    name: 'Kedvencnek jelölt termékek',
    component: Products,
    props: {default_filters: {

      order_by: 'last_seen',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      favourite: 1

    }}
  },
  {
    path: '/megfigyeltek',
    name: 'Megfigyelt termékek',
    component: Products,
    props: {default_filters: {

      order_by: 'last_seen',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      
      listpage_check_buy_again_set: 1

    }}
  },
  {
    path: '/akciok',
    name: 'Termékek',
    component: Products,

  },
  {
    path: '/termekek/uj-termekek',
    name: 'Új termékek',
    component: Products,

  },
  {
    path: '/termekek/uj-akciok',
    name: 'Új akciók',
    component: Products,
    props: {default_filters: {

      order_by: 'product_discount_percent',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      watched: 0

    }},
    

  },
  {
    path: '/termekek/uj-top-akciok',
    name: 'Új TOP akciók',
    component: Products,
    props: {default_filters: {

      order_by: 'product_discount_percent',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      watched: 0,
      is_top_price: 1

    }},
    

  },
  {
    path: '/termekek/uj-pushed-akciok',
    name: 'Új PUSHED akciók',
    component: Products,
    props: {default_filters: {

      order_by: 'top_price_percent',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      watched: 0,
      is_top_price: 1,
      watched_pushed: 1, 

    }},
    

  },
  {
    path: '/termekek/sharelist',
    name: 'ShareList',
    component: Products,
    props: {default_filters: {

      order_by: 'product_discount_percent',
      sort_by: 'DESC',
      do_show_products: 1,
      show_products: 1,
      page: 1,
      

    }},
    

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
