<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span :class="bold == 'true' ? 'font-weight-bold' : 'font-weight-light'" v-html="dateResponse" v-bind="attrs" v-on="on"></span>
    </template>
    <span>{{ old_date }} - {{ new_date }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    old_date: {},
    new_date: {},
    background: {},
    color: {},
    bold: {
      default: 'true'
    }

  },
  data() {
    return {
      greeting: 'Hello',
      diff_in_locale: false,
    }
  },
  methods: {
    getDiff(old_date, new_date) {

       var stringD = '--'
     
      var diff_in_seconds = this.moment(new_date).diff(old_date, 'seconds')
      var diff_in_minutes = Math.round(diff_in_seconds / 60)

      var diff_in_locale = (diff_in_minutes >= 60 ? Math.round(diff_in_minutes / 60) +' óra' : Math.round(diff_in_minutes) + ' perc' )

      switch (true) {
          case diff_in_seconds < 60:
            stringD = '<span class="'+ (this.background ? this.background : 'green lighten-5') +' '+ (this.color ? this.color : 'green--text') +' px-2">' + diff_in_seconds + ' másodperc </span>'
            break
          case diff_in_minutes < 10:
            stringD = '<span class="'+ (this.background ? this.background : 'green lighten-5') +' '+ (this.color ? this.color : 'green--text') +' px-2">' + diff_in_locale + '</span>'
            break
          case diff_in_minutes >= 10 && diff_in_minutes < 30:
            stringD = '<span class="'+ (this.background ? this.background : 'orange lighten-5') +' '+ (this.color ? this.color : 'orange--text') +'  px-2">' + diff_in_locale + '</span>'
            break
          case diff_in_minutes >= 30 && diff_in_minutes < 60:
            stringD = '<span class="'+ (this.background ? this.background : 'purple lighten-5') +' '+ (this.color ? this.color : 'purple--text') +'  px-2">' + diff_in_locale + '</span>'
            break
          default:
            stringD = '<span class="'+ (this.background ? this.background : 'red lighten-5') +' '+ (this.color ? this.color : 'red--text') +'  px-2">' + diff_in_locale + '</span>'
        }
      

      return stringD

    },
  },
  mounted() {
    

    setInterval(() => {
      this.diff_in_locale = this.getDiff(this.old_date, this.new_date)
    }, 10000)
  },
  created(){
    this.diff_in_locale = this.getDiff(this.old_date, this.new_date)
  },

  computed: {
    dateResponse() {
      return this.diff_in_locale
    },
  },
}
</script>