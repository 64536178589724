<template>
  <div>
    <v-dialog v-model="saveInProgress" scrollable max-width="790">
      <v-card>
        <v-card-title :class="saveMessage.class" class="text-caption lighten-3 text-center d-block">
          {{ saveMessage.title }}
        </v-card-title>
        <v-card-text :class="saveMessage.class" class="text-caption lighten-5 pa-5 text-center">{{ saveMessage.message }}</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editRangeDialog" persistent max-width="800">
      <v-card class="ma-0" height="80vh">
        <v-card-title class="purple lighten-4">Szűrő RANGE</v-card-title>

        <v-card-text v-if="addEditFilterData.filter_range">
          {{ addEditFilterData.filter_range }}
          <v-divider></v-divider>
          <v-switch label="Globális?" @change="globalRange($event)" v-model="addEditFilterData.filter_range[0]" true-value="global" class="d-inline-block" false-value=""></v-switch>
          <template v-if="!loadingRangeUrls">
            <Treeselect :flat="true" :show-count="true" v-if="addEditFilterData.filter_range != 'global'" v-model="addEditFilterData.filter_range" :multiple="true" :options="range_urls" />
          </template>
          <template v-else>
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </template>
        </v-card-text>
        <v-card-actions style="position: absolute; bottom: 0; right: 0"><v-spacer></v-spacer><v-btn @click="editRangeDialog = false" x-small>Bezárás</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addEditFilter" persistent max-width="800">
      <v-card class="ma-0">
        <template>
          <v-card-title v-if="addEditFilterData.id" class="orange lighten-4">Szerkesztés - {{ addEditFilterData.filter_description }}</v-card-title>
          <v-card-title v-else class="green lighten-4">Új szűrő létrehozása</v-card-title>
          <v-card-text>

            <v-text-field class="mt-4" dense outlined label="Szűrő elnevezése" v-model="addEditFilterData.filter_description"></v-text-field>
            <fieldset>
              <legend class="mx-1 pa-2"><v-btn class="green white--text" @click="editRange()" x-small>Range</v-btn></legend>

              <template>
                <v-alert v-if="addEditFilterData.filter_range == 'global'" class="ma-2" dense type="success">Globálisan érvényes</v-alert>
                <v-alert v-else-if="addEditFilterData.filter_range == ''" dense type="warning" class="ma-2"> Nincs URL kiválasztva</v-alert>
                <v-list v-else>
                  <v-list-item style="min-height: 20px; border-bottom: 1px dotted #999" dense v-for="(item, index) in addEditFilterData.filter_range" :key="index">
                    <v-list-item-content class="pa-0 ma-0 px-1" :class="'hashtag-' + (item.split('#').length - 1)">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </fieldset>
            
            <fieldset class="mt-2" style="border: 1px dotted #666" >
              <legend class="mx-1 pa-2"><v-btn class="green white--text" @click="addFilterCondition()" x-small>Feltétel hozzáadása</v-btn></legend>
           
            <template v-if="Object.keys(addEditFilterData).includes('filter_conditions') && Object.keys(addEditFilterData.filter_conditions).includes('conditions') && addEditFilterData.filter_conditions.conditions.length > 0 ">
               <span class="d-none pa-2 grey lighten-3 ma-1" >{{addEditFilterData.filter_conditions}}</span>
              <v-row>
               <v-col class="pa-0" cols="12" md="3"><v-switch hide-details="" class="ma-0 ml-2 mt-2" label="Új termékek" v-model="addEditFilterData.filter_conditions.new_products" :true-value="true" :false-value="false"></v-switch></v-col>
               <v-col class="pa-0" cols="12" md="3"><v-switch hide-details="" class="ma-0 ml-2 mt-2" label="Meglévő termékek" v-model="addEditFilterData.filter_conditions.exists_products" :true-value="true" :false-value="false"></v-switch></v-col>
               <v-col class="pa-0" cols="12" md="3"><v-switch hide-details="" class="ma-0 ml-2 mt-2" label="Push üzenet" v-model="addEditFilterData.filter_conditions.push_message" :true-value="true" :false-value="false"></v-switch></v-col>
            
               <v-col class="pa-0" cols="12" md="3"><v-switch hide-details="" class="ma-0 ml-2 mt-2" label="Automatikus megrendelés?" v-model="addEditFilterData.filter_conditions.autoorder" :true-value="true" :false-value="false"></v-switch></v-col>
            
              </v-row>

              <v-combobox v-if="addEditFilterData.filter_conditions.push_message" dense outlined :items="JSON.parse($store.state.settings.pushover_shopurl_filter_sounds)" v-model="addEditFilterData.filter_conditions.push_sound" label="Push hang"></v-combobox>


              <v-item-group class="pa-1" v-for="(condition, conditionindex) in addEditFilterData.filter_conditions.conditions" :key="conditionindex">
              
              <fieldset class="pb-2" style="border: 1px solid #333">
                <legend class="mx-1 pa-2">{{conditionindex}}. feltétel <v-btn class="red white--text" @click="removeFilterCondition(conditionindex)" x-small>x</v-btn></legend>
              <v-row >
                <v-col cols="12"  class="ma-0 pa-0 px-1 row">
                   
                  <v-col cols="6"><v-select v-model="addEditFilterData.filter_conditions.conditions[conditionindex].param" hide-details  :items="JSON.parse($store.state.settings.product_filter_statement_properties)" dense outlined label="param"></v-select></v-col>
                  <v-col cols="6"><v-select  v-model="addEditFilterData.filter_conditions.conditions[conditionindex].operator" hide-details class="col-6"  :items="JSON.parse($store.state.settings.url_filter_operators)" outlined dense label="operator"></v-select></v-col>
                   
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 px-1 row">
                  <v-col class="pa-0" cols="12" md="3"><v-switch hide-details="" class="ma-0 ml-2 mt-2" label="inverse" v-model="addEditFilterData.filter_conditions.conditions[conditionindex].inverse" :true-value="true" :false-value="false"></v-switch></v-col>
                  <v-col class="pa-0" cols="12" md="9"><v-text-field  v-model="addEditFilterData.filter_conditions.conditions[conditionindex].value" label="value" hide-details class="mt-2" outlined dense></v-text-field></v-col>
                  
                </v-col>
              </v-row>
              </fieldset>
              

              </v-item-group>
            </template>
            </fieldset>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeAddEditDialog()" x-small>Bezárás</v-btn>
          <v-btn @click="saveFilter()" x-small class="green white--text">Mentés</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

   <v-divider></v-divider>
    <v-btn @click="addFilter()" small color="my-2 green lighten-2 white--text"><i class="fas fa-plus mr-2"></i> Új szűrő létrehozása</v-btn>
    <v-divider></v-divider>
    <template v-if="productFilters.length > 0 && !loadingFilters">
      <v-card v-for="(filter, index) in productFilters" :key="index" class="my-4">
        <v-card-title class=" green lighten-5"><b>{{ filter.filter_description }}</b></v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pa-0 px-2">
          <fieldset class="pa-2">
            <legend class="ml-2 px-2">URL RANGE</legend>

            <span v-for="(item, index) in filter.filter_range.split(',')" :key="index" class="px-2 ma-1 pa-1 d-inline-block" :class="'hashtag-bg-' + (item.split('#').length - 1)">{{ item }}</span>
          </fieldset>
        </v-card-text>
        <v-card-text class="ma-0 pa-0 px-2">
          <fieldset class="pa-2">
            <legend class="ml-2 px-2">Feltételek</legend>
 
            
            <v-row v-for="(condition, cindex) in JSON.parse(filter.filter_conditions)" :key="cindex">


              <span v-if="cindex == 'push_message' ">
               <b>Push üzenet:</b>
               <i class="fas fa-ban red--text mx-1" v-if="condition === false"></i>
               <i class="fas fa-check green--text mx-1" v-if="condition === true"></i>
              </span>

              <span v-if="cindex == 'push_sound' ">
               <b>Push hang:</b>
               <v-btn x-small class="green white--text mx-1"><i class="fas fa-volume-up mr-2"></i> {{condition}}</v-btn>
              </span>

              <span v-if="cindex == 'new_products' ">
               <b>Új termékek között:</b>
               <i class="fas fa-ban red--text mx-1" v-if="condition === false"></i>
               <i class="fas fa-check green--text mx-1" v-if="condition === true"></i>
              </span>

              <span v-if="cindex == 'exists_products'">
               <b> Meglévő termékek között is:</b>
               <i class="fas fa-ban red--text mx-1" v-if="condition === false"></i>
               <i class="fas fa-check green--text mx-1" v-if="condition === true"></i>
              </span>

              <span class="mt-3 blue white--text pa-1" v-if="cindex == 'autoorder' && condition === true">
               <b> Automatikus megrendelés?:</b>
               <span class="white ml-2 pa-1">
               <i class="fas fa-ban red--text mx-1" v-if="condition === false"></i>
               <i class="fas fa-check green--text mx-1" v-if="condition === true"></i>
              </span>
              </span>


              <span v-if="cindex == 'conditions'">
                <v-divider class="my-2"></v-divider>
               <b> Feltételek:</b>
               <v-row v-for="(condition_item, ccindex) in condition" :key="ccindex">
                 <i class="fas fa-angle-right mx-2 orange--text mt-1"></i>
                <span class="grey lighten-4 px-2" v-for="(citem, citemindex) in condition_item" :key="citemindex">
                 <b>{{citemindex}}</b>: {{citem}} 
                </span>
 
               </v-row>
              </span>

              

            </v-row>
          </fieldset>
        </v-card-text>
        <v-divider class="mt-2"></v-divider>
        <v-card-actions class="grey lighten-5 ">
          <v-btn x-small @click="editFilter(filter)"> Szerkesztés </v-btn>
          <v-btn class="red white--text" @click="deleteShopUrlFilter(filter.id)" x-small>Törlés</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import global_mixin from '../mixins/global_mixin'
export default {
  mixins: [global_mixin],
  components: { Treeselect },
  data() {
    return {
      loadingFilters: false,
      productFilters: {},
      addEditFilter: false,
      addEditFilterData: {},
      editRangeDialog: false,
      value: {},
      loadingRangeUrls: false,
      range_urls: [],
      saveInProgress: false,
      saveMessage: {
        title: 'Mentés folyamatban',
        message: 'Kérlek várj ...',
        class: 'blue',
      },

     
    }
  },

  methods: {
    deleteShopUrlFilter(id){


      this.$confirm(
        {
          message: `Biztosan törlöd?`,
          button: {
            no: 'Mégse',
            yes: 'Törlés'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm) {
             
              

              this.$http.get('productfilters/delete?id=' + id + '').then((result) => {
                if (result.data.error === true) {
                
                this.alertMessage = result.data.message

                this.$toast.error(result.data.message, {
                  timeout: 2000,
                })
                
                } else {
                
                  this.getProductFilters()

                  this.$toast.success(result.data.message, {
                    timeout: 2000,
                  })
                }
              })



            

            }
          }
        }
      )

      

    },
    saveFilter(){

      //const encoded = JSON.stringify(this.addEditFilterData)

      console.log(this.addEditFilterData)

      this.saveInProgress = true

      this.axios.post('productfilters/save', this.addEditFilterData).then((result) => {
        if (result.data.error === true) {

          this.saveMessage.title = 'A mentés sikertelen!'
          this.saveMessage.message = result.data.message
          this.saveMessage.class = 'red'

        
        } else {

          this.saveMessage.title = 'A mentés sikeres!'
          this.saveMessage.message = result.data.message
          this.saveMessage.class = 'green'

          if (this.addEditFilterData.id === undefined) {
            this.addEditFilterData.id = result.data.response.id
          }

          this.getProductFilters()

         
        }
      })

      setTimeout(() => {
        this.saveInProgress = false
      }, 3000)


    },
    removeFilterCondition(index){

      console.log(index, this.addEditFilterData.filter_conditions.conditions[index])

      this.$delete(this.addEditFilterData.filter_conditions.conditions, index)

    },
    addFilterCondition() {
      this.addEditFilterData.filter_conditions.conditions.push(
        {
            param: '',
            operator: '',
            value: '',
            inverse: '',
        }
      )
    },
    getRangeUrls() {
      this.loadingRangeUrls = true

      this.$http.get('shopurls/getallgroupped').then((result) => {
        if (result.data.error === true) {
          //
        } else {
          if (result.data.data) {
            this.range_urls = Object.values(result.data.data)
          }
        }

        this.loadingRangeUrls = false
      })
    },
    globalRange(event) {
      if (event == 'global') {
        this.addEditFilterData.filter_range = ['global']
      } else {
        this.addEditFilterData.filter_range = []
      }
    },
    editRange() {
      this.editRangeDialog = true
      this.getRangeUrls()
    },
    addFilter() {
      this.addEditFilter = true
      this.$set(this.addEditFilterData, 'filter_range', ['global'])
      this.$set(this.addEditFilterData, 'filter_conditions', {
        new_products: true,
        exists_products: false,
        push_message: false,
        push_sound: false,
        conditions: [],
      })
      this.getRangeUrls()
    },
    editFilter(filterData) {
      this.addEditFilter = true
      this.addEditFilterData = JSON.parse(JSON.stringify(filterData))



      this.addEditFilterData.filter_conditions = JSON.parse(this.addEditFilterData.filter_conditions)
      this.addEditFilterData.filter_range = this.addEditFilterData.filter_range.split(',')
    },
    closeAddEditDialog() {
      this.addEditFilter = false
      this.addEditFilterData = {}
    },
    getProductFilters() {
      this.loadingFilters = true

      this.axios.get('productfilters/search').then((result) => {
        this.productFilters = result.data.response.data

        this.loadingFilters = false
      })
    },
  },
  mounted() {
    this.getProductFilters()
  },
}
</script>
<style >
.hashtag-0 {
  color: orange;
}
.hashtag-1 {
  color: green;
}
.hashtag-3 {
  color: purple;
}

.hashtag-bg-0 {
  background-color: rgb(255, 236, 199) !important;
}
.hashtag-bg-1 {
  background-color: rgb(197, 247, 230) !important;
}
.hashtag-bg-3 {
  background-color: rgb(255, 224, 255) !important;
}
.vue-treeselect__checkbox--indeterminate {
  border-color: #54ce15;
  background: #26e503;
}

.vue-treeselect__option.vue-treeselect__option--highlight {
  background: #d4f0d0;
}
</style>
