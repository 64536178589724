<template>
  <div>
   
   
    <v-dialog  scrollable v-model="showUrlProblemDialog" max-width="100%" >
      <v-card class="white" >
        <v-card-title class="blue lighten-3 justify-center">
          
          {{urlProblemsData.site_cms}}  

           <span class=" px-3 text-caption  grey mx-2 lighten-2 black--text">{{urlProblemsData.all_problems}}</span>
          

          <v-divider vertical color="grey" class="mx-2"></v-divider>

        <v-badge v-if="urlProblemsData.all_connect_error_count > 0" bordered color="error" overlap class="mr-4" :content="urlProblemsData.all_connect_error_count">
              
              <span class="pa-1 text-caption px-4 red white--text"><i class="fas fa-exclamation-triangle mr-1"></i></span>
            </v-badge>
            
            <v-badge v-if="urlProblemsData.all_product_error_count > 0" bordered color="warning" overlap class="mr-4" :content="urlProblemsData.all_product_error_count">
              
              <span class="pa-1 text-caption px-4 orange white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            <v-badge v-if="urlProblemsData.all_no_product_on_first_page > 0" bordered color="purple" overlap class="mr-4" :content="urlProblemsData.all_no_product_on_first_page">
              
              <span class="pa-1 text-caption px-4 purple white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

        </v-card-title>
        <v-card-text class="pa-0 ">
          
          <v-row v-for="(urlproblem, index) in urlProblemsData.urls" :key="index" class="pa-1 py-2 my-1 py-md-0 grey lighten-3">
           <v-col cols="12" md="6" class="pa-0 pa-md-2 text-center text-md-right">


            <a :href="'shopurls?shop_url_id=' + urlproblem.id + '&show_url_list=1'" target="_blank" color="secondary" ><b> {{ urlproblem.site_prefix }} </b> - {{ urlproblem.category }} - {{ urlproblem.description }} </a>
            <span class="d-block">{{urlproblem.last_refresh}}</span>

            </v-col>

            <v-col cols="12" md="6" class="mb-0 mb-md-2 pa-md-4 pa-0 mt-1 mt-md-0 text-center text-md-left" >
            
            <v-btn :href="'log?log_shop_url_id='+ urlproblem.id +'&filterLogCategories=true&get_categories=true'" target="_blank" x-small class="grey white--text elevation-0 mr-2" text>
              <i class="fas fa-list"></i>
            </v-btn> 
            
            <v-badge v-if="urlproblem.connect_error_count > 0" bordered color="error" overlap class="mr-4" :content="urlproblem.connect_error_count">
              
              <span class="pa-1 text-caption px-4 red white--text"><i class="fas fa-exclamation-triangle mr-1"></i></span>
            </v-badge>
            <v-badge v-if="urlproblem.no_product_error_count > 0" bordered color="warning" overlap class="mr-4" :content="urlproblem.no_product_error_count">
              
              <span class="pa-1 text-caption px-4 orange white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            <v-badge v-if="urlproblem.no_product_on_first_page > 0" bordered color="purple" overlap class="mr-4" :content="urlproblem.no_product_on_first_page">
              
              <span class="pa-1 text-caption px-4 purple white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="align-center justify-center flex-row d-flex">
          
          <v-btn small class="red white--text" v-if="urlProblemsData.all_connect_error_count > 0" @click="resetUrlErrors(urlProblemsData.site_cms, 'connect_error_count', showUrlProblemsMaybe)" >
                  <i class="fas fa-angle-right mr-2"></i> Connect Error Count Reset {{urlProblemsData.all_connect_error_count}}
          </v-btn>
          <v-btn small class="orange white--text" v-if="urlProblemsData.all_product_error_count > 0" @click="resetUrlErrors(urlProblemsData.site_cms, 'no_product_error_count', showUrlProblemsMaybe)" >
                  <i class="fas fa-angle-right mr-2"></i> No Product Error Count Reset {{urlProblemsData.all_product_error_count}}
          </v-btn>
          <v-btn small class="purple white--text" v-if="urlProblemsData.all_no_product_on_first_page > 0" @click="resetUrlErrors(urlProblemsData.site_cms, 'no_product_on_first_page', showUrlProblemsMaybe)" >
                  <i class="fas fa-angle-right mr-2"></i> No Product On First Page Reset {{urlProblemsData.all_no_product_on_first_page}}
          </v-btn>
              
          <v-btn @click="showUrlProblemDialog = false" small class="green white--text">Bezárás</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


      <v-card  v-if="Object.keys(hasProblems).length > 0" class="pa-0 pt-3 elevation-0 scrollable" max-height="200px" :style="this.$vuetify.breakpoint.mobile === false ? 'margin-top: 60px !important;' : 'margin-top: 20px !important;'" >
        <v-card-title dense class="pa-0 px-2 grey lighten-2 text-body-1"><v-progress-circular indeterminate v-if="loading" size="15" class="mr-2 green--text"></v-progress-circular> URL problémák </v-card-title>
       
         <v-card-text  class="pa-0"  >
          <v-card-title  v-for="(site_cms, index) in hasProblems" :key="index" class="pa-0 my-2 text-caption red lighten-5 dotted">
           <v-col cols="1">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on" x-small class="mx-1 green white--text"><i class="fas fa-power-off"></i></v-btn>
            </template>
              <v-list dense class="pa-0" style="min-width: 200px; text-align:center;">
                <v-list-item disabled dense class="pa-5 grey lighten-4" style="min-height: 20px; ">
                  <v-list-item-title> 
                    <v-img  eager class="mr-2 pa-2 " style="border: 1px dotted #666; display:inline-block; margin-bottom: -5px" v-if="$store.state.settings['' + index + '_pushover_icon']" :src="$store.state.settings['' + index + '_pushover_icon']" max-height="20px" max-width="20"  />
                  {{site_cms.site_cms.toUpperCase()}}</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0 white" style="border: 3px solid white"></v-divider>
                
                <v-list-item v-if="site_cms.all_connect_error_count > 0" @click="resetUrlErrors(site_cms.site_cms, 'connect_error_count', 0)" link selectable class="pa-3 mt-1 red lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> Connect Error Count Reset</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="site_cms.all_product_error_count > 0" @click="resetUrlErrors(site_cms.site_cms, 'no_product_error_count', 0)" link class="pa-3 orange mt-1 lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> No Product Error Reset</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="site_cms.all_no_product_on_first_page > 0" @click="resetUrlErrors(site_cms.site_cms, 'no_product_on_first_page', 0)" link class="pa-3 purple mt-1 lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> No Product On First Page Reset</v-list-item-title>
                </v-list-item>
                 <v-list-item @click="resetUrlErrors(site_cms.site_cms, '', 0)" link selectable class="pa-3 mt-3" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-play mr-2 mb-1"></i> Összes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
           </v-col>
            <v-col cols="5" align="right" class="mr-0 py-1" @click="showUrlProblems(site_cms.site_cms)" style="cursor:pointer">
          
           <v-img class="mr-2 " style="display:inline-block; margin-bottom: -5px" v-if="$store.state.settings['' + index + '_pushover_icon']" :src="$store.state.settings['' + index + '_pushover_icon']" max-height="20px" max-width="20"  />
            
            {{index.toUpperCase()}}   
    
    
           <span class="pa-1 text-caption px-2 grey lighten-2 black--text">{{site_cms.all_problems}}</span>
           
            
            </v-col>

            
            <v-col cols="5">
            <v-divider vertical color="grey" class="mx-2"></v-divider>

            

            <v-badge v-if="site_cms.all_connect_error_count > 0" bordered color="error" overlap class="mr-4" :content="site_cms.all_connect_error_count">
              
              <span class="pa-1 text-caption px-4 red white--text"><i class="fas fa-exclamation-triangle mr-1"></i></span>
            </v-badge>
            
            <v-badge v-if="site_cms.all_product_error_count > 0" bordered color="warning" overlap class="mr-4" :content="site_cms.all_product_error_count">
              
              <span class="pa-1 text-caption px-4 orange white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            <v-badge v-if="site_cms.all_no_product_on_first_page > 0" bordered color="purple" overlap class="mr-4" :content="site_cms.all_no_product_on_first_page">
              
              <span class="pa-1 text-caption px-4 purple white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            
            <v-spacer></v-spacer>
            </v-col>
          </v-card-title>
        </v-card-text>
       
   
      </v-card>
     
     <v-card  class="pa-0 pt-3 mb-2 elevation-0 scrollable" max-height="200px" >
        <v-card-title style="cursor: pointer;" @click="doShowMaybehasproblemsCard()" dense class="pa-1 px-2 grey lighten-2 text-body-1"><v-progress-circular indeterminate v-if="loading" size="15" class="mr-2 green--text"></v-progress-circular>  problémák [ Szezonális ]  <v-btn x-small class="ml-2 purple darken-2 white--text"> {{Object.keys(hasMaybeEmptyProblems).length}} </v-btn><v-spacer></v-spacer></v-card-title>
       
        <template v-if="Object.keys(hasMaybeEmptyProblems).length > 0">
         <v-card-text  class="pa-0"  >
          <template  v-if="showMaybehasproblemsCard">
          <v-card-title v-for="(site_cms, index) in hasMaybeEmptyProblems" :key="index" class="pa-0 my-2 text-caption red lighten-5 dotted">
           <v-col cols="1">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on" x-small class="mx-1 green white--text"><i class="fas fa-power-off"></i></v-btn>
            </template>
              <v-list dense class="pa-0" style="min-width: 200px; text-align:center;">
                <v-list-item disabled dense class="pa-5 grey lighten-4" style="min-height: 20px; ">
                  <v-list-item-title> 
                    <v-img  eager class="mr-2 pa-2 " style="border: 1px dotted #666; display:inline-block; margin-bottom: -5px" v-if="$store.state.settings['' + index + '_pushover_icon']" :src="$store.state.settings['' + index + '_pushover_icon']" max-height="20px" max-width="20"  />
                  {{site_cms.site_cms.toUpperCase()}}</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0 white" style="border: 3px solid white"></v-divider>
                
                <v-list-item v-if="site_cms.all_connect_error_count > 0" @click="resetUrlErrors(site_cms.site_cms, 'connect_error_count', 1)" link selectable class="pa-3 mt-1 red lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> Connect Error Count Reset</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="site_cms.all_product_error_count > 0" @click="resetUrlErrors(site_cms.site_cms, 'no_product_error_count', 1)" link class="pa-3 orange mt-1 lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> No Product Error Reset</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="site_cms.all_no_product_on_first_page > 0" @click="resetUrlErrors(site_cms.site_cms, 'no_product_on_first_page', 1)" link class="pa-3 purple mt-1 lighten-4" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-angle-right mr-2"></i> No Product On First Page Reset</v-list-item-title>
                </v-list-item>
                 <v-list-item @click="resetUrlErrors(site_cms.site_cms, '', 1)" link selectable class="pa-3 mt-3" dense style="min-height: 20px;  ">
                  <v-list-item-title><i class="fas fa-play mr-2 mb-1"></i> Összes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
           </v-col>
            <v-col cols="5" align="right" class="mr-0 py-1" @click="showUrlProblems(site_cms.site_cms, 'maybeemptyurls')" style="cursor:pointer">
          
           <v-img class="mr-2 " style="display:inline-block; margin-bottom: -5px" v-if="$store.state.settings['' + index + '_pushover_icon']" :src="$store.state.settings['' + index + '_pushover_icon']" max-height="20px" max-width="20"  />
            
            {{index.toUpperCase()}}   
    
    
           <span class="pa-1 text-caption px-2 grey lighten-2 black--text">{{site_cms.all_problems}}</span>
           
            
            </v-col>

            
            <v-col cols="5">
            <v-divider vertical color="grey" class="mx-2"></v-divider>

            

            <v-badge v-if="site_cms.all_connect_error_count > 0" bordered color="error" overlap class="mr-4" :content="site_cms.all_connect_error_count">
              
              <span class="pa-1 text-caption px-4 red white--text"><i class="fas fa-exclamation-triangle mr-1"></i></span>
            </v-badge>
            
            <v-badge v-if="site_cms.all_product_error_count > 0" bordered color="warning" overlap class="mr-4" :content="site_cms.all_product_error_count">
              
              <span class="pa-1 text-caption px-4 orange white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            <v-badge v-if="site_cms.all_no_product_on_first_page > 0" bordered color="purple" overlap class="mr-4" :content="site_cms.all_no_product_on_first_page">
              
              <span class="pa-1 text-caption px-4 purple white--text"><i class="fas fa-exclamation-triangle mr-1"></i> </span>
            </v-badge>

            
            <v-spacer></v-spacer>
            </v-col>
          </v-card-title>
          </template>
        </v-card-text>
        </template>

      </v-card>
    
  

</div>
</template>
<script>
export default {
  data() {
    return {
      hasProblems: false,
      hasMaybeEmptyProblems: false,
      loading: false,
      showUrlProblemDialog: false,
      urlProblemsData: {},
      showMaybehasproblemsCard: false,
      showUrlProblemsMaybe: false
      
    }
  },

  methods: {

    doShowMaybehasproblemsCard(){

      this.showMaybehasproblemsCard = !this.showMaybehasproblemsCard

    },
    resetUrlErrors(site_cms, type = "", maybe = 0){




      var typestring;

      if(type == ""){
        typestring = "Összes"
      }else{
        typestring = type
      }

 this.$confirm(
        {
          message: 'Biztosan Reseteled? ('+ site_cms +' | '+ typestring +')',
          button: {
            no: 'Mégse',
            yes: 'Igen'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm) {
             
              this.$http.get('shopurls/reseturlerrors?site_cms=' + site_cms + '&type=' + type +'&maybe=' + maybe).then((result) => {

            

        if (result.data.error === true) {

           this.$toast.error(result.data.message, {
                  timeout: 2000,
           })
        
        } else {
          
          this.showUrlProblemDialog = false

          this.getProblems()

          
          
          

        }

        
      })

             



            

            }
          }
        }
      )
     
      

    },
    showUrlProblems(site_cms, prob = ""){

      var v = ""
      if(prob == "maybeemptyurls"){
      
      this.showUrlProblemsMaybe = 1
      v = this.hasMaybeEmptyProblems
        
      }else{

      this.showUrlProblemsMaybe = 0
       v = this.hasProblems

      }

          if(Object.prototype.hasOwnProperty.call(v, site_cms)){
                
                this.urlProblemsData = v[site_cms]
               
                this.showUrlProblemDialog = true

              

          }else{


           this.urlProblemsData = {}
           this.showUrlProblemDialog = false
           

          }

    },
   async getProblems() {

      this.loading = true

      this.$http.get('shopurls/hasproblems').then((result) => {
        if (result.data.error === true) {
          alert('Hiba történt')
        } else {
          
          
          
          
          this.hasProblems = result.data.urls

          this.hasMaybeEmptyProblems = result.data.maybeempty_urls

          

        }

        setTimeout(() => {

            
        this.loading = false

        },1000)
      
      return result

      })
    }
  },
  mounted() {
    

    setInterval(() => {
      this.getProblems()
    }, 10000)
  },
  created(){
      this.getProblems()
  }
}
</script>