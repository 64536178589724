<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span :class="bold == 'true' ? 'font-weight-normal' : 'font-weight-light'" v-html="dateResponse" v-bind="attrs" v-on="on"></span>
    </template>
    <span>{{ date }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    date: {},
    background: {},
    color: {},
    bold: {
      default: 'true'
    }

  },
  data() {
    return {
      greeting: 'Hello',
      diff_in_locale: false,
    }
  },
  methods: {
    getMoment(date) {
      var stringD = '--'

      if (this.date !== undefined) {
        const diff_in_seconds = this.moment().diff(date, 'seconds')

        const diff_in_locale = this.moment(date).locale('hu').fromNow()

        switch (true) {
          case diff_in_seconds < 89:
            stringD = '<span class="dotted  '+ (this.background ? this.background : 'green lighten-5') +' '+ (this.color ? this.color : 'green--text') +' px-2">' + diff_in_locale + '</span>'
            break
          case diff_in_seconds >= 90 && diff_in_seconds < 300:
            stringD = '<span class="'+ (this.background ? this.background : 'orange lighten-5') +' '+ (this.color ? this.color : 'orange--text') +'  px-2">' + diff_in_locale + '</span>'
            break
          case diff_in_seconds >= 300 && diff_in_seconds < 2700:
            stringD = '<span class="'+ (this.background ? this.background : 'purple lighten-5') +' '+ (this.color ? this.color : 'purple--text') +'  px-2">' + diff_in_locale + '</span>'
            break
          default:
            stringD = '<span class="'+ (this.background ? this.background : 'red lighten-5') +' '+ (this.color ? this.color : 'red--text') +'  px-2">' + diff_in_locale + '</span>'
        }
      }
      return stringD
    },
  },
  mounted() {
    

    setInterval(() => {
      this.diff_in_locale = this.getMoment(this.date)
    }, 10000)
  },
  created(){
    this.diff_in_locale = this.getMoment(this.date)
  },

  computed: {
    dateResponse() {
      return this.diff_in_locale
    },
  },
}
</script>