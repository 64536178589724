<template>
<div>
<v-app-bar  dense color="black" fixed  :bottom="this.$vuetify.breakpoint.mobile" dark>

       <v-dialog  v-model="sharelistDialog" max-width="600px" class="white pa-0">
      <v-card class="pa-0 ma-0">
        <v-card-title><v-btn class="mr-2" x-small @click="showShareLists()"><i class="fas fa-retweet "></i></v-btn> ShareList</v-card-title>
        <template v-if="Object.keys(shareListsData).length > 0">

          <v-card-text class="pa-1">

            <v-list dense class="pa-0">
              <v-list-item dense class="blue lighten-2 white--text ma-1 pa-1" v-for="(sharelistitem, index) in shareListsData" :key="index">
                
                <v-menu  offset-y>
                 <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" x-small class="mr-3">{{sharelistitem.count}}</v-btn>  
                 </template>
                 <v-list class="text-caption pa-0 ma-0">
                <v-list-item dense class="pa-0 ma-0 px-2" :to="'/termekek/sharelist?sharelist=' + sharelistitem.name + ''" target="_blank">
                  <i class="fas fa-external-link-alt mr-2 mb-1"></i> Mutasd
                </v-list-item>
                <v-list-item dense class="red white--text lighten-2 pa-0 ma-0 px-2" @click="deletesharelistclick(sharelistitem.name)" >
                  <i class="fas fa-trash-alt mr-2 mb-1"></i> Törlés
                </v-list-item>
              </v-list>
                </v-menu>
                
                {{sharelistitem.name}}
                
              </v-list-item>
            </v-list>

          </v-card-text>

        </template>
        <template v-else>

<v-card-text class="green white--text pa-3">
        <i class="fas fa-spinner mr-3"></i>  Nincs ShareList
</v-card-text>
        </template>
      </v-card>
       </v-dialog>

      <v-dialog v-if="$store.state.notification_count > 0 " v-model="notificationDialog" max-width="600px" class="white pa-0">
      <v-card :loading="loadingNotifications"  class="pa-0 ma-0">

          <template v-if="$store.state.notification_count > 0 ">
          <v-card-title class="grey lighten-3 text-caption ">{{$store.state.notification_count}} értesítés!</v-card-title>
          <v-card-text class="pa-0 px-1 "  v-for="(type, index) in $store.state.notification_log_types" :key="index">
            <v-list-item @click.stop="showLogs(type.log_type)">

             
             
                <v-list-item-content >
                  
                  <v-list-item-title class="text-caption font-weight-bold" > 
                    <v-btn text x-small class="btn green lighten-2 white--text mr-2">{{type.count}}</v-btn> 
                    <span v-html="type.log_type"></span>
                  </v-list-item-title>
                  
                
                </v-list-item-content>
                
            </v-list-item>
            
            <v-divider v-if="(index + 1) != $store.state.notification_log_types.length" class="grey"></v-divider>
          </v-card-text>
          
         </template>
         
      </v-card>
</v-dialog>

      <v-tabs v-model="selectedtab" color="green" class="black white--text " centered center-active align-with-title show-arrows :fixed-tabs="this.$vuetify.breakpoint.mobile" next-icon="fas fa-angle-right" prev-icon="fas fa-angle-left small" >
            

          <v-tab @click="showNotifications()" class="white--text">
            <template v-if="$store.state.notification_count > 0 ">
             <v-badge :content="$store.state.notification_count" color="error">
             <i class="far fa-comment-alt green-text"></i> 
             </v-badge>
            </template>
            <template v-else>
             <i class="far fa-comment-alt "></i> 
            </template>
            </v-tab>

            <!-- 
            <v-tab @click="rerenderRouter()" class="white--text"  :to="$store.state.notification_count > 0 ? '/log?log_category=notification&show_log_dialog=1' : '' ">
            <template v-if="$store.state.notification_count > 0 ">
             <v-badge :content="$store.state.notification_count" color="error">
             <i class="far fa-comment-alt green-text"></i> 
             </v-badge>
            </template>
            <template v-else>
             <i class="far fa-comment-alt "></i> 
            </template>
            </v-tab>
             -->

            <template v-if="!this.$vuetify.breakpoint.mobile">

            <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="white--text font-weight-normal text-overline " @click="rerenderRouter()" to="/curl">
             <i class="fas fa-code mr-1"></i> CURL
            </v-tab>
           <v-divider class="grey" inset vertical></v-divider>
           <v-tab class="white--text font-weight-normal text-overline " @click="rerenderRouter()" to="/beallitasok">
             <i class="fas fa-user-cog mr-1"></i> Beállítások
            </v-tab>
               <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/filters">
             <i class="fas fa-filter mr-1"></i> Filters
            </v-tab>
            
            </template>

         
            
            <v-divider class="grey mx-1"  inset vertical></v-divider>
            <v-tab v-if="$store.state.settings.new_products > 0 " class="orange lighten-4 font-weight-normal black--text text-overline" @click="rerenderRouter()" to="/termekek/uj-termekek">
             <i class="fab fa-shopify mr-1"></i>{{$store.state.settings.new_products}} 
            </v-tab>
            <v-tab v-if="$store.state.settings.new_discounts > 0 " class="purple white--text font-weight-normal text-overline" @click="rerenderRouter()" to="/termekek/uj-akciok">
             <i class="fab fa-shopify mr-1"></i> {{$store.state.settings.new_discounts}}
            </v-tab>
            <v-tab v-if="$store.state.settings.is_top_price > 0 " class="red white--text font-weight-normal text-overline" @click="rerenderRouter()" to="/termekek/uj-top-akciok">
             <i class="fas fa-fire mr-1"></i> {{$store.state.settings.is_top_price}}
            </v-tab>
            <v-tab v-if="$store.state.settings.watched_pushed > 0 " class="green white--text font-weight-normal text-overline" @click="rerenderRouter()" to="/termekek/uj-pushed-akciok">
             <i class="fas fa-fire mr-1"></i> {{$store.state.settings.watched_pushed}}
            </v-tab>

            
            <v-tab v-if="$store.state.settings.is_top_price == 0  && $store.state.settings.new_discounts == 0 && $store.state.settings.new_products == 0" class="light-green lighten-1 white--text text-overline font-weight-normal " @click="rerenderRouter()" to="/akciok?show_filter_dialog=1">
             <i class="fas fa-search mr-1"></i> Keresés
            </v-tab>
            <v-divider class="grey ml-1" inset vertical></v-divider>

            <template v-if="!this.$vuetify.breakpoint.mobile">
            
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/shopurls">
            <i class="fas fa-link mr-1"></i> URL Admin
            </v-tab>
            <v-divider class="grey" inset vertical></v-divider>
            
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/sessions">
            <i class="fas fa-spinner mr-1"></i> Sessions
            </v-tab>

            
            
            <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/log">
             <i class="fas fa-list-ul mr-1"></i> LOG
            </v-tab>
            <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/kedvencek">
              <i class="fas fa-star mr-1"></i> 
            </v-tab>
            <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="white--text text-overline font-weight-normal" @click="rerenderRouter()" to="/megfigyeltek">
             <i class="fas fa-bell mr-1"></i> 
            </v-tab>
            <v-divider class="grey" inset vertical></v-divider>
            <v-tab v-if="$store.state.settings.sharelists.length > 0" class="blue--text white--text text-overline font-weight-normal" @click="showShareLists()">
             <i class="fas fa-share-alt"></i> <span class="pa-1 blue--text text-lighten-3"> {{$store.state.settings.sharelists.length}} </span>
            </v-tab>
            <v-divider class="grey" inset vertical></v-divider>
            <v-tab class="text-overline font-weight-normal" :class="$store.state.settings.global_turn_off == 0 ? 'red white--text' : 'green white--text'" @click="setGlobalOnOffSwitch()">
              <i class="fas fa-power-off"></i> 
            </v-tab>

            </template>

           
            <v-tab v-if="this.$vuetify.breakpoint.mobile" class="white--text " @click="showMenuDialog = true">
             <i class="fas fa-list "></i> 
            </v-tab>

          </v-tabs>


           <v-dialog scrollable v-model="showMenuDialog" max-width="60%">
            <v-card class="white ma-0">
            <v-card-title class="grey lighten-3 black--text justify-center text-overline font-weight-normal">Menü</v-card-title>
            <v-card-text class="pa-0" align="center">
               <v-list-item @click="rerenderRouter()" to="/kedvencek">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-star mr-1"></i> Kedvencek </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                 <v-list-item @click="rerenderRouter()" to="/megfigyeltek">
                 <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-bell mr-1"></i> Megfigyeltek </v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                 <v-list-item v-if="$store.state.settings.sharelists.length > 0"  @click="showShareLists()">
                 <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"><i class="fas fa-share-alt"></i>  ShareList <span class="pa-1 blue--text text-lighten-3 blue lighten-4 px-3"> {{$store.state.settings.sharelists.length}} </span> </v-list-item-title>
                </v-list-item-content>
                </v-list-item>


                
              <v-list-item @click="rerenderRouter()" to="/log">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-funnel-dollar mr-1"></i> LOG </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="rerenderRouter()" to="/filters">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-funnel-dollar mr-1"></i> Filters </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="rerenderRouter()" to="/sessions">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-funnel-dollar mr-1"></i> Sessions </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="rerenderRouter()" to="/shopurls">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-funnel-dollar mr-1"></i> URL Admin </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="rerenderRouter()" to="/beallitasok">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"> <i class="fas fa-user-cog mr-1"></i> Beállítások </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="rerenderRouter()" to="/curl">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"><i class="fas fa-funnel-dollar mr-1"></i> CURL </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="text-overline font-weight-normal" :class="$store.state.settings.global_turn_off == 0 ? 'red white--text' : 'green white--text'" @click="setGlobalOnOffSwitch()">
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-normal"><i class="fas fa-power-off"></i> </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-card-text>
            </v-card>
           </v-dialog>
    
  </v-app-bar>
</div>
</template>

<script>
import global_mixin from '../../mixins/global_mixin'

export default {
   mixins: [global_mixin],
  data() {
    return {
      selectedtab: false,
      notification_count: 0,
      update_notification_count: true,
      update_notification_loading: false,
      showMenuDialog: false,
      notificationDialog: false,
      loadingNotifications: false,
      shareListsData: {},
      sharelistDialog: false
    }
  },
  methods: {

    deletesharelistclick(name){


      this.deleteShareList(name);
      
      

    },

    setNotificationsWatched(){

        this.loadingNotifications = true
        this.
        this.$toast.warning('Értesítések olvasottnak jelölése ....!', {
                    timeout: 2000,
                  })

    },
    showShareLists(){


      this.sharelistDialog = true

      this.axios.get('products/sharelists')
            .then(result => {


              if(result.data.sharelist_data){

                this.shareListsData = JSON.parse(result.data.sharelist_data)

              }

            
            });

    },
    showLogs(log_type){

      //this.$set(this.globalFilters, 'show_log_dialog', 1)
      
      this.$router.replace({ name: 'Log', query: {log_category: 'notification', log_type: log_type, show_log_dialog: 1, log_max_date: this.mysqlDateString()} }).catch(()=>{});



    },
    showAll(){

      //this.$set(this.globalFilters, 'show_log_dialog', 1)
      
      this.$router.replace({ name: 'Log', query: {log_category: 'notification', log_type: '', show_log_dialog: 1, log_max_date: this.mysqlDateString()} }).catch(()=>{});



    },
    showNotifications(){

      if(this.$store.state.notification_count > 0){
      
        this.notificationDialog = !this.notificationDialog
      
      }else{

        this.$toast.warning('Nincs olvasatlan értesítés!', {
                    timeout: 2000,
                  })

      }

    },
    rerenderRouter(){

      this.$emit('rerender')
      this.showMenuDialog = false

    }

    
   
    
  },

  mounted() {

     
    

    
  }

  
}
</script>
<style>

.v-tab{

  padding: 2px;
  margin: auto 3px;
  height: 30px;

}


</style>