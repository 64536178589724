<template>
  <v-app  style="margin-bottom: 650px;" >
    <template v-if="connectionStatus">
    <template  v-if="appLoaded">
    <v-container  class=" mx-auto pa-0 "  >
      <Header @rerender="keyCode++" />

      <v-main >

        <v-alert  color="error" style="margin-top: 50px;" class=" white--text" dense v-if="$store.state.settings.global_turn_off == '1'">A GLOBÁLIS főkapcsoló be van kapcsolva!</v-alert>
        <v-alert  class="purple lighten-3 " dense v-if="$store.state.settings.newtescodiff > 0"><a class="white--text" href="https://vue.supreme7.eu/akciok?show_products=1&watched=0&product_extra_info=Eltérő ár&site_cms=tesco&order_by=product_last_change&sort_by=DESC">{{$store.state.settings.newtescodiff}} új Tesco API áreltérés</a></v-alert>
        <ShopUrlProblems></ShopUrlProblems>
        <TooManyConnections></TooManyConnections>


        <router-view :key="keyCode"></router-view>
      </v-main>
      <v-snackbar centered class="text-center" multi-line v-model="$store.state.snackbar.show" :timeout="$store.state.snackbar.timeout ? $store.state.snackbar.timeout : 3000">
        <v-card-text v-html="$store.state.snackbar.message" > </v-card-text>
        <v-card-text><v-btn color="red" text @click="$store.state.snackbar.show = false">Bezárás</v-btn></v-card-text>
      </v-snackbar>
   
    </v-container>
    <vue-confirm-dialog></vue-confirm-dialog>
    </template>
    <template v-else>
      <div class="mx-auto my-auto"><b>SUPREME7#BB</b> indítása folyamatban ....</div>
      <div class="mx-auto my-auto col-6 d-fixed top"><v-progress-linear fixed top height="5px" class="pa-0" :value="progress"></v-progress-linear></div>
    </template>
    </template>
    <template v-else>
      
      <div class="mx-auto my-auto text-center">
        <p><i class="fas fa-plug fa-3x"></i></p>
        <p class="my-0">Úgy tűnik <b>OFFLINE</b> vagy.</p>
        <p class="grey--text">Ellenőrízd az internet kapcsolatodat és próbáld újra.</p>
        <p><v-btn @click="$router.go()" small outlined >Újracsatlakozás</v-btn></p>
        </div>
    </template>
  </v-app>
</template>

<script>

import Header from './components/template/Header.vue'
import ShopUrlProblems from './components/ShopUrlProblems'
import TooManyConnections from './components/TooManyConnections'
export default {

  components: {
    Header, ShopUrlProblems, TooManyConnections
  },

  data() {
    return {

      keyCode: 0,
      progress: 0,
      appLoaded: false,
      connectionStatus: false
    }
  },
  methods: {

    checkOnlineStatus(){
    if(navigator.onLine){
		this.connectionStatus = true
		}else{
		this.connectionStatus = false
		}
    },

    
  },

created() {

   document.title = 'S7#BBVUE - v2.2.2';
         

this.checkOnlineStatus()


if(this.connectionStatus){
  this.$store.commit('getSettings')
  
  this.$store.commit("getNotifications")
    

  const appStart = setInterval(() => {
          
          this.progress = this.progress + 20

          if(this.progress > 80){
            this.progress = 80
          }

          if(this.$store.state.settings){
            this.progress = 100
            clearInterval(appStart)
            setTimeout(() => {

              this.appLoaded = true

            }, 500)
            
          }
          
        }, 500);
        
}
    
  },
  mounted: function () {

      console.log(this.$vuetify.breakpoint)

      setInterval(() => {

        this.checkOnlineStatus()
        

      }, 20000)


    if(this.connectionStatus){
    
      setInterval(() => {

        this.$store.commit('getSettings')
          

      }, 30000)

  setInterval(() => {

     this.$store.commit("getNotifications")


      }, 10000)

    


    }
  },
  computed: {
    snackbarSettings() {
      return this.$store.state.snackbar
    },
  },
  watch: {
    snackbarSettings: {
      deep: true,
      handler(value) {
        

        

        if (value.show === false) {
          
            this.$store.state.snackbar.message = false
          
        }
      },
    },
  },
}
</script>

<style>
a {
  text-decoration: none;
}

.orderby.fa-sort-up:before, .fa-sort-up:after {

     position: relative;
     top: 3px;

}

.orderby.fa-sort-down:before, .fa-sort-down:after {

      position: relative;
      top: -3px;

}



/* Hide scrollbar for IE, Edge and Firefox */
#product-orderby-nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

 .v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
  margin: 0;
}
 .v-bottom-navigation .v-btn:not(.v-btn--active) span {
  color: #fff !important;
}
.row {
  margin: 0 !important;
  
  
  padding: 0 !important;
}
.col {padding: 0 !important;}
.v-card {margin: 0 5px !important; }
.pointer {
  cursor: pointer !important;
}

.v-card.scrollable {
  display: flex !important;
  flex-direction: column;
}

.scrollable .v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.chartAreaWrapper {
       width: 80%;
       
       overflow-x: scroll;
  }

.walking-speed-chart{
       margin-top: 20px;
       height: 1200px;
       max-width: 1200px;
  }

</style>