<template>
  <div>

    <v-dialog  scrollable v-if="filteredSiteCmsList != false" v-model="showFilteredSiteCmsList" max-width="400px" class="white pa-0">
      <v-card  class="pa-0 ma-0">

          <v-card-title class="text-caption justify-center grey lighten-3">Filtered Site CMS lista</v-card-title>
          <v-card-text class="pa-3">
            <v-list>
              <div v-for="(cmss, index) in filteredSiteCmsList.all_site_cms" :key="index">
              <v-list-item @click="doGetProducts('site_cms', cmss.site_cms)"  style="" :class="index%2 == 0 ? 'grey lighten-3' : 'grey lighten-5' ">
               
      
            <v-img class=" px-2 pa-2 mr-2 " style="border: 2px solid white" eager  v-if="$store.state.settings['' + cmss.site_cms + '_pushover_icon']" :src="$store.state.settings['' + cmss.site_cms + '_pushover_icon']" max-width="40" max-height="40"  cover />
                  
              {{cmss.site_cms.toUpperCase() }}  <v-spacer></v-spacer> <v-badge color="info" :content="cmss.countsitecms" right inline></v-badge>
             
              </v-list-item>
            </div>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-center grey lighten-3">
           
            
            <v-btn x-small class="red white--text" @click="showFilteredSiteCmsList = false">Bezárás</v-btn>
            
            </v-card-actions>
            
      </v-card>
</v-dialog>
<v-dialog  scrollable v-if="productImageDialogSrc != ''" v-model="productImageDialog" max-width="600px" class="white pa-0">
      <v-card  class="pa-0 ma-0">

          <v-card-title class="text-caption justify-center"></v-card-title>
          <v-card-text class="pa-3">
            <v-img :src="productImageDialogSrc"></v-img>
          </v-card-text>
          <v-card-actions class="justify-center grey lighten-3">
           
             <v-btn x-small class="yellow" @click="copyText(productImageDialogSrc)">SRC másolása</v-btn>
            <v-btn x-small class="purple white--text" :href="productImageDialogSrc" target="_blank">Megnyitás új lapon</v-btn>
            <v-btn x-small class="red white--text" @click="productImageDialog = false">Bezárás</v-btn>
            
            </v-card-actions>
            
      </v-card>
</v-dialog>
    <v-dialog width="600" scrollable v-model="product_buyable_again_dialog" max-width="100%" class="white pa-0">
      <v-card  class="pa-0 ma-0">

        <v-card-title class="text-caption blue lighten-4">Beállítások - {{ productBuyableAgainData.product_name }} </v-card-title>
          <v-card-text class="pa-3">
 

            Válassz pusht stb ... 
            <v-select dense outlined :items="JSON.parse($store.state.settings.pushover_product_filter_sounds)" v-model="product_buyable_again_settings.pushover_sound"></v-select>
<v-row>
  <v-col cols="2">
       <i class="fas fa-retweet float-left orange--text mr-3 mt-1"></i><v-checkbox class="pa-0 ma-0" color="orange" v-model="product_buyable_again_settings.if_has_basket_button_reverse" ></v-checkbox> 
  </v-col>
  <v-col cols="10">
            <v-checkbox class="pa-0 ma-0" v-model="product_buyable_again_settings.if_has_basket_button" label="Ha kosárba rakható" ></v-checkbox>
  </v-col>
  
</v-row>     
            <v-divider></v-divider>

            <v-row>
  <v-col cols="2">
       <i class="fas fa-retweet float-left orange--text mr-3 mt-6"></i><v-checkbox v-model="product_buyable_again_settings.if_price_lower_than_reverse" ></v-checkbox>
  </v-col>
  <v-col cols="10">
            <v-checkbox v-model="product_buyable_again_settings.if_price_lower_than"  label="Ha az ára olcsóbb"></v-checkbox>
  </v-col>
  
</v-row>  


            
            <template v-if="product_buyable_again_settings.if_price_lower_than">
            <v-divider ></v-divider>
            <v-text-field   v-model="product_buyable_again_settings.price" type="number" dense outlined> </v-text-field>
            </template>

            <v-divider></v-divider>
            <v-checkbox v-model="product_buyable_again_settings.autoorder"  label="Automatikus megrendelés?"></v-checkbox>

            <v-divider></v-divider>
            {{product_buyable_again_settings}}

          </v-card-text>  
          <v-card-actions>
            <v-btn class="red white--text" @click="removeBuyableAgain(productBuyableAgainData.id)" x-small>Eltávolítás</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="green white--text" @click="saveBuyableAgain()" x-small>Mentés</v-btn>
          </v-card-actions>

      </v-card>

    </v-dialog>
    <v-dialog width="600" scrollable v-model="showProductLogTimeline" max-width="100%" class="white pa-0">
      <v-card :loading="loadingProductLogTimeline" class="pa-0 ma-0">
        <v-card-title class="text-caption">{{ productLogTimelineData.product_name }} Változásai ({{ productLogTimeline.count }})</v-card-title>
        <v-card-text>
          <template>
            <v-timeline v-if="productLogTimeline.count > 0" align-top dense>
              <div v-for="(timeline, index) in productLogTimeline.response" :key="index">
              <template v-if="timeline.product_change_type == 'new_product'">
                <v-timeline-item color="purple" small >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="timeline.date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>Új termék</strong>
                      <div class="text-caption" v-html="timeline.extra_info"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
              <template v-if="timeline.product_change_type == 'buyable_again'">
                <v-timeline-item color="green" small >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="timeline.date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>Újra rendelhető</strong>
                      <div class="text-caption" v-html="timeline.extra_info"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
              <template v-if="timeline.product_change_type == 'currently_not_buyable'">
                <v-timeline-item color="red" small >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="timeline.date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>Jelenleg nem rendelhető</strong>
                      <div class="text-caption" v-html="timeline.extra_info"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
              <template v-if="timeline.product_change_type == 'product_name change'">
                <v-timeline-item color="blue" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="timeline.date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>Névváltozás</strong>
                      <div class="text-caption" v-html="timeline.extra_info"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
              </div>
            </v-timeline>
            <v-alert type="info" v-else>Nincs találat</v-alert>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog width="600" scrollable v-model="showProductBasketChanges" max-width="100%" class="white pa-0">
      <v-card class="pa-0 ma-0">
        <v-card-title class="text-caption">{{ productBasketChanges.product_name }} Változásai</v-card-title>

        <v-card-text v-if="productBasketChanges.entry_data != '' && productBasketChanges.entry_data != undefined">
        
        </v-card-text>
        <v-card-text>
          <template>
            <v-timeline  v-if="productBasketChanges.basket_changes != undefined && productBasketChanges.basket_changes != ''" align-top dense>
              <div  v-for="(changes, index) in JSON.parse(productBasketChanges.basket_changes)" :key="index">
                <template v-if="changes.basket_change == 'Újra rendelhető' || changes.basket_change == 'Bekerült - rendelhető'">
                <v-timeline-item color="green" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="changes.change_date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>{{changes.basket_change}}</strong>
                      
                    </v-col>
                  </v-row>
                </v-timeline-item>
                
              </template>
              <template v-if="changes.basket_change == 'Jelenleg nem rendelhető' || changes.basket_change == 'Bekerült - nem rendelhető'">
                <v-timeline-item color="red" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong><MomentAgo :date="changes.change_date" background="transparent" bold="false"></MomentAgo></strong>
                    </v-col>
                    <v-col>
                      <strong>{{changes.basket_change}}</strong>
                      
                    </v-col>
                  </v-row>
                </v-timeline-item>
                
              </template>
              </div>
            </v-timeline>
            

            </template>
            

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLastSeenJsonData" v-if="lastSeenJsonDataFormatted != ''" max-width="600">
      <v-card class="ma-0">
        <v-card-text class="mt-3 pt-5" v-html="lastSeenJsonDataFormatted"> </v-card-text>
        <v-card-actions><v-spacer></v-spacer><v-btn x-small @click="showLastSeenJsonData = false" class="red white--text">Bezárás</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showStockInfo" v-if="stockInfo != ''" max-width="600">
      <v-card class="ma-0">
        <v-card-title  class="justify-center grey lighten-4  text-h6 text-center"><span class="red--text mr-2"> {{stockInfoData.site_cms.toUpperCase()}} </span> készletinfó </v-card-title>
        <v-card-subtitle class="text-center justify-center text-caption grey lighten-4 py-1">{{stockInfoData.product_name}}</v-card-subtitle>
        <v-card-text>
        <v-img class="ml-auto mr-auto mt-4" :src="stockInfoData.product_picture" width="200"><v-img class=" elevation-5"  style="position: absolute; bottom: 0; left: 0;"  eager  v-if="$store.state.settings['' + stockInfoData.site_cms + '_pushover_icon']" :src="$store.state.settings['' + stockInfoData.site_cms + '_pushover_icon']" max-width="30" max-height="3/60"  cover /></v-img>
       
        </v-card-text>
        <v-card-text class="mt-3 pt-5">
          <div v-for="stock in stockInfo.split('#')" :key="stock" class="my-1 orange lighten-5 px-1 text-center">
                  <span v-if="stock != ''" >
                  <span v-html="stock"></span> 
                  </span>
                  </div>
        </v-card-text>
        <v-card-actions class="grey lighten-4"><v-spacer></v-spacer><v-btn x-small @click="stockInfo = false" class="red white--text">Bezárás</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="loadingProducts === true" v-model="loadingProducts" max-width="400">
      <v-alert dense text type="success" class="white ma-0"> Terméklista betöltése folyamatban .... </v-alert>
    </v-dialog>


    <v-dialog v-model="sendToDiscordDialog" v-if="Object.keys(sendToDiscordData).length > 0" max-width="600">
      <v-card class="ma-0">
        <v-card-title align="center" class="text-caption" v-html="sendToDiscordData.product_name"></v-card-title>
        <v-card-text>
          <v-select v-model="sendToDiscordData.discord_webhook" :items="webhooks" item-text="name" item-value="url"></v-select>
          {{ sendToDiscordData.discord_webhook }}
        </v-card-text>
        <v-card-actions><v-spacer></v-spacer><v-btn x-small @click="doSendToDiscord()" class="green white--text">Mentés</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFixHighestPriceDialog" v-if="Object.keys(fixHighestPriceData).length > 0" max-width="600">
      <v-card class="ma-0">
        <v-card-title align="center" v-html="fixHighestPriceData.product_name"></v-card-title>
        <v-card-text>
          <v-text-field type="number" v-model="fixHighestPriceData.highest_price" :value="fixHighestPriceData.highest_price" label="Add meg mennyi legyen a normál ára amihez viszonyul a kedvezményes ár"></v-text-field>
          <v-btn class="mx-1 " @click="fixHighestPriceData.highest_price = fixHighestPriceData.product_discount_price">{{ mFormat(fixHighestPriceData.product_discount_price) }}</v-btn>
          <v-btn @click="PriceMinusPercent(5)" class="mx-1 orange white--text">-5%</v-btn>
          <v-btn @click="PriceMinusPercent(10)" class="mx-1 red white--text">-10%</v-btn>
          <v-btn @click="PriceMinusPercent(20)" class="mx-1 purple white--text">-20%</v-btn>
           <v-btn @click="fixHighestPriceData.highest_price = fixHighestPriceData.original_highest_price" class="mx-1 green white--text">Reset [{{mFormat(fixHighestPriceData.original_highest_price)}}]</v-btn>
        </v-card-text>
        <v-card-actions><v-spacer></v-spacer>
        <v-btn x-small @click="doFixHighestPrice()" class="green white--text">Mentés</v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="600" scrollable persistent v-model="productAlert" max-width="100%" class="white pa-0">
      <v-card class="pa-0 ma-0">
        <v-card-title class="text-caption">{{ productAlertData.product_name }}</v-card-title>
        <v-card-text class="pa-0">
          <v-row class="grey lighten-5 pa-2" cols="12">
            <v-col class="pa-0 px-2 grey lighten-2" cols="12">Filter</v-col>
            <v-col class="pa-0">
              <fieldset class="pa-5 pt-1">
                <legend class="px-2">Feltételek</legend>

                <v-btn x-small @click="generateCliCommand(alertFilterData.observe_group)" class="mx-1"><i class="fas fa-terminal"></i></v-btn>

                <v-combobox dense outlined :items="JSON.parse($store.state.settings.product_change_filter_groups)" v-model="alertFilterData.observe_group"></v-combobox>

                <v-divider></v-divider>

                <v-combobox label="Pushover hang" dense outlined :items="JSON.parse($store.state.settings.pushover_product_filter_sounds)" v-model="alertFilterData.pushover_sound"></v-combobox>

                <v-switch color="green" v-model="alertFilterData.active" label="Szűrő aktív?"></v-switch>

                <v-divider></v-divider>

<v-row>
  <v-col cols="2">
    <i class="fas orange--text fa-retweet float-left mt-6 mr-2 "></i> <v-switch class="float-left" color="orange" v-model="alertFilterData.has_basket_button_reverse" ></v-switch> 
  </v-col>
  <v-col cols="10">
    <v-switch v-model="alertFilterData.has_basket_button" label="Ha rendelhető"></v-switch>
  </v-col>
</v-row>
<v-divider></v-divider>
<v-row>
  <v-col cols="2">
    <i class="fas orange--text fa-retweet float-left mt-6 mr-2 "></i> <v-switch class="float-left" color="orange" v-model="alertFilterData.discount_price_lower_then_reverse" ></v-switch> 
  </v-col>
  <v-col cols="10">
    <v-switch v-model="alertFilterData.discount_price_lower_then" label="Ha olcsóbb?"></v-switch>
  </v-col>
</v-row>
                
                

                <v-text-field dense v-if="alertFilterData.discount_price_lower_then" v-model="alertFilterData.discount_price_lower_then_price" type="number" label="Ha ennél olcsóbb" outlined></v-text-field>
             
                <v-switch v-model="alertFilterData.autoorder" color="red" label="Automatikus rendelés?"></v-switch>
             
              </fieldset>
            </v-col>
            <v-col cols="12">
              <v-switch v-model="alertFilterData.push" class="d-inline-block mx-2" label="Push?"></v-switch>
              <v-switch v-model="alertFilterData.log" class="d-inline-block mx-2" label="Log?"></v-switch>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
          </v-row>
      {{alertFilterData}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn x-small class="red white--text" @click="deleteProductAlert()">Megfigyelés törlése</v-btn>
          <v-btn x-small @click="closeProductAlert()">Mégse</v-btn>
          <v-btn x-small @click="saveProductAlert()" class="green white--text">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" scrollable v-model="showProductChangeDialog" max-width="100%" class="white pa-0">
      <v-card :loading="loadingProductChanges" class="pa-0 ma-0">
        <v-card-title class="text-caption">{{ productChangesItem.product_name }} árváltozásai ({{ productChanges.count }})</v-card-title>
        <v-card-text v-if="!loadingProductChanges">
          <v-row v-if="productChanges.count > 0">
            <v-col cols="6" align="center" class="green white--text">
              {{ mFormat(productChanges.min_max.lowest_price) }}
            </v-col>
            <v-col cols="6" align="center" class="orange white--text">
              {{ mFormat(productChanges.min_max.highest_price) }}
            </v-col>
          </v-row>
          <v-row v-if="productChanges.count > 0">
            <v-col cols="12">
              <v-switch v-model="switch_product_changes" true-value="all" false-value="distinct" @change="getProductChanges(productChangesItem.id, productChangesItem)" :label="'Szűrés [' + switch_product_changes + ']'"></v-switch>
            </v-col>
            <v-col cols="12">
              <FinancialChart :chartData="productChangeDataset"></FinancialChart>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-skeleton-loader type="article" class="pa-2"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!--

      ///////////////////////////////////////////////////
      //
      //
      // KERESŐ FORM
      //
      //
      ////////////////////////////////////////////////////


    -->

    <v-dialog scrollable v-if="showFilterDialog" v-model="showFilterDialog" max-width="800">
      <v-card class="white">
        <v-card-title class="grey text-caption font-weight-bold lighten-4 pa-1 justify-center" @click="showFilterDialog = !showFilterDialog" style="cursor: pointer">Szűrés</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-2" >
          <v-tabs-items v-model="filterTab">
            <v-tab-item>
              <v-card flat>
                <v-card-title class="text-caption">Keresés ... </v-card-title>
                <v-card-text>
                  <v-text-field @keydown.enter="doFilters()" dense outlined v-model="globalFilters.search_text"></v-text-field>
                  
                  <v-radio-group row v-model="globalFilters.search_text_method">
                    <v-radio label="Pontosan keress" value="match"></v-radio>
                    <v-radio label="%search" value="before"></v-radio>
                    <v-radio label="search%" value="after"></v-radio>
                    <v-radio label="%search%" value="both"></v-radio>
                  </v-radio-group>
                  
                  <v-checkbox label="Bypass Search limit" true-value="1" false-value="" v-model="globalFilters.bypass_search_limit"></v-checkbox>
                  <v-text-field @keydown.enter="doFilters()" dense outlined v-model="globalFilters.product_extra_info" placeholder="Product Extra Info"></v-text-field>

                  <v-switch class="mb-4" dense v-model="globalFilters.only_new" true-value="1" false-value="" label="Csak újak?" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.only_with_lego_info" true-value="1" false-value="" label="Csak ahol van lego info?" color="green" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.only_without_lego_info" true-value="1" false-value="" label="Csak ahol nincs lego info?" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.watched" true-value="0" false-value="" label="Csak új akciók" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.watched" true-value="1" false-value="" label="Csak megtekintett akciók" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.has_basket_button" true-value="1" false-value="" label="Csak kosárba rakhatóak?" color="green" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.favourite" true-value="1" false-value="" label="Csak kedvencek" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.exclude" true-value="1" false-value="" label="Csak kizártak" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.observe" true-value="1" false-value="" label="Csak megfigyeltek" color="green" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.is_top_price" true-value="1" false-value="" label="Csak TOP áras akciók" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.is_top_price" true-value="0" false-value="" label="Csak LOW akciók" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.arukereso_different" true-value="1" false-value="" label="Csak ÁRUKERESŐ DIFF" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.resealed" true-value="1" false-value="" label="Csak Újracsomagolt?" color="blue" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.has_basket_changes" true-value="1" false-value="" label="Csak ahol van kosár változás?" color="red" hide-details></v-switch>
                  <v-switch class="mb-4" dense v-model="globalFilters.listpage_check_buy_again_set" true-value="1" false-value="" label="Csak kosárba rakható megfigyelés?" color="blue" hide-details></v-switch>
                  <v-select dense outlined label="Megfigyelt csoport" :items="JSON.parse($store.state.settings.product_change_filter_groups)" v-model="globalFilters.observe_group"></v-select>
                  

                  <v-select dense outlined label="Elmentett listák" :items="$store.state.settings.sharelists" v-model="globalFilters.sharelist"></v-select>

                  <v-select
                    outlined
                    label="Olcsóbb/drágább?"
                    v-model="globalFilters.status"
                    :items="[
                      { text: 'Csak olcsóbbak', value: '-1' },
                      { text: 'Csak drágultak', value: '1' },
                      { text: 'Mindegy', value: '' },
                    ]"
                    dense
                  ></v-select>

                 
                  <v-row>
                    
                  <v-col cols="12" class="mb-2"><i class="fas fa-chart-bar red-text"></i> Ennyi alkalommal került már ennyibe:</v-col>
                   <v-col cols="12" class="d-flex">
                  <v-col cols="3" class="mx-2">
                    <v-text-field outlined v-model="globalFilters.min_change_count" type="number" label="minimum"></v-text-field>
                  </v-col>
                  <v-col cols="3"  class="mx-2">
                     <v-text-field outlined v-model="globalFilters.max_change_count" type="number" label="maximum"></v-text-field>
                  </v-col>
                  </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-select return-object multiple search-input :loading="loading_all_unique_data" @change="$set(globalFilters, 'site_cms', $event.toString())" @input.native="globalFilters.site_cms = $event.srcElement.value" :items="allCms" label="Site CMS" outlined dense :value="Object.keys(globalFilters).includes('site_cms') ? globalFilters.site_cms.split(',') : ''"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select return-object multiple search-input :loading="loading_all_unique_data" @change="$set(globalFilters, 'site_prefix', $event.toString())" @input.native="globalFilters.site_prefix = $event.srcElement.value" :items="allSitePrefix" label="Site Prefix" outlined dense :value="Object.keys(globalFilters).includes('site_prefix') ? globalFilters.site_prefix.split(',') : ''"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select return-object multiple search-input :loading="loading_all_unique_data" @change="$set(globalFilters, 'category', $event.toString())" @input.native="globalFilters.category = $event.srcElement.value" :items="allCategory" label="Kategória" outlined dense :value="Object.keys(globalFilters).includes('category') ? globalFilters.category.split(',') : ''"></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-select v-model="globalFilters.order_by" outlined dense :items="JSON.parse($store.state.settings.product_page_order_by)"></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-btn class="mx-3" @click="$set(globalFilters, 'sort_by', globalFilters.sort_by == 'DESC' ? 'ASC' : 'DESC')">{{ globalFilters.sort_by }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="12">
                      <v-select return-object search-input @change="$set(globalFilters, 'group_by', $event.toString())" @input.native="globalFilters.group_by = $event.srcElement.value" :items="['', 'product_extra_info']" label="GROUP BY" outlined dense :value="Object.keys(globalFilters).includes('group_by') ? globalFilters.group_by : ''"></v-select>
                  </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row class="grey lighten-2 px-1 ">
                    Eredeti ár szűrő 
                  </v-row>
                  <v-row class="pa-2">
                 
                  <v-col cols="12">
                  Minimum eredeti ár: 
                 <v-slider v-model="priceFilter.min_original_price"  step="5" ticks="always" tick-size="5"  track-color="grey" always-dirty >

                  <template v-slot:prepend>
                      <v-btn x-small  @click="decrement('min_original_price')">
                      <v-icon color="red">  mdi-minus </v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:append>
                    <v-btn x-small  @click="increment('min_original_price')">
                      <v-icon color="green">  mdi-plus </v-icon>
                      </v-btn>
                    </template>
                 </v-slider>
                  <v-text-field outlined dense type="number" v-model="globalFilters.min_original_price"></v-text-field>
                  </v-col>
                 
                  </v-row>
                  
                  <v-row class="pa-2">
                 
                  <v-col cols="12">
                  Maximum eredeti ár: 
                 <v-slider v-model="priceFilter.max_original_price"  step="5" ticks="always" tick-size="5"  track-color="grey" always-dirty >
                  
                  <template v-slot:prepend>
          <v-btn x-small  @click="decrement('max_original_price')">
          <v-icon color="red">  mdi-minus </v-icon>
          </v-btn>
        </template>

        <template v-slot:append>
         <v-btn x-small  @click="increment('max_original_price')">
          <v-icon color="green">  mdi-plus </v-icon>
          </v-btn>
        </template>
                 </v-slider>
                  <v-text-field outlined dense type="number" v-model="globalFilters.max_original_price"></v-text-field>
                  </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <v-divider class="my-1"></v-divider>

          <v-tabs v-model="filterTab" height="40" color="secondary" slider-color="black" centered grow slider-size="0" dark active-class="grey darken-1 white--text">
            <v-tabs-slider></v-tabs-slider>

            <v-tab class="text-caption"> Gyorsszűrő </v-tab>
            <v-tab class="text-caption"> Ár | Kedvezmény </v-tab>
          </v-tabs>
        </v-card-text>
        <v-card-actions v-if="showFilterDialog" class="align-center justify-center flex-row d-flex">
          <v-btn class="orange white--text" @click="doFilters()" small>Szűrés</v-btn>
          <v-btn @click="resetFilters()" small class="red white--text">RESET</v-btn>
          <v-btn @click="showFilterDialog = false" small class="green white--text">Mégse</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--


          //////////////////////////////////////////////////////////
          //
          // KERESŐ FORM VÉGE
          //
          //////////////////////////////////////////////////////////

        -->       

    <!--


          //////////////////////////////////////////////////////////
          //
          //  FILTER CHIPS-EK
          //
          //////////////////////////////////////////////////////////

        -->       
<v-row class=" orange white--text my-1 pa-2" v-if="this.globalFilters.sharelist && globalFilters.show_products == '1'">

<v-col cols="12" class="text-center"><i class="fas fa-exclamation-triangle mr-2"></i> EZ EGY MEGOSZTOTT LISTA TARTALMA! ({{this.globalFilters.sharelist}}) - <v-btn x-small class="red white--text" @click="deleteShareList(globalFilters.sharelist)">Lista törlése</v-btn></v-col>

</v-row>
<v-row>
        <v-col cols="12" class="align-center justify-center d-flex" v-if="Object.keys(products).length > 0">
          <v-chip-group column>
            <v-chip x-small class="orange white--text" @click="getFilteredProductsSiteCmsList()">Site cms lista</v-chip>
            <v-chip x-small class="purple white--text">Összes találat: {{ products.response.count }}</v-chip>
            <v-chip x-small v-for="(item, index) in products.params" :key="index" @click="filterReset(item.filter, true)">
              <b>{{ item.type }}</b
              >: {{ item.value }}
            </v-chip>
          </v-chip-group>
        </v-col>
</v-row>


            <!--


          //////////////////////////////////////////////////////////
          //
          //  LAPOZÓ ADAT CARD LISTA
          //
          //////////////////////////////////////////////////////////

        -->       


          <v-pagination class="my-2 grey lighten-3 text-caption"  v-if="Object.keys(products).length > 0" v-model="pagination" :length="products.response.all_pages" :total-visible="8"></v-pagination>
      
         <!--


          //////////////////////////////////////////////////////////
          //
          //  TERMÉK ADAT CARD LISTA
          //
          //////////////////////////////////////////////////////////

        -->       
        <v-row v-if="Object.keys(products).length > 0" class="justify-center">
          <v-col cols="12" sm="12" md="6" lg="4" class="px-1 my-1 pa-0" v-for="(product, index) in products.response.data" :key="index">
            <v-card 
            
              :id="index"
              width="100%"
              class="my-3 card-outter"
              min-height="200px"
              height="100%"
              v-intersect.once="{
                handler: onIntersect,
                options: {
                  rootMargin: '0px 0px -30px 0px',
                  threshold: [1],
                },
              }"
            >
         
             

                <v-card-subtitle dense  class="pa-0 text-center text-caption " :class="product.seen ? 'green lighten-5' : 'grey lighten-3'">
                

                <v-btn style="font-size: 0.5rem" x-small text  @click="doGetProducts('product_shopurl_priority', product.product_shopurl_priority)">{{ product.product_shopurl_priority }}</v-btn> 
                <span class="deep-orange--text"> / </span>
                <v-btn style="font-size: 0.5rem" x-small text  @click="doGetProducts('site_cms', product.site_cms)">  {{ product.site_cms }} </v-btn>
               <span class="deep-orange--text"> / </span>
                <v-btn style="font-size: 0.5rem" x-small text  @click="doGetProducts('category', product.category)"> {{ product.category }} </v-btn>
               <span class="deep-orange--text"> / </span>
                <v-btn style="font-size: 0.5rem" x-small text  @click="doGetProducts('description', product.description)"> {{ product.description }} </v-btn>
               
              </v-card-subtitle>
              <v-card-subtitle class="grey lighten-5 pa-0 px-2 text-caption font-weight-bold" align="center" style="line-height: 15px; word-break: break-word">
                <v-spacer></v-spacer>
                
                <i class="far fa-eye grey--text text-lighten-3"></i> <MomentAgo  :date="product.last_seen" background="transparent" bold="false"></MomentAgo>
                <template v-if="product.product_last_change != ''"> <i class="fas fa-exchange-alt grey--text text-lighten-3"></i> <MomentAgo :date="product.product_last_change" bold="false" color="grey--text" background="transparent"></MomentAgo> </template>
                <i class="fas fa-calendar-plus grey--text text-lighten-3"></i> <MomentAgo  :date="product.entry_date" bold="false" color="purple--text" background="transparent"></MomentAgo>

                <v-spacer></v-spacer>
                <span class="pa-1" v-if="1 != 1"><i class="fas fa-bullseye green--text"></i></span>
              
                
              </v-card-subtitle>
             
              <v-card-text class="pa-0 ">
                
                <v-row class="" align="center" justify="center">

                  <v-col cols="12" md="12" class="text-center">
                  
                    <v-img  @click="product.picture != '' ? showImageInDialog(product.product_picture) : ''" position="center center" eager :lazy-src="no_image" :src="product.product_picture != '' ? product.product_picture : no_image"  class="white--text align-end fill-height justify-center my-0 " style="cursor: pointer" height="100" max-height="100%" max-width="100%"  contain>
                    
                    <v-row class="d-block">
                      <v-col class="text-left ml-10 mb-2"><v-btn v-if="product.higher_price_but_not_watched > 0"    x-small class="red white--text "><i class="fas fa-skull-crossbones mr-1 "></i>Drágult?!</v-btn></v-col>
                      <v-col class="text-left ml-10 mb-2"><v-btn v-if="product.is_new > 0"    x-small class="yellow black--text "><i class="fas fa-bolt mr-1"></i>ÚJ!</v-btn></v-col>
                    <v-col class="text-left ml-10 mb-2"><v-btn v-if="product.watched_pushed > 0"    x-small class="green white--text "><i class="far fa-paper-plane mr-1 "></i></v-btn></v-col>
                   
                  </v-row>
                    
                     <v-container style="position: absolute;  top: 0;  right: -90px;" >
                     
                    <v-btn text small class=" mr-1 px-4" style="opacity: 0.85" :class="productPriorityClass(product.product_priority)" v-if="product.product_discount_percent > 0">{{ product.product_discount_percent }} %</v-btn>
                    <br>
                   <v-btn  text class="blue lighten-3 mr-1" style="opacity: 0.85" x-small v-if="product.status < 0 && product.product_new_discount_percent > 0">{{ product.product_new_discount_percent }} %</v-btn> 
                        </v-container>
                    </v-img>
                  
                    <v-row class="justify-center ma-0 pa-0 ">
                       
                    <v-img class=" my-0 mt-n10  ml-n15 px-2 pa-2  white elevation-5"  style="margin-right: 140px;"   eager  v-if="$store.state.settings['' + product.site_cms + '_pushover_icon']" :src="$store.state.settings['' + product.site_cms + '_pushover_icon']" max-width="40" max-height="40"  cover />
                      
                    
                    </v-row>
                <v-row class="justify-center mt-4">
                     <v-card-text v-html="(product.resealed == 1 ? '<span class=\'mr-2\'>[ <span class=\'blue--text text--darken-2 font-weight-bold\'>'+ (product.resealed_text != '' ? product.resealed_text.toUpperCase() : 'Újracsomagolt' ) + '</span> ] </span> ' : '') + product.product_name" :class="productPriorityClass(product.product_priority)"  class=" justify-center text-center pa-0 pa-3 mb-2 font-weight-normal" style="font-size: 0.8rem; word-break: break-word"> 
              
             </v-card-text>

                </v-row>

                <v-row class="justify-center " v-if="product.is_top_price == '1' && product.top_price_percent > 0">
                
                <v-col cols="12" class="purple lighten-5"> 
                  <v-btn text x-small class="mx-0 mb-0 purple white--text mr-1" @click="orderByTopPricePercent()">{{ product.top_price_percent }} %</v-btn>
                <span v-for="index in Math.ceil(product.top_price_percent / 10)" :key="index">
                  <v-btn text x-small class="mx-0 mb-0 red white--text mr-1" v-if="product.is_top_price == '1'"><i class="fas fa-fire"></i></v-btn>
                </span>
              </v-col>
              </v-row>

              <v-row class="justify-center mt-4">

                  <v-btn text small class="mx-1 mb-1 red white--text mr-1" v-if="product.is_top_price == '1'"><i class="fas fa-fire"></i></v-btn>
                    <v-btn @click="getProductChanges(product.id, product)" small style="font-size: 1.2rem; " class="red lighten-5 red--text mx-1 pa-0 px-4 " elevation="0" text >
                      <template>
                        <i class="fas fa-arrow-down green--text mr-2" v-if="product.status < 0"></i>
                        <i class="fas fa-arrow-up red--text mr-2" v-if="product.status > 0"></i>
                      </template>
                      {{ mFormat(product.product_discount_price) }}
                    </v-btn>
                
                </v-row>

                  </v-col>
                  <v-col cols="12" md="12" class="my-0" align="center">

                    <v-card-text v-if="product.last_change_count > 0 && product.status < 0 && product.top_price_percent == 0 " class="text-caption red--text pa-0 my-1 mb-2" :class="(product.last_change_count >= 0 && product.last_change_count <= 10 ? 'orange lighten-5' : 'red lighten-5') ">
                            
                            <span>
                            <i class="fas fa-chart-bar red-text"></i> <span v-html="product.last_change_count + ' alkalommal került már ennyibe'"></span> 
                            </span>
                            
                        </v-card-text>
                        
                        <v-btn x-small class="mx-1" style="position: abolsute; left: 0;" @click="addToFavourite(product)">
                      <template>
                        <i class="far fa-heart" v-if="product.favourite == '0'"></i>
                        <i class="fas fa-heart pink--text" v-else-if="product.favourite == '1'"></i>
                      </template>
                    </v-btn>
                    <v-btn v-if="product.site_cms == 'auchan' || product.site_cms == 'praktiker'" title="Készletinfo" class="red mx-1" x-small @click="getProductStockInfo(product)">
                      <i class="white--text fas fa-layer-group"></i>
                    </v-btn>
                    <v-btn :class="JSON.parse(product.basket_changes).length > 1 ? 'purple' : 'brown' " class=" white--text ma-1" v-if="product.basket_changes != '' &&  JSON.parse(product.basket_changes).length > 0" x-small @click="showBasketChanges(product.id, product)">
                      <i class="fas fa-chart-area mr-2"></i> {{ JSON.parse(product.basket_changes).length }}
                    </v-btn>
                    
               
                       <v-btn  @click="fixHighestPriceDialog(product)" text  x-small class="red lighten-5 mx-1" elevation="0" >
                      <template>
                        <s v-if="product.product_discount_price != product.highest_price">{{ mFormat(product.highest_price) }}</s>
                        <i v-else class="fas fa-wrench"></i>
                      </template>
                    </v-btn>
                    <v-divider class="my-1 mx-10 "></v-divider>
             
             <v-divider class="my-1 mx-14 "></v-divider>
                <template>
                    

                    <v-btn text x-small class=" mr-1" :class="productPriorityClass(product.product_priority)" v-if="product.product_discount_percent > 0">{{ product.product_discount_percent }} %</v-btn>
                   <v-btn  text class="blue lighten-3 mr-1" x-small v-if="product.status < 0 && product.product_new_discount_percent > 0">{{ product.product_new_discount_percent }} %</v-btn> 
                   
                    <v-btn  text class="blue lighten-3 mr-1" x-small v-if="product.status < 0 && product.product_previous_price > 0">{{ mFormat(product.product_previous_price) }}</v-btn> 
                    <template v-if="product.ai_priority > 0" >
                    
                    <v-btn  text class="yellow lighten-2" x-small @click="orderByAi()"><i class="fas fa-magic mr-3" ></i> {{ product.ai_priority }}</v-btn> 
                    </template>
                </template>
              
                 <template v-if="product.lowest_price_diff > 0">

                  <v-divider class="my-1 mx-14 "></v-divider>

                 <v-btn x-small class="cyan lighten-3" @click="orderByLowestPriceDiff()" ><i class="fas fa-bolt blue--text text--darken-4 mt-1 mr-2"></i>  {{ mFormat(product.lowest_price) }} ({{ product.lowest_price_diff  }}%)</v-btn>

                 </template>
                    
                    
                    
                    
                    
                      
                    
                                      </v-col>
                </v-row>"
              </v-card-text>
              <v-card-text style="border: 1px dotted #000" class="pa-1 mt-0 my-1 mb-2 text-center" v-if="product.listpage_check_buy_again != ''">

               <div class="green lighten-4"> <i class="fas fa-shopping-cart"></i>  - Terméklista oldal kosár megfigyelve</div>
                <div class="green lighten-5">
                  <span v-if="product.listpage_check_buy_again_set > 0">
                    
                 Push sound:  <b>{{JSON.parse(product.listpage_check_buy_again).pushover_sound}} </b> <i class="fas fa-check green--text"></i>
                 <br>
                 Ha kosárba rakható: <i class="fas " :class="JSON.parse(product.listpage_check_buy_again).if_has_basket_button === true || JSON.parse(product.listpage_check_buy_again).if_has_basket_button == 'true' ? 'fa-check green--text' : 'fa-times red--text'"></i> <i v-if="JSON.parse(product.listpage_check_buy_again).if_has_basket_button_reverse == 'true'" class="orange--text fas fa-retweet"></i>
                <br>
                Ha olcsóbb mint:  <i class="fas " :class="JSON.parse(product.listpage_check_buy_again).if_price_lower_than === true || JSON.parse(product.listpage_check_buy_again).if_price_lower_than === 'true' ? 'fa-check green--text' : 'fa-times red--text'"></i>  <i v-if="JSON.parse(product.listpage_check_buy_again).if_price_lower_than_reverse == 'true'" class="orange--text fas fa-retweet"></i>
                <span v-if="JSON.parse(product.listpage_check_buy_again).if_price_lower_than === true || JSON.parse(product.listpage_check_buy_again).if_price_lower_than === 'true'">  - {{ mFormat(JSON.parse(product.listpage_check_buy_again).price) }}</span>
                <br>
                <v-btn x-small class="blue white--text"   v-if="JSON.parse(product.listpage_check_buy_again).autoorder === 'true'">AUTOORDER: <i class="fas fa-check white--text"></i></v-btn>
              </span>
                  
                </div>
              </v-card-text>
              <v-card-text v-if="product.observe != ''" class=" brown lighten-3  text-caption pa-1 px-1">
                
                <v-row class="orange lighten-5 pa-2 px-2">
                  <template v-if="product.observe_last_refresh"> [ <i class="far fa-calendar-check grey--text fa-lg ml-2"></i> <Momentago bold="false" :date="product.observe_last_refresh"></Momentago> ] </template>
                  <template v-if="product.observe_last_status">
                    <v-btn x-small v-if="product.observe_last_status == 'OK'" class="mx-1 green white--text">{{ product.observe_last_status }}</v-btn>
                    <v-btn x-small v-else class="mx-1 red white--text">{{ product.observe_last_status }}</v-btn>
                  </template>
                </v-row>
               
                <v-row class="blue lighten-5 pa-2 px-2">
                  <v-row class="mt-1">
                  <b>Csoport</b> <v-btn x-small :to="'/akciok?observe_group=' + product.observe_group + '&show_products=1'" target="_blank" class="grey lighten-3 px-1 mx-2">{{ product.observe_group }} </v-btn>
                  </v-row >
                  <v-row class="mt-1"> 
                    <b class="mr-1">Termékoldal kosár:</b> {{ product.product_page_has_basket_button }} | <b class="mx-1">Termékoldal ár:</b> {{ mFormat(product.product_page_price) }} 
                  </v-row>
               
                </v-row>
                <v-row class="blue lighten-4 pa-2 px-2">
                  <v-col cols="12">
                  Push sound:  <b>{{JSON.parse(product.observe).pushover_sound}} </b> <i class="fas fa-check green--text"></i>
                  </v-col>
                  <v-col cols="12">
                 Ha kosárba rakható: <i class="fas " :class="JSON.parse(product.observe).has_basket_button === true || JSON.parse(product.observe).has_basket_button == 'true' ? 'fa-check green--text' : 'fa-times red--text'"></i> <i v-if="JSON.parse(product.observe).has_basket_button_reverse == true" class="orange--text fas fa-retweet"></i>
                  </v-col>
                  <v-col cols="12">

                Ha olcsóbb mint:  <i class="fas " :class="JSON.parse(product.observe).discount_price_lower_then === true || JSON.parse(product.observe).discount_price_lower_then === 'true' ? 'fa-check green--text' : 'fa-times red--text'"></i>  <i v-if="JSON.parse(product.observe).discount_price_lower_then_reverse == true" class="orange--text fas fa-retweet"></i>
                <span v-if="JSON.parse(product.observe).discount_price_lower_then === true || JSON.parse(product.observe).discount_price_lower_then === 'true'">  - {{ mFormat(JSON.parse(product.observe).discount_price_lower_then_price) }}</span>
         
                  </v-col>
                  <v-col cols="12">
                     <v-btn v-if="JSON.parse(product.observe).autoorder == true" x-small class="blue white--text ">Autoorder!</v-btn>
                    
                  </v-col>

                 
                </v-row>
                 
              </v-card-text>
              <v-card-text v-if="product.product_extra_info != '' " class=" text-caption pa-0 px-2">
                  <div v-for="extra in product.product_extra_info.split('#')" :key="extra" class="my-1 red lighten-5 px-1 text-center">
                  <span v-if="extra != ''" >
                   <i class="fas fa-exclamation red--text"></i> <span v-html="extra"></span> 
                  </span>
                  </div>
              </v-card-text>

              <v-card-text v-if="product.product_extra_stock_info != '' " class=" text-caption pa-0 px-2">
                  <div v-for="stock in product.product_extra_stock_info.split('#')" :key="stock" class="my-1 red lighten-5 px-1 text-center">
                  <span v-if="stock != ''" >
                   <i class="fas fa-exclamation orange--text"></i> <span v-html="stock"></span> 
                  </span>
                  </div>
              </v-card-text>
              
              <v-card-text v-if="product.arukereso_different > 0 && product.arukereso_data != ''" class=" text-caption pa-0">
                  <div  class="my-1 purple lighten-5 mx-2 px-1 text-center">
                    ÁK Check: 
                  <span v-for="(akdata, key) in JSON.parse(product.arukereso_data)" :key="key" >
                  <span v-if='key == "date"'> <MomentAgo :date="akdata" background="transparent" bold="false"></MomentAgo> | </span>
                  <span v-if='key == "product_url"'> <a :href="akdata" target="_blank">Árukereső URL</a> | </span> 
                  <span v-if='key == "product_arukereso_price"'> <span v-html="mFormat(akdata)"></span> | </span>
                 
                  
                  </span>
                  <span v-if='product.arukereso_different > 0'> <span v-html="product.arukereso_different"></span> %  </span>
                  </div>
                  
              </v-card-text>
              <v-card-text class="lime lighten-4 pa-1 text-caption" style="height: 30px;" v-if='product.product_extra_lego_info != ""'>
                <v-row class="justify-center text--center ">
                    <v-img inline class="   mr-2" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/LEGO_logo.svg/2048px-LEGO_logo.svg.png" max-width="20" height="20"></v-img>
                    
                    <span style="border-left: 1px dotted #000" class="float-left px-2" v-for="(legodata, key) in JSON.parse(product.product_extra_lego_info)" :key="key" >
                  
                      <span v-if='key == "retired"'></span>
                      <span v-else-if='key == "retail_price" && legodata != ""'  class="purple--text  " > {{ mFormat(legodata) }}</span>
                      <span v-else-if='key == "value_price" && legodata != "" '  class="red--text " > {{ mFormat(legodata) }}</span>
                      <span v-else-if='key == "availability" && legodata == "Retired"'   >Kifutott!</span>
                      <span v-else-if='key == "pieces"' >{{ legodata }} db</span>
                      <span v-else-if='key == "availability" && legodata == "Retail"'  >Jelenleg is kapható!</span>
                      <span v-else > {{ legodata }}</span>
                    
                      
                      </span>
              
                    </v-row>

              </v-card-text>

              <v-card-text v-if="product.arukereso_different == 0 && product.arukereso_data != ''" class=" text-caption pa-0">
              <div  class="my-1 blue lighten-5 mx-2 px-1 text-center">
                ÁK Check: 
                  <span v-for="(akdataa, keyy) in JSON.parse(product.arukereso_data)" :key="keyy" >
                    <span v-if='keyy == "date"'> <MomentAgo :date="akdataa" background="transparent" bold="false"></MomentAgo> | </span>
                    <span v-if='keyy == "product_arukereso_price"'> <span class="ml-1 red--text" v-html="mFormat(akdataa)"></span> | </span>
                    
                  </span>
                  <span v-if='product.arukereso_different > 0'> <span v-html="product.arukereso_different"></span> %  </span>
                  </div>
              </v-card-text>
              <v-card-text  v-if="product.product_number != ''">
                <span class="text-caption" >Termékkód: </span>
                <v-btn x-small class="grey lighten-4" :href="'https://www.arukereso.hu/CategorySearch.php?sp=2&st=' + product.product_number" target="_blank">Arukereso</v-btn>
                 <v-btn x-small class="grey lighten-4" @click="copyText(product.product_number)">{{product.product_number}}</v-btn>

                
              </v-card-text>

              <v-card-text class="text-center" v-if="product.sharelist != ''">
                 <v-btn  x-small class=" orange white--text" @click="setGlobalFilters({ sharelist: product.sharelist})"> {{product.sharelist}}</v-btn> 
              </v-card-text>
              <v-card-text v-if="product.product_changes != ''">
               
                
              <v-row justify-center center class="text--center">
                <sparkline :width="(JSON.parse(product.product_changes).length * 20)" class="ml-auto mr-auto " :indicatorStyles="spIndicatorStyles3">
                <sparklineCurve :data="JSON.parse(product.product_changes)" :limit="JSON.parse(product.product_changes).length" :styles="spCurveStyles2" />
              </sparkline>
            </v-row>
              </v-card-text>

                 <!--


                    //////////////////////////////////////////////////////////
                    //
                    //  Funkciógombok
                    //
                    //////////////////////////////////////////////////////////

                  -->

                <v-card-text  v-if="product.show_extra_menu" class="text-center">
                  <v-btn class="orange white--text  ml-1" x-small @click="recycleProduct(product)"> <i class="fas fa-recycle"></i></v-btn>
                    
                    <v-btn x-small class="mx-1" @click="excludeProduct(product)">
                      <template>
                        <i class="fas fa-times-circle" v-if="product.exclude == '0'"></i>
                        <i class="fas fa-tint-slash red--text" v-else-if="product.exclude == '1'"></i>
                      </template>
                    </v-btn>
                    <v-btn x-small class="mx-1" @click="addToFavourite(product)">
                      <template>
                        <i class="far fa-heart" v-if="product.favourite == '0'"></i>
                        <i class="fas fa-heart pink--text" v-else-if="product.favourite == '1'"></i>
                      </template>
                    </v-btn>
                    <v-btn x-small class="mx-1" @click="setProductAlert(index)"><i class="far fa-bell"></i></v-btn>
                    <v-btn class="yellow mx-1" x-small @click="copyToPh(product)">
                      <i class="fas fa-paper-plane"></i>
                    </v-btn>
                    <v-btn v-if="product.site_cms == 'auchan' || product.site_cms == 'praktiker'" title="Készletinfo" class="red mx-1" x-small @click="getProductStockInfo(product)">
                      <i class="white--text fas fa-layer-group"></i>
                    </v-btn>
                    <v-btn class="purple white--text ma-1" x-small @click="sendToDiscord(product)">
                      <i class="fab fa-discord"></i>
                    </v-btn>
                    <v-btn class="blue white--text ma-1" x-small @click="getProductLogTimeline(product.id, product)">
                      <i class="fas fa-stream"></i>
                    </v-btn>
                   

                      <v-btn @click="showLastSeenJsonDataDialog(product.last_seen_data)" x-small class="brown lighten-4 mx-1 pa-0 px-2" elevation="1" outlined>
                      <i class="fas fa-align-center"></i>
                    </v-btn>
                    
                    <template v-if="product.listpage_check_buy_again_set == 0">
                    <v-btn class="green white--text ma-1" x-small @click="checkIfBuyableAgain(product)">
                    <i class="fas fa-cart-plus"></i>
                    </v-btn>
                    
                    </template>
                    <template v-else>
                      <v-btn class="orange white--text ma-1" x-small @click="checkIfBuyableAgain(product)">
                    <i class="fas fa-shopping-cart"></i>
                    </v-btn>
                    
                    </template>
                    <v-btn class="purple white--text  ml-1" target="_blank" :href="'https://bb.supreme7.eu/api/shopurlparser/productpagecheck?product_id=' + product.id" x-small><i class="fas fa-search-location"></i></v-btn>
                    
                   
                    <v-btn x-small :href="product.last_seen_url" target="_blank" class="ml-1"><i class="fas fa-bullseye orange--text"></i></v-btn>
                     <v-btn v-if="product.url_id != 0" x-small :href="'https://vue.supreme7.eu/shopurls?shop_url_id='+ product.url_id +'&show_url_list=1'" target="_blank" class="green lighten-1 ml-1"><i class="fas fa-unlink  white--text"></i></v-btn>
                </v-card-text>

              <v-card-actions class="grey lighten-3 card-actions" >
                <v-btn x-small @click="showExtraMenu(index)" class="teal lighten-1 white--text"><i class="fas fa-tools"></i></v-btn>
                <v-btn target="_blank" :href="'https://google.hu/search?q=' + productNameToUrl(product.product_name)" x-small>Google</v-btn>
                <v-btn target="_blank" :href="'https://arukereso.hu/CategorySearch.php?st=' + productNameToUrl(product.product_name)" x-small>Árukereső</v-btn>
                <v-btn x-small :href="product.product_url" target="_blank">URL</v-btn>
                <v-spacer></v-spacer>
                <v-btn x-small v-if="product.has_basket_button > 0" class="green white--text"><i class="fas fa-shopping-cart"></i></v-btn>

         
                
                

                
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        
      <!--


          //////////////////////////////////////////////////////////
          //
          //  KATEGÓRIÁK
          //
          //////////////////////////////////////////////////////////

        -->



      <v-card-text v-if="Object.keys(categories).length > 0 && Object.keys(products).length <= 0" class="pa-0" :style="showCategoryDetails ? '' : 'display: flex; flex-direction: column; '">
        <template >

          <template v-if="!loadingCategories">


          

            <div class="ma-0" v-for="(sprior, spindex) in categories.response.pgroup" :key="spindex">
              

              <v-card class="mt-0 ma-0 mt-1" elevation="0" >
                <v-card-title class="pa-1 pl-3 grey lighten-3 black--text font-weight-normal text-subtitle-1 text-overline justify-center" >

                 

                  
                
                  <template v-if="spindex == '1' || spindex == '2' || spindex == '3' || spindex == '4' || spindex == '5' || spindex == '6' || spindex == '7' || spindex == '8'">
                    
                    <v-btn class="mr-1" v-if="categories.response.pgroup[spindex].all_new > 0" x-small target="_self" @click="setGlobalFilters({ product_shopurl_priority: spindex, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"><i class="fas fa-rss mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_new }}</v-btn>

                    <v-btn class="purple lighten-2 white--text mr-1" v-if="categories.response.pgroup[spindex].all_unwatched > 0" x-small target="_self" @click="setGlobalFilters({ product_shopurl_priority: spindex, watched: '0', order_by: 'product_discount_percent', sort_by: 'DESC', show_products: 1 })"><i class="fas fa-tag mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_unwatched }}</v-btn>
                  </template>

                  <template v-if="spindex == 'Árukereső'">
                    <v-btn class="mr-1" v-if="categories.response.pgroup[spindex].all_new > 0" x-small target="_self" @click="setGlobalFilters({ only_new: '1', site_cms: 'arukereso', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"><i class="fas fa-rss mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_new }}</v-btn>

                    <v-btn class="purple lighten-2 white--text mr-1"  v-if="categories.response.pgroup[spindex].all_unwatched > 0" x-small target="_self" @click="setGlobalFilters({ site_cms: 'arukereso', watched: '0', order_by: 'product_discount_percent', sort_by: 'DESC', show_products: 1 })"><i class="fas fa-tag mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_unwatched }}</v-btn>
                  </template>

                  <template v-if="spindex == 'Újracsomagolt'">
                    <v-btn class="mr-1" v-if="categories.response.pgroup[spindex].all_new > 0" x-small target="_self" @click="setGlobalFilters({ only_new: '1', resealed: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"><i class="fas fa-rss mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_new }}</v-btn>

                    <v-btn class="purple lighten-2 white--text mr-1" v-if="categories.response.pgroup[spindex].all_unwatched > 0" x-small target="_self" @click="setGlobalFilters({ resealed: '1', watched: '0', order_by: 'product_discount_percent', sort_by: 'DESC', show_products: 1 })"><i class="fas fa-tag mr-1 mt-1"></i> {{ categories.response.pgroup[spindex].all_unwatched }}</v-btn>
                  </template>
                  <v-divider vertical class="mx-2"></v-divider>
                  <span > <i class="fas fa-quote-right "></i> {{formatSpindex(spindex)}} </span>

                </v-card-title>
              </v-card>
              <v-row class="justify-center">
              <v-card class="my-2" elevation="0" outlined :height="showCategoryDetails ? '' : '100px' " :min-width="showCategoryDetails ? '200px' : '180px' " :max-width="showCategoryDetails ? '100%' : '450px' " :style="showCategoryDetails ? '' : ''" :cols="showCategoryDetails ? 4 : ''"  v-for="(sitecms, index) in sprior.all_cms" :key="index"  :class="showCategoryDetails ? 'ma-0 col-12 justify-center' : 'col' ">
                <v-card-title class="grey lighten-5 align-center justify-center pa-1 pt-2 ">
                  <v-img  @click="setGlobalFilters({ site_cms: index, watched: '0', order_by: 'product_discount_percent', sort_by: 'DESC', show_products: 1 })" v-if="$store.state.settings['' + index + '_pushover_icon']" :src="$store.state.settings['' + index + '_pushover_icon']" max-width="30px" class="mr-2" />

                 <span :class="showCategoryDetails ? '' : 'text-overline'" @click="setGlobalFilters({ site_cms: index, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> {{ index.toUpperCase() }} </span>
                 
                </v-card-title>
                
                <v-divider></v-divider>

                <template >
                  <v-card-subtitle v-if="spindex == '1' || spindex == '2' || spindex == '3' || spindex == '4' || spindex == '5' || spindex == '6' || spindex == '7' || spindex == '8'" class="d-flex align-center justify-center">
                    <v-btn v-if="sitecms.all_new > 0" text x-small class="mr-1 pa-3 text-overline green lighten white--text" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-calendar-plus mr-2"></i> {{ sitecms.all_new }} </v-btn>
                    <v-btn v-if="sitecms.all_unwatched > 0" text x-small class="mr-1 pa-3 text-overline red white--text" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-chart-bar mr-2"></i> {{ sitecms.all_unwatched }} </v-btn>
                    <v-btn x-small style="display: none;"><i class="fas fa-check-double"></i></v-btn>
                  </v-card-subtitle>
                  <v-card-subtitle v-if="spindex == 'Árukereső'" class="d-flex align-center justify-center">
                    <v-btn v-if="sitecms.all_new > 0" text x-small class="mr-1 pa-3 text-overline green white--text" @click="setGlobalFilters({ site_cms: index, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-calendar-plus mr-2"></i> {{ sitecms.all_new }} </v-btn>
                    <v-btn v-if="sitecms.all_unwatched > 0" text x-small class="mr-1 pa-3 text-overline red white--text" @click="setGlobalFilters({ site_cms: index, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-chart-bar mr-2"></i> {{ sitecms.all_unwatched }} </v-btn>
                    <v-btn x-small style="display: none;"><i class="fas fa-check-double"></i></v-btn>
                  </v-card-subtitle>
                  <v-card-subtitle v-if="spindex == 'Újracsomagolt'" class="d-flex align-center justify-center">
                    <v-btn v-if="sitecms.all_new > 0" text x-small class="mr-1 pa-3 text-overline green white--text" @click="setGlobalFilters({ resealed: '1', site_cms: index, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-calendar-plus mr-2"></i> {{ sitecms.all_new }} </v-btn>
                    <v-btn v-if="sitecms.all_unwatched > 0" text x-small class="mr-1 pa-3 text-overline red white--text" @click="setGlobalFilters({ resealed: '1', site_cms: index, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> <i class="fas fa-chart-bar mr-2"></i> {{ sitecms.all_unwatched }} </v-btn>
                    <v-btn x-small style="display: none;"><i class="fas fa-check-double"></i></v-btn>
                  </v-card-subtitle>
                </template>

                <template v-if="showCategoryDetails === true">
                <v-card-text v-for="(prefix, pindex) in sitecms.site_prefixes" :key="pindex" class="pa-0 mt-3">
                  <v-card-subtitle class="orange lighten-5 pa-2 px-2 d-flex align-center justify-center">
                    <v-spacer></v-spacer>

                    <template v-if="spindex == '1' || spindex == '2' || spindex == '3' || spindex == '4' || spindex == '5' || spindex == '6' || spindex == '7' || spindex == '8'">
                      <v-btn v-if="prefix.all_new > 0" x-small class="mx-1 orange white--text" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, site_prefix: prefix.site_prefix, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_new }}
                      </v-btn>
                      <v-btn v-if="prefix.all_unwatched > 0" x-small class="mx-1 purple white--text" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, site_prefix: prefix.site_prefix, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_unwatched }}
                      </v-btn>
                    </template>
                    <template v-if="spindex == 'Árukereső'">
                      <v-btn v-if="prefix.all_new > 0" x-small class="mx-1 orange white--text" @click="setGlobalFilters({ site_cms: index, site_prefix: prefix.site_prefix, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_new }}
                      </v-btn>
                      <v-btn v-if="prefix.all_unwatched > 0" x-small class="mx-1 purple white--text" @click="setGlobalFilters({ site_cms: index, site_prefix: prefix.site_prefix, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_unwatched }}
                      </v-btn>
                    </template>
                    <template v-if="spindex == 'Újracsomagolt'">
                      <v-btn v-if="prefix.all_new > 0" x-small class="mx-1 orange white--text" @click="setGlobalFilters({ resealed: '1', site_cms: index, site_prefix: prefix.site_prefix, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_new }}
                      </v-btn>
                      <v-btn v-if="prefix.all_unwatched > 0" x-small class="mx-1 purple white--text" @click="setGlobalFilters({ resealed: '1', site_cms: index, site_prefix: prefix.site_prefix, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                        {{ prefix.all_unwatched }}
                      </v-btn>
                    </template>

                    <span class="font-weight-bold"> {{ prefix.site_prefix.toUpperCase() }} </span>
                    <v-spacer></v-spacer>

                    <v-btn style="display: none;" x-small outlined color="orange"><i class="fas fa-check-double"></i></v-btn>
                    <v-btn style="display: none;" x-small outlined color="purple"><i class="fas fa-check-double"></i></v-btn>

                    <v-spacer></v-spacer>
                  </v-card-subtitle>

                  <v-card-text v-if="prefix.categories" class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0 my-1">
                      <v-row v-for="(pf, ind) in prefix.categories" :key="ind" class="ma-0 col-12 col-md-6 pa-0" style="height: 36px !important; min-height: auto">
                        <v-col class="pa-1 ma-1 grey lighten-4">
                          <v-col cols="12" class="d-flex">
                            <template v-if="spindex == '1' || spindex == '2' || spindex == '3' || spindex == '4' || spindex == '5' || spindex == '6' || spindex == '7' || spindex == '8'">
                              <v-btn v-if="pf.all_new > 0" x-small class="mx-1 yellow lighten-4" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_new }}
                              </v-btn>
                              <v-btn v-if="pf.all_unwatched > 0" x-small class="mx-1 purple lighten-4" @click="setGlobalFilters({ product_shopurl_priority: spindex, site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_unwatched }}
                              </v-btn>
                            </template>
                            <template v-if="spindex == 'Árukereső'">
                              <v-btn v-if="pf.all_new > 0" x-small class="mx-1 yellow lighten-4" @click="setGlobalFilters({ site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_new }}
                              </v-btn>
                              <v-btn v-if="pf.all_unwatched > 0" x-small class="mx-1 purple lighten-4" @click="setGlobalFilters({ site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_unwatched }}
                              </v-btn>
                            </template>
                            <template v-if="spindex == 'Újracsomagolt'">
                              <v-btn v-if="pf.all_new > 0" x-small class="mx-1 yellow lighten-4" @click="setGlobalFilters({ resealed: '1', site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_new }}
                              </v-btn>
                              <v-btn v-if="pf.all_unwatched > 0" x-small class="mx-1 purple lighten-4" @click="setGlobalFilters({ resealed: '1', site_cms: index, site_prefix: prefix.site_prefix, category: pf.category, watched: '0', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })">
                                {{ pf.all_unwatched }}
                              </v-btn>
                            </template>

                            <v-spacer></v-spacer>

                            <span class="">{{ pf.category }} </span>

                            <v-spacer></v-spacer>
                            <v-btn style="display: none;" x-small outlined color="orange"><i class="fas fa-check-double"></i></v-btn>
                            <v-btn style="display: none;" x-small outlined color="purple"><i class="fas fa-check-double"></i></v-btn>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card-text>
                </v-card-text>
                </template>
              </v-card>
              </v-row>
            </div>
          </template>
          <template v-else-if="loadingCategories">
            <v-skeleton-loader type="articel" class="my-3"></v-skeleton-loader>
          </template>
          <template v-else>
            <v-alert color="warning" outlined>Úgy tűnik nincs találat</v-alert>
          </template>
        </template>
        <v-skeleton-loader type="article" v-if="loadingCategories"></v-skeleton-loader>
      </v-card-text>
  

 <v-col cols="12">
          <v-pagination class="my-2 grey lighten-3 text-caption" @change="getProductList()" v-if="Object.keys(products).length > 0" v-model="globalFilters.page" :length="products.response.all_pages" :total-visible="8"></v-pagination>
        </v-col>
       

    <v-footer fixed class="pa-0" :style="this.$vuetify.breakpoint.mobile ? 'margin-bottom: 48px;' : 'margin-bottom: 0px'">
      <v-row>

        <v-col cols="12" class="d-flex grey lighten-2 py-1">
          <v-spacer></v-spacer>
          <v-btn small class="mx-1" v-if="globalFilters.show_products" @click="filterReset('max_date')"><i class="fas fa-history orange--text"></i></v-btn>
          <v-btn small v-if="globalFilters.show_products == '1'" class="mx-1 red white--text" @click="showCategories()"><i class="fas fa-home"></i></v-btn>
          <v-btn small class="mx-1 orange lighten-3 px-2" @click="showProductFilterDialog()">
            <template v-if="Object.keys(products).length > 0 && products.response.count > 0">

              {{products.response.count}}

              
            </template>
            <template v-else>
            <i class="fas fa-chart-pie"></i>
            </template>

          </v-btn>
          
          <v-btn small class="mx-1" @click="shareThisList()" v-if="Object.keys(products).length > 0 && products.response.count > 0"> <i class="fas fa-share-alt"></i></v-btn>
          <v-btn small class="mx-1" @click="seenThisPage()" v-if="Object.keys(products).length > 0 && products.response.count > 0"> <i class="fas fa-share-square"></i></v-btn>
          <v-btn small class="mx-1" v-if="seenProducts.length > 0" @click="setSeenProductsWatched('ids', true)"><i class="fas fa-check"></i> [{{ seenProducts.length }}]</v-btn>
          <v-btn small class="mx-1" v-if="Object.keys(products).length > 0" @click="setSeenProductsWatched('all_filtered')"><i class="fas fa-check-double"></i></v-btn>
          <template v-if="Object.keys(categories).length > 0 && globalFilters.show_products != '1'">
            <v-btn  @click="showCategoryDetails = !showCategoryDetails" small><i class="fab fa-hive mr-1"></i> Részletes</v-btn>
            <v-btn small v-if="categories.response.all_new > 0" class="orange white--text mr-1 ml-2" @click="setGlobalFilters({ only_new: '1', order_by: 'product_discount_price', sort_by: 'ASC', show_products: 1 })"> {{ categories.response.all_new }}</v-btn>
            <v-btn small v-if="categories.response.all_unwatched > 0" class="purple white--text mr-1" @click="setGlobalFilters({ watched: '0', order_by: 'product_discount_percent', sort_by: 'DESC', show_products: 1 })"> {{ categories.response.all_unwatched }}</v-btn>
          </template>

          <v-spacer></v-spacer>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import global_mixin from '../mixins/global_mixin'
import { Sparkline } from 'vue-sparklines'
import FinancialChart from '../components/charts/Financial.vue'
import Momentago from '../components/settings/Momentago.vue'

export default {
  components: { FinancialChart, Momentago },
  props: {
    default_filters: {
      default: false,
    },
  },
  mixins: [global_mixin],
  data() {
    return {
      chartOptions: {},
      showProductChangeDialog: false,
      productChangeDataset: {},
      globalFilters: {
        page: 1,
        max_date: this.mysqlDateString(),
      },
      no_image: require('../assets/no_image.png'),
     // no_image: "https://bb.supreme7.eu/thumbs/fit_5757.jpg",
      pagination: 1, 
      showLastSeenJsonData: false,
      loadingProducts: false,
      compactView: false,
      lastSeenJsonDataFormatted: '',
      alertFilterData: {},
      loadingProductChanges: false,
      productAlert: false,
      productAlertData: {},
      productChangesItem: false,
      productChanges: {},
      seenProducts: [],
      filters: {},
      filterTab: 0,
      switch_product_changes: 'distinct',
      showStockInfo: false,
      stockInfo: '',
      stockInfoData: [],
      productsTab: 0,
      products: {},
      categories: {},
      loading: false,
      loadingCategories: false,
      showFilterDialog: false,
      fixHighestPriceData: {},
      showFixHighestPriceDialog: false,
      sendToDiscordDialog: false,
      sendToDiscordData: {},
      webhooks: {},
      productId: null,
      showProductLogTimeline: false,
      loadingProductLogTimeline: false,
      productLogTimelineData: [],
      productLogTimeline: false,
      filteredSiteCmsList: false,
      showFilteredSiteCmsList: false,

      // SPARKLINE? ///////////////////
      spLineStyles1: {
        stroke: "#54a5ff",
        strokeOpacity: 0,
        strokeDasharray: "2, 9"
      },
      spCurveStyles2: {
        stroke: "#54a5ff"
      },
      spIndicatorStyles3: {
        stroke: '#99CC00'
      },



      productBasketChanges: false,
      showProductBasketChanges: false,
      loadingProductBasketChanges: false,
      
      priceFilter: {
        min_original_price: 0,
        max_original_price: 0,

      },
      product_buyable_again_dialog: false,
      product_buyable_again_settings: {},
      productBuyableAgainData: {},
      productImageDialog: false,
      productImageDialogSrc: "",
      showCategoryDetails: false,
      menu: false,
    } 
  },

  methods: {


    showFilteredProducts(){

    },
    showExtraMenu(index){

      if(this.products.response.data[index].show_extra_menu){
      this.$set(this.products.response.data[index], 'show_extra_menu', !this.products.response.data[index].show_extra_menu)  
      }else{
      this.$set(this.products.response.data[index], 'show_extra_menu', true)
      }

    },
    formatSpindex(spindex){


      var formats = JSON.parse(this.$store.state.settings.spindex_format)
      var title = formats[spindex]
      
      if(title){
        return title
      }

      return spindex

    },
    orderByAi(){

      this.globalFilters.order_by = 'ai_priority'
      this.globalFilters.sort_by = 'DESC'
      this.doFilters()

    },
    orderByTopPricePercent(){

      this.globalFilters.order_by = 'top_price_percent'
      this.globalFilters.sort_by = 'DESC'
      this.doFilters()

    },

    orderByLowestPriceDiff(){

      this.globalFilters.order_by = 'lowest_price_diff'
      this.globalFilters.sort_by = 'ASC'
      this.doFilters()

    },
    showImageInDialog(src){

      this.productImageDialog = true
      this.productImageDialogSrc = src


    },
    PriceMinusPercent(percent = 5){

      var newprice = Math.ceil(this.fixHighestPriceData.highest_price - ((this.fixHighestPriceData.highest_price / 100) * percent))
      if(newprice < this.fixHighestPriceData.product_discount_price){

        this.fixHighestPriceData.highest_price =  this.fixHighestPriceData.product_discount_price

      }else{
        this.fixHighestPriceData.highest_price = newprice
      }
      

    },
    doGetProducts(key, val){


      this.$set(this.globalFilters, key, val)
      this.doFilters()


    },

    getProductStockInfo(product){

      var pid = '';

      switch(product.site_cms){

        case 'praktiker':
        pid = product.barcode_or_product_id;
        break;
        
        case 'auchan':
        pid = product.barcode_or_product_id;
        break;
        

      }

      this.$http.get('products/getstockinfo?id=' + pid + '&site_cms=' + product.site_cms).then((result) => {
                  if(result.data.info){
                  
                  this.showStockInfo = true
                  this.stockInfo = result.data.info
                  
                  this.stockInfoData = product


                }else{
                   this.$toast.error('Nem kaptunk készletinfót', {
                    timeout: 2000,
                  })

                }

      });


    },

    removeBuyableAgain(product_id){

        this.$confirm({
            message: `Eltávolítás?`,
            button: {
              no: 'Mégse',
              yes: 'Yap',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {


                  this.$http.get('products/removebuyableagain?product_id=' + product_id).then((result) => {
                  if(!result.data.error){
                  this.$toast.warning(this.productBuyableAgainData.product_name + ' - Kosárba rakható státusz eltávolítva', {
                    timeout: 2000,
                  })

                  this.productBuyableAgainData.listpage_check_buy_again = ""
                  this.productBuyableAgainData.listpage_check_buy_again_set = 0


                }else{
                   this.$toast.error(result.data.message, {
                    timeout: 2000,
                  })

                }
                })


              }
        },
      })

    },
    saveBuyableAgain(){

    

     this.$confirm({
            message: `Mentés?`,
            button: {
              no: 'Mégse',
              yes: 'Yap',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {
 
        
              let queryString = new URLSearchParams(this.product_buyable_again_settings).toString()

              this.$http.get('products/buyableagainchecker?' + queryString).then((result) => {
                if(!result.data.error){
                  this.$toast.success(this.productBuyableAgainData.product_name + ' - Kosárba rakható státusz figyelve!', {
                    timeout: 2000,
                  })

                  this.productBuyableAgainData.listpage_check_buy_again = JSON.stringify(this.product_buyable_again_settings)
                  this.productBuyableAgainData.listpage_check_buy_again_set = 1
                  

                  this.productBuyableAgainData.has_basket_button = 0

                }else{
                   this.$toast.error(result.data.message, {
                    timeout: 2000,
                  })

                }
              })

 }
        },
      })

    },
 
    checkIfBuyableAgain(product){

      this.productBuyableAgainData = product
      this.product_buyable_again_dialog = true


      if(product.listpage_check_buy_again != ""){

        this.$set(this.product_buyable_again_settings, 'product_id', product.id)
        this.$set(this.product_buyable_again_settings, 'pushover_sound', 'tugboat')
         this.product_buyable_again_settings =  {...this.product_buyable_again_settings, ...JSON.parse(this.productBuyableAgainData.listpage_check_buy_again)}

        if(this.product_buyable_again_settings.if_has_basket_button === "true"){
          this.product_buyable_again_settings.if_has_basket_button = true
        }else{
          this.product_buyable_again_settings.if_has_basket_button = false
        }

        if(this.product_buyable_again_settings.if_price_lower_than === "true"){
          this.product_buyable_again_settings.if_price_lower_than = true
        }else{
          this.product_buyable_again_settings.if_price_lower_than = false
        }


        if(this.product_buyable_again_settings.if_price_lower_than_reverse === "true"){
          this.product_buyable_again_settings.if_price_lower_than_reverse = true
        }else{
          this.product_buyable_again_settings.if_price_lower_than_reverse = false
        }

          if(this.product_buyable_again_settings.if_has_basket_button_reverse === "true"){
          this.product_buyable_again_settings.if_has_basket_button_reverse = true
        }else{
          this.product_buyable_again_settings.if_has_basket_button_reverse = false
        }



      }else{

      this.$set(this.product_buyable_again_settings, 'product_id', product.id)
      this.$set(this.product_buyable_again_settings, 'pushover_sound', 'tugboat')
      this.$set(this.product_buyable_again_settings, 'if_has_basket_button', true)
     this.$set(this.product_buyable_again_settings, 'if_price_lower_than', true)
      this.$set(this.product_buyable_again_settings, 'price', product.product_discount_price )
  
  }
         


    },
    seenThisPage(){


      let products = this.products
      let seenprods = this.seenProducts

//console.log("pp", products.response.data)

    Object.keys(products.response.data).forEach(function(entry) {



         if (!seenprods.includes(products.response.data[entry].id)) {
           seenprods.push(products.response.data[entry].id)
           
          }
        
        
      });


    this.setSeenProductsWatched('ids', true)

    },




    
    shareThisList(){

       var list = this.products.response.ids
       var countList = list.split(",").length 
       var dataarray = {};

      

        this.$prompt("Mentsd el ezt a listát ("+ countList +" termék)").then(text => {

          
          dataarray.ids = list;
          dataarray.name = text;
          
            this.axios.post('products/createsharelist', dataarray)
            .then(result => {
              
              if(result.data.error == false){

                this.$swal.fire({
                  title: "Sikerült",
                  text: result.data.message,
                  icon: "success",
                  footer: '<a href="/termekek/sharelist?sharelist='+ result.data.sharelist_final_name +'" target="_blank">Mutasd a listát</a>'

                });

                this.$store.commit('getSettings')
                this.setSeenProductsWatched('all_filtered', true)
             

              }else if(result.data.error == true){

                this.$toast.warning(result.data.message, {
                  timeout: 2000,
                })

              }else{

                 this.$toast.error("Hiba történt!", {
                  timeout: 2000,
                })

              }

            })

          });

      

    },

    decrement (key) {
        this.priceFilter[key] = (this.priceFilter[key] - 5)
      },
      increment (key) {
       this.priceFilter[key] = (this.priceFilter[key] + 5)
      },
    showLastSeenJsonDataDialog(data) {
     // console.log(data)

      var retString = ''
      this.lastSeenJsonDataFormatted = ''
      

      if (data != '') {
        const parsedJson = JSON.parse(data)

        for (const [index, value] of Object.entries(parsedJson)) {
          retString = retString + '<b>' + index + '</b> - ' + value + ' <br>'
        }

        this.lastSeenJsonDataFormatted = retString
        this.showLastSeenJsonData = true
      } else {
        this.$toast.warning('Nincsenek LASTSEENJSON adatok!', {
          timeout: 2000,
        })
      }
    },
    sendToDiscord(product) {
      this.sendToDiscordData = JSON.parse(JSON.stringify(product))
      this.sendToDiscordData.discord_webhook = 'priority-s7'
      this.sendToDiscordData.send_now = 'true'
      this.sendToDiscordDialog = true
      this.parseWebhooks()
    },
    doSendToDiscord() {
      let queryString = new URLSearchParams(this.sendToDiscordData).toString()

      this.sendToDiscordDialog = false

      this.$toast.info('Küldés folyamatban ...', {
        timeout: 500,
      })

      this.$http.get('discord/send?' + queryString).then(() => {
        this.$toast.success(this.sendToDiscordData.product_name + ' - Elküldve!', {
          timeout: 2000,
        })
      })
    },
    fixHighestPriceDialog(product) {
      this.fixHighestPriceData = JSON.parse(JSON.stringify(product))
      this.fixHighestPriceData.original_highest_price =  this.fixHighestPriceData.highest_price
      this.showFixHighestPriceDialog = true
    },

    doFixHighestPrice() {
      const productIndex = this.products.response.data.findIndex((x) => x.id === this.fixHighestPriceData.id)

      const upd_data = {
        table: 'shop_products',
        where: 'id',
        where_value: this.fixHighestPriceData.id,
        param: 'highest_price',
        value: this.fixHighestPriceData.highest_price,
      }

      const toast_success_text = 'A legmagasabb ár beállítva! (' + this.fixHighestPriceData.highest_price + ')'

      this.updateGlobalParam(upd_data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toast_success_text, {
            timeout: 2000,
          })

          this.products.response.data[productIndex].highest_price = upd_data.value
        }
      })
    },

    parseWebhooks() {
      const webhooks = JSON.parse(this.$store.state.settings.discord_webhooks)
      const webhook_object = []

      Object.entries(webhooks).forEach((item) => {
        webhook_object.push({ name: item[0], url: item[0] })
        //console.log(index, item)
      })

      this.webhooks = webhook_object
      console.log(webhook_object)
    },

    addToFavourite(product) {
      const upd_data = {
        table: 'shop_products',
        where: 'id',
        where_value: product.id,
        param: 'favourite',
        value: product.favourite == 0 ? 1 : 0,
      }

      const productIndex = this.products.response.data.findIndex((x) => x.id === product.id)

      const toas_success_text = upd_data.value == 0 ? 'Eltávolítva a kedvencek közül!' : 'Kedvencekhez adva!'

      this.updateGlobalParam(upd_data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[productIndex].favourite = upd_data.value
        }
      })
    },

    copyToPh(product) {
      product
      const text = '#akció \n \n [L:' + product.product_url + '][ [b]' + product.product_name + ' ][/b][/L]  \n \n [b]' + this.mFormat(product.product_discount_price) + '[/b]'

      this.copyText(text)
    },
    excludeProduct(product) {
      const upd_data = {
        table: 'shop_products',
        where: 'id',
        where_value: product.id,
        param: 'exclude',
        value: product.exclude == 0 ? 1 : 0,
      }

      const productIndex = this.products.response.data.findIndex((x) => x.id === product.id)

      const toas_success_text = upd_data.value == 0 ? 'Eltávolítva a kizártak közül!' : 'Termék kizárva!'

      this.updateGlobalParam(upd_data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[productIndex].exclude = upd_data.value
        }
      })
    },
    closeProductAlert() {
      this.productAlert = false
      this.productAlertData = {}
      this.alertFilterData = {}
    },
    setProductAlert(index) {
      this.productAlertData = this.products.response.data[index]
      this.productAlertData.index = index

      if (this.productAlertData.observe != '') {
        this.alertFilterData = JSON.parse(this.productAlertData.observe)
      } else {
        this.alertFilterData = {
          active: true,
          has_basket_button: false,
          has_basket_button_reverse: false,
          discount_price_lower_then_price: this.productAlertData.product_discount_price,
          discount_price_lower_then: false,
          discount_price_lower_then_reverse: false,
          observe_group: 'all',
          push: false,
          log: false,
        }
      }

      this.productAlert = true
    },

    setProductObserveGroup(group) {
      const data = {}

      data.table = 'shop_products'
      data.where = 'id'
      data.where_value = this.productAlertData.id
      data.param = 'observe_group'
      data.value = group

      const toas_success_text = 'A termék szűrő csoport beállítva!'

      this.updateGlobalParam(data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[this.productAlertData.index].observe_group = group
        }
      })
    },
    saveProductAlert() {
      const data = {}

      data.table = 'shop_products'
      data.where = 'id'
      data.where_value = this.productAlertData.id
      data.param = 'observe'
      data.value = JSON.stringify(this.alertFilterData)

      const toas_success_text = 'A termék szűrő beállítva!'

      this.updateGlobalParam(data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[this.productAlertData.index].observe = data.value
        }

        if (this.alertFilterData.observe_group != '') {
          this.setProductObserveGroup(this.alertFilterData.observe_group)
        }
      })
    },
    deleteProductAlert() {
      const data = {}

      data.table = 'shop_products'
      data.where = 'id'
      data.where_value = this.productAlertData.id
      data.param = 'observe'
      data.value = ''

      const toas_success_text = 'A szűrő törölve!'

      this.updateGlobalParam(data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[this.productAlertData.index].observe = data.value
        }
      })

      data.table = 'shop_products'
      data.where = 'id'
      data.where_value = this.productAlertData.id
      data.param = 'observe_group'
      data.value = ''

      this.updateGlobalParam(data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toas_success_text, {
            timeout: 2000,
          })

          this.products.response.data[this.productAlertData.index].observe = data.value
        }
      })
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    filterReset(type) {
      switch (type) {
        case 'max_date':
          this.$set(this.globalFilters, 'max_date', this.mysqlDateString())
          this.globalFilters.page = 1
          // this.getProductList()

        this.doFilters()

          break

        default:
          this.$delete(this.globalFilters, type)

          this.globalFilters.page = 1
      }
    },
    showProductFilterDialog() {
      this.$set(this.globalFilters, 'show_products', 0)
      this.showFilterDialog = true
    },
    getCategories() {
      this.loadingCategories = true
      this.categories = {}

      this.$http.get('products/getallunwatched2').then((result) => {
        if (result.data.error === true) {
          //
        } else {
          if (result.data.response) {
            this.categories = result.data
          }
        }

        this.loadingCategories = false
      })
    },

    setSeenClick(params) {
      this.$confirm({
        message: `Olvasottnak jelölöd?`,
        button: {
          no: 'Mégse',
          yes: 'Yap',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.setGlobalFilters(params)
            this.setSeenProductsWatched('all_filtered')
            this.showCategories()
            this.$set(this.globalFilters, 'seenclicked', 1)
          }
        },
      })
    },
    getProductChanges(id, item) {
      this.productChangesItem = item
      this.loadingProductChanges = true
      this.productChanges = {}

      this.$http.get('productchange?id=' + id + '').then((result) => {
        if (result.data.error === true) {
          //
        } else {
          if (result.data.response.length > 0) {
            this.productChanges = result.data
            this.showProductChangeDialog = true
          } else {
            this.$toast.warning('Nincs árváltozás', {
              timeout: 2000,
            })
          }
        }

        this.loadingProductChanges = false
      })
    },
    getProductLogTimeline(id, item) {
      this.productLogTimelineData = item
      this.loadingProductLogTimeline = true
      this.productLogTimeline = {}

      this.$http.get('productlogtimeline?id=' + id).then((result) => {
        if (result.data.error === true) {
          //
        } else {
          if (result.data.response.length > 0) {
            this.productLogTimeline = result.data
            this.showProductLogTimeline = true
          } else {
            this.$toast.warning('Nincs találat', {
              timeout: 2000,
            })
          }
        }

        this.loadingProductLogTimeline = false
      })
    },
    showBasketChanges(id, item){ 


      if(item.basket_changes != '' || item.entry_data != ''){

        this.productBasketChanges = item 
        this.showProductBasketChanges = true

      }else{

        this.productBasketChanges = false 
        this.showProductBasketChanges = false

        this.$toast.warning('Nincs kosár változás', {
              timeout: 2000,
            })



      }
    },
    setGlobalFilters(obj) {
      const maxdata = this.globalFilters.max_date

      this.globalFilters = {}
      this.$set(this.globalFilters, 'max_date', maxdata)

      this.globalFilters = { ...this.globalFilters, ...obj }

      this.$set(this.globalFilters, 'page', 1)

      if (Object.keys(obj).includes('show_products') && (obj.show_products == '1' || obj.show_products == 1)) {
      
        this.doFilters()
      
      }
    },

    showCategories() {
      if (this.seenProducts.length > 0) {
        //this.setSeenProductsWatched('ids')
        const answer = window.confirm('Biztosan visszalépsz? Van ' + this.seenProducts.length + 'db  megtekintett, de nem megjelölt termék!')
        if (answer) {
          //
        } else {
          return false
        }
      }

      this.getCategories()
      this.globalFilters = {
        max_date: this.mysqlDateString(),
        page: 1,
      }
      this.products = {}
    },
   async setSeenProductsWatched(type = false, show_new_product_list = false) {

      if (!type) {
        alert('Add meg az olvasottnak jelölés típusát')
        return false
      }

    this.$confirm({
            message: `Olvasottnak jelölöd?`,
            button: {
              no: 'Mégse',
              yes: 'Yap',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {
                
                
                var func = 'undefined'
      let queryString = new URLSearchParams(this.globalFilters).toString()

      switch (true) {
        case type === 'ids':
          func = 'set_ids_watched&ids=' + this.seenProducts.toString()
          break
        case type === 'all_filtered':
          func = 'all_filtered&' + queryString
          break
      }

      this.$http.get('products/search?function=' + func).then((result) => {
        if (result.data.error === true) {
          this.$toast.error(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(result.data.message, {
            timeout: 2000,
          })

          if (type === 'ids') {
            this.seenProducts = []
            if (show_new_product_list === true) {
              this.$set(this.globalFilters, 'page', 1)
              this.filterReset('max_date')
              this.$store.commit('getSettings')
              // this.showCategories()
              this.doFilters()
              
            }
          }

          if (type === 'all_filtered') {
            this.seenProducts = []
            this.products = {}
            //this.filterReset('max_date')

            if(Object.keys(this.globalFilters).includes('is_new')){
              this.getCategories()
              this.showCategories()
            }

            if(Object.keys(this.globalFilters).includes('watched') || Object.keys(this.globalFilters).includes('is_top_price')){
              this.filterReset('max_date')
              this.doFilters(true)
            }
           
            this.$store.commit('getSettings')
          }
        }
      })

         
              }
            },
          })


      

      
    },

    getFilteredProductsSiteCmsList(){


      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.$http.get('products/search?'+ queryString +'&function=get_all_site_cms').then((result) => {
        if (result.data.error === true) {
          this.$toast.error(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(result.data.message, {
            timeout: 2000,
          })

          this.filteredSiteCmsList = result.data
          this.showFilteredSiteCmsList = true

        }
      });
     

    },
    resetFilters() {
      this.filters = {}
      this.globalFilters = {}
    },
    doFilters(showsearchform = false) {
      this.showFilterDialog = false
      this.globalFilters.do_show_products = null

      this.globalFilters.show_products = null

      this.filters.max_date = this.mysqlDateString()
      this.filters.show_filter_dialog = null
      this.globalFilters.show_filter_dialog = null
      this.globalFilters.page = 1
      this.globalFilters.show_products = 1
      

      this.filters.show_products = 1
      this.globalFilters.site_prefix = this.globalFilters.site_prefix ? this.globalFilters.site_prefix.toString() : ''
      this.getProductList(showsearchform)
    },

    onIntersect(entries) {
      if (entries[0].intersectionRatio >= 1.0) {
        var product_index = entries[0].target.attributes.id.value

        if (this.products.response.data[product_index].watched == '0' || this.products.response.data[product_index].is_new == '1') {
          if (!this.seenProducts.includes(this.products.response.data[product_index].id)) {
            this.seenProducts.push(this.products.response.data[product_index].id)
            this.products.response.data[product_index].seen = true
          }
        }
      }
    },

    recycleProduct(product){

      const productIndex = this.products.response.data.findIndex((x) => x.id === product.id)

      var upd_data = {
        table: 'shop_products',
        where: 'id',
        where_value: product.id,
        param: 'lowest_price',
        value: product.product_previous_price,
      }

      var toast_success_text = 'A legalacsonyabb ár beállítva! (' + product.product_previous_price  + ')'

      this.updateGlobalParam(upd_data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toast_success_text, {
            timeout: 2000,
          })

          this.products.response.data[productIndex].lowest_price = upd_data.value
        }
      })


      var upd_data = {
        table: 'shop_products',
        where: 'id',
        where_value: product.id,
        param: 'product_discount_price',
        value: product.product_previous_price,
      }

      var toast_success_text_2 = 'A termék ár beállítva! (' + product.product_previous_price  + ')'

      this.updateGlobalParam(upd_data).then((result) => {
        if (result.data.error) {
          this.$toast.warning(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(toast_success_text_2, {
            timeout: 2000,
          })

          this.products.response.data[productIndex].product_discount_price = upd_data.value
        }
      })


    },
    generateCliCommand(observe_group = false) {
      var cli_command = 'Api Task ProductPageCheck start'
      var observe_group_q_string = observe_group ? 'observe_group=' + observe_group : ''

      var command = cli_command + " '" + (observe_group_q_string !== '' ? observe_group_q_string : '') + "'" // eslint-disable-line

      this.copyText(command)
    },
    productPriorityClass(priority) {
      var priority_class = ''

      switch (true) {
        case priority == '0':
          priority_class = 'green lighten-2 white--text'
          break

        case priority == '1':
          priority_class = 'orange lighten-2 white--text'
          break

        case priority == '2':
          priority_class = 'red lighten-2 white--text'
          break
        default:
          priority_class = 'grey lighten-3 grey--text text--darken-2'
          break
      }

      return priority_class
    },
    getProductList(showsearchform = false) {
      this.loadingProducts = true
      this.showFilterDialog = false
      this.products = []
      this.toTop(0)

      let queryString = new URLSearchParams(this.globalFilters).toString()
      

      this.$http.get('products/search?' + queryString + '').then((result) => {
        this.loadingProducts = false
        this.showFilterDialog = false
        if (result.data.error === true) {
          this.$toast.error(result.data.message, {
            timeout: 4000,
          })
          this.loadingProducts = false
          this.globalFilters.show_products = null
           this.globalFilters.show_filter_dialog = 1
      
           this.products = {}
          if(showsearchform === true){
            this.showFilterDialog = true
          }
           //this.doFilters()
        } else {
          this.products = result.data

          if (result.data.response.count === 0) {
            this.$toast.warning('Nincs találat a keresésre', {
              timeout: 2000,
            })

            this.products = {}
            this.categories = {}
            this.showCategories()
            this.loadingProducts = false
            this.globalFilters.show_products = null
            if(showsearchform === true){
              this.globalFilters.show_filter_dialog = 1
            }
            this.$store.commit('getSettings')
           // this.showFilterDialog = true

            //this.showCategories()
          }
        }

        
        this.loadingProducts = false
        //this.showFilterDialog = false
        
      })
    },
  },
  created() {},
  mounted() {
    if (this.default_filters) {
      this.globalFilters = { ...this.globalFilters, ...this.default_filters }
    }
    this.getCategories()
    this.getAllShopUrlUniqueData()
  },
  watch: {
    globalFilters: {
      deep: true,
      handler(newValue, oldValue) {



        if(this.seenProducts.length > 0 && (typeof oldValue.product_shopurl_priority !== 'undefined' || oldValue.product_shopurl_priority != newValue.product_shopurl_priority)){

          this.setSeenProductsWatched('ids')

        }

        if(this.seenProducts.length > 0 && ( typeof oldValue.site_cms !== 'undefined' || oldValue.site_cms != newValue.site_cms)){

          this.setSeenProductsWatched('ids')

        }

        if(this.seenProducts.length > 0 && ( typeof oldValue.category !== 'undefined' || oldValue.category != newValue.category)){

          this.setSeenProductsWatched('ids')

        }
         
         if(this.seenProducts.length > 0 && ( typeof oldValue.description !== 'undefined' || oldValue.description != newValue.description)){

          this.setSeenProductsWatched('ids')

        }

        
        if (Object.keys(this.globalFilters).includes('show_filter_dialog') && (this.globalFilters.show_filter_dialog == '1' || this.globalFilters.show_filter_dialog == 1)) {
          this.showFilterDialog = true
          //this.loadingProducts = false
          this.globalFilters.show_products = null
          this.globalFilters.show_filter_dialog = 1
        }
        if (Object.keys(this.globalFilters).includes('show_products') && ( this.globalFilters.show_products == '1' ||  this.globalFilters.show_products == 1) ) {
          this.showFilterDialog = false
          this.globalFilters.show_filter_dialog = null
          //this.loadingProducts = false
          this.globalFilters.show_products = 1
          //this.getProductList()
          //this.doFilters()


          if (Object.keys(this.globalFilters).includes('do_show_products') && ( this.globalFilters.do_show_products == '1' ||  this.globalFilters.do_show_products == 1) ) {
          
            this.doFilters()
          
          }

          if (Object.keys(this.globalFilters).includes('page') ) {
          
            this.pagination = this.globalFilters.page
          
          }

          if (Object.keys(this.globalFilters).includes('id') ) {
          
            this.productId = this.globalFilters.id
          
          }
        }

      

      },
    },
    priceFilter: {
      deep: true,
      handler() {

       // console.log(this.priceFilter)
        
          Object.entries(this.priceFilter).forEach(entry => {

          const [key, value] = entry;
          
            if(value != 0){
              
              
            // this.setGlobalFilters({ [key] : (value * 1000) })

             this.$set(this.globalFilters, key, (value * 1000))

             console.log(key, value)


            }else{


             this.$delete(this.globalFilters, key);


            }


            
          });
        
      },
    },
    pagination: {
      deep: true,
      handler() {


       // console.log(this.priceFilter)
       
        
          this.$set(this.globalFilters, 'page', this.pagination)
          this.getProductList()
      },
    },

    productImageDialog: {
      deep: true,
      handler() {


       // console.log(this.priceFilter)
       if(this.productImageDialog == false){

          this.productImageDialogSrc = ""

       }
        
          
          
      },
    },
    productId: {
      deep: true,
      handler() {


       // console.log(this.priceFilter)
       
        
          this.$set(this.globalFilters, 'product_id', this.productId)
          this.getProductList()
      },
    },
    productChanges: {
      handler() {
        if (Object.keys(this.productChanges).includes('count') && this.productChanges.count > 0) {


          const labels = [];
          const prices = [];
          var i = 0;
          var countchanges = this.productChanges.response.length
          this.productChanges.response.forEach((element) => {

            i++
            

            labels.push(element.date)
            labels.push(element.date)

            
            prices.push(element.new_data)
            prices.push(element.old_data)

            if(i == countchanges){

            labels.push(this.productChangesItem.entry_date + ' - Bekerült')
            prices.push(this.productChangesItem.highest_price)


            }
            

          })

          //const obj = this.productChanges.response
          const builded_dataset = {
            labels: labels, //Object.keys(obj).map((key) => obj[key].date),
            datasets: [
              {
                label: 'Árváltozások',
                data: prices, //Object.keys(obj).map((key) => obj[key].new_data),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                pointBackgroundColor: '#99cc00',
                pointRadius: 10,
                pointHoverRadius: 15,
                stepped: true,
              },
              
            ],
          }

          this.productChangeDataset = builded_dataset
          /*var old_prices = Object.keys(obj).map((key) => obj[key].old_data)
          old_prices = Object.values(old_prices)
          this.productChangeDataset.datasets[0].data.push(old_prices) 
          console.log(this.productChangeDataset)
          */
        }
      },
    },
  },
}
</script>
<style scoped>
.card-outter {
  padding-bottom: 50px;

}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
