<template>
  <div >
    
    <div v-if="Object.keys(sessions).length > 0">
      <v-progress-linear v-if="loadingSessions"></v-progress-linear>
      <v-card  class="my-2 pa-1 mx-1" v-for="(item, index) in sessions.response.data" :key="index" :class="item.session_end != '' ? 'grey lighten-5' : 'green lighten-5'">
        <v-img class="mx-2 " v-if="$store.state.settings['' + item.site_cms + '_pushover_icon']" :src="$store.state.settings['' + item.site_cms + '_pushover_icon']" max-height="30px" max-width="30"  />
            
        <v-card-title class="pa-0 text-caption">
          <v-spacer></v-spacer>
          [ <v-btn x-small text @click="seturlid(item.url_id)"> {{ item.url_id }} </v-btn> ] - <b>{{ item.url_data }}</b>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title class="pa-0 text-caption">
          <v-spacer></v-spacer>
          <span v-if="item.session_end != ''">[ <MomentAgo :date="item.session_start"></MomentAgo> ] - [ <MomentAgo :date="item.session_end"></MomentAgo>]</span>
          <span v-else class="green--text font-weight-bold">folyamatban... [ <MomentAgo :date="item.session_start"></MomentAgo> ]</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title class="pa-0 text-caption"
          ><v-spacer></v-spacer>
          <v-btn x-small @click="showLastSessionData(item)"><span class="red--text">last_session</span>#{{ item.session_id }}</v-btn>
          <v-divider color="grey" vertical class="mx-1"></v-divider>
           <v-btn x-small :to="'/log?session_id=' + item.session_id + '&filterLogCategories=true'" target="_blank"> <span class="red--text">log</span>@{{ item.session_id }}</v-btn>
          <v-spacer></v-spacer
        ></v-card-title>

        <v-card-text class="">
          <v-row style="font-size: 12px">
            <v-row>
              <v-col cols="6" class="pa-3 pr-0 ma-0 pr-1">
                <v-col cols="12" class="pa-0 text-center grey lighten-4 font-weight-bold">FOUND PAGES</v-col>
                <v-col cols="12" class="pa-0 d-flex flex-column justify-center align-center text-center grey lighten-5">
                  {{ item.found_pages }}
                </v-col>
              </v-col>
              <v-col cols="6" class="pa-3 pr-0 pr-1 px-0">
                <v-col cols="12" class="pa-0 text-center grey lighten-4 font-weight-bold">FOUND PRODUCTS</v-col>
                <v-col cols="12" class="pa-0 d-flex flex-column justify-center align-center text-center grey lighten-5">
                  {{ item.found_products }}
                </v-col>
              </v-col>
            </v-row>
          </v-row>

          <v-row v-if="item.no_product_count > 0 || item.connect_error_count > 0 || item.no_product_on_first_page_count > 0">
            <v-row>
              <v-col cols="4" md="4" class="pa-3 pr-0 ma-0 pr-1">
                <v-col cols="12" :class="item.no_product_count > 0 ? 'blue lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Nem található termék hiba: {{ item.no_product_count }}</v-col>
              </v-col>
              <v-col cols="4" md="4" class="pa-3 pr-0 pr-1 px-0">
                <v-col cols="12" :class="item.connect_error_count > 0 ? 'red lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Kapcsolódási hiba: {{ item.connect_error_count }}</v-col>
              </v-col>

              <v-col cols="4" md="4" class="pa-3 pr-3 px-0">
                <v-col cols="12" :class="item.no_product_on_first_page_count > 0 ? 'purple lighten-3 white--text' : 'grey lighten-2'" class="pa-0 text-center">Nincs termék az első oldalon: {{ item.no_product_on_first_page_count }}</v-col>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="showlastsessiondata" max-width="590">
        <v-card color="white">
          <v-card-title class="pa-1 grey white--text text-caption"
            >[ {{ last_session_data.url_id }} ] - <b>{{ last_session_data.url_data }}</b></v-card-title
          >
          <v-card-text class="pa-2" v-html="last_session_data.last_session_data"> </v-card-text>
          <v-card-actions class="grey"><v-spacer></v-spacer><v-btn @click="showlastsessiondata = false" class="red lighten-5" x-small text>Bezárás</v-btn></v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showfilterdialog" max-width="590">
        <v-card color="white">
          <v-card-title class="pa-1 grey white--text text-caption">Szűrés</v-card-title>
          <v-card-text class="pa-2 ">
              <v-spacer></v-spacer>
            <v-switch class="d-inline" v-model="globalFilters.in_progress" label="Csak folyamatban lévőket" true-value="true" false-value="" hide-details></v-switch>
            <v-switch class="d-inline" v-model="globalFilters.only_errors" label="Csak ahol hiba van" true-value="true" false-value="" hide-details></v-switch>
           
            <v-spacer>  </v-spacer>
          </v-card-text>

          <v-card-actions class="grey"><v-spacer></v-spacer><v-btn @click="showfilterdialog = false" class="red lighten-5" x-small text>Bezárás</v-btn></v-card-actions>
        </v-card>
      </v-dialog>
      <v-footer fixed class="text-center pa-0" :style="this.$vuetify.breakpoint.mobile ? 'margin-bottom: 48px;' : 'margin-bottom: 0px'">
      
        <v-row >
    
          <v-col cols="12">
            <v-pagination  class=" grey lighten-3 text-caption"  v-if="Object.keys(sessions).length > 0" v-model="globalFilters.page" :length="sessions.response.all_pages" :total-visible="8"></v-pagination>
    
          </v-col>
                <v-col cols="12" class="d-flex pa-0 grey lighten-5">
            <v-spacer></v-spacer>
        <v-chip-group column>
          <v-chip x-small v-for="item in sessions.params" :key="item.type" @click="filterReset(item.filter)">
            <b>{{ item.type }}</b
            >: {{ item.value }}
          </v-chip>
        </v-chip-group>
       <v-spacer></v-spacer>
      </v-col>
          <v-col cols="12">
        <v-spacer></v-spacer>
        <v-btn class="mx-2" elevation="0" :class="realtimeData ? 'purple darken-2 white--text' : ''" @click="realtimeData ? (realtimeData = false) : (realtimeData = true)">{{ realtimeCountDown !== false ? 'Realtime: ' + realtimeCountDown + '' : 'Realtime?' }}</v-btn>
        <v-btn class="mx-2 green white--text" elevation="0" @click="getSessions()">Frissítés</v-btn>
        <v-btn class="mx-2" elevation="0" :class="showfilterdialog ? 'orange darken-2 white--text' : ''" @click="showfilterdialog = true">Szűrés</v-btn>
        <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-footer>
    </div>
    <v-skeleton-loader v-else type="article"></v-skeleton-loader>
  </div>
</template>


<script>

import global_mixin from '../mixins/global_mixin'
export default {
  
 mixins: [global_mixin],
  data() {
    return {
      loadingSessions: false,
      showlastsessiondata: false,
      last_session_data: false,
      realtimeData: false,
      realtimeCountDown: false,
      updateIntervalSecond: 5,
      showfilterdialog: false,
      sessions: {},
      globalFilters: {
        page: 1,
      },
    }
  },

  methods: {
    filterReset(type) {
      switch (type) {
        default:
          this.$delete(this.globalFilters, type)
      }
    },
    showLastSessionData(data) {
      this.last_session_data = data
      this.showlastsessiondata = true
    },
    seturlid(id) {
      this.$set(this.globalFilters, 'url_id', id)
    },
    getSessions() {
      this.loadingSessions = true
      //this.sessions = {}

      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.axios.get('shopurlsessions/search?' + queryString + '').then((result) => {
        this.sessions = result.data

        this.loadingSessions = false
        this.toTop()
      })
    },
  },
  mounted() {
    //this.getSessions()
   
  },
  watch: {
    realtimeData: {
      handler() {
        if (this.realtimeData) {
          this.realtimeCountDown = this.updateIntervalSecond

          this.updateIntervalCountdown = setInterval(() => {
            this.realtimeCountDown--
          }, 1000)
        } else {
          clearInterval(this.updateIntervalCountdown)
          this.realtimeCountDown = false
        }

        if (this.realtimeData) {
          this.updateInterval = setInterval(() => {
            this.getSessions()
            this.realtimeCountDown = this.updateIntervalSecond
          }, this.updateIntervalSecond * 1000)
        } else {
          clearInterval(this.updateInterval)
          this.realtimeCountDown = false
        }
      },
    },

    globalFilters: {
      deep: true,
      handler() {
        this.setRouterFromQueryFilterParams()
        this.getSessions()
      },
    },
  },
}
</script>
