<template>
  <div>
    <v-dialog v-model="startSetLogWatched" class="pa-0 ma-0" max-width="400px">
      <v-card :class="set_log_watched_class" dark class="pa-3">
        <v-card-text>
          {{ set_log_watched_loading_text }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog @close="closeLogDialog()" @keydown.esc="closeLogDialog()" scrollable fullscreen v-model="showLogDialog" max-width="100%">
      <template>
        <v-card v-if="loadingLogs">
          <v-card-title class="grey white--text">Töltés folyamatban</v-card-title>
          <v-card-text class="text-h4 text-center pa-3">
            <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
          </v-card-text>
          <v-card-actions class="d-flex pa-0 mb-1 grey lighten-3 white--text pa-2"> <v-spacer></v-spacer><v-btn small outlined class="grey lighten-2 elevation-0" @click="closeLogDialog()">Bezárás</v-btn> </v-card-actions>
        </v-card>
        <v-card v-else-if="Object.keys(logItems).includes('error') && logItems.error == true">
          <v-card-title class="grey white--text">HIBA TÖRTÉNT</v-card-title>
          <v-card-text class="text-h4 text-center pa-3"> -- {{ logItems.message }} -- </v-card-text>
          <v-card-actions class="d-flex pa-0 mb-1 grey lighten-3 white--text pa-2"> <v-spacer></v-spacer><v-btn small outlined class="grey lighten-2 elevation-0" @click="closeLogDialog()">Bezárás</v-btn> </v-card-actions>
        </v-card>
        <v-card v-else-if="Object.keys(logItems).includes('response')" :loading="loadingLogs">
          <v-card-title class="grey lighten-2 pa-0 text--caption">
            <v-spacer></v-spacer>
            LOG
            <span v-if="Object.keys(globalFilters).includes('log_category') && globalFilters.log_category != ''"> - {{ globalFilters.log_category }}</span>
            <span v-if="Object.keys(globalFilters).includes('log_type') && globalFilters.log_type != ''"> - {{ globalFilters.log_type }}</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          
          <v-card-title class="pa-0 text-caption">
            <v-spacer></v-spacer>
            {{ logItems.response.count }} találat | Összes oldal: {{ logItems.response.all_pages }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text  class="pa-0 " v-if="logItems.response.count > 0">
            <v-card  outlined v-for="log_item in logItems.response.data" class="ma-0 my-1" elevation="0" :key="log_item.id">
              <v-divider></v-divider>
              <v-card-title class="text-caption grey lighten-3 pa-1">
                <v-col cols="12" sm="6" class="text-center text-sm-right ma-0 pa-0"
                  ><b class="mr-1">{{ log_item.log_category }}</b> - <span class="ml-1 orange--text">{{ log_item.log_type }}</span></v-col
                >
                <v-col cols="12" sm="6" class="text-center text-sm-left ma-0 pa-0 pl-2">{{ log_item.log_start_date }} </v-col>
              </v-card-title>

              <template>
                <v-card-text v-if="log_item.log_msg != ''" class="text-center d-block" v-html="log_item.log_msg"></v-card-text>
                <v-card-text v-else class="text-center yellow lighten-5 d-block"> -- </v-card-text>
              </template>
              <v-card-actions class="grey lighten-5 pa-0 d-block text-center">
                <v-btn outlined class="grey lighten-3 ma-1 elevation-0" x-small v-if="log_item.log_curl_id != ''" :href="'https://bb.supreme7.eu/log_files/' + log_item.log_curl_id"  target="_blank">CURL</v-btn>
                <v-btn outlined class="grey lighten-3 ma-1 elevation-0" x-small v-if="log_item.log_product_id != ''" >PRODUCT</v-btn>
                <v-btn outlined class="grey lighten-3 ma-1 elevation-0" x-small v-if="log_item.log_shop_url_id != ''" :href="'https://vue.supreme7.eu/shopurls?page=1&show_url_list=1&active=1&shop_url_id=' + log_item.log_shop_url_id "  target="_blank">SHOPURL</v-btn>
                <v-btn outlined class="grey lighten-3 ma-1 elevation-0" x-small v-if="log_item.log_parser_id != ''" :href="'https://bb.supreme7.eu/log_files/' + log_item.log_parser_id" target="_blank">PARSER</v-btn>
                <v-btn target="_blank" :href="'https://vue.supreme7.eu/log?id='+log_item.log_task_id +'&filterLogCategories=true'"  outlined class="grey lighten-3 ma-1 elevation-0" x-small v-if="log_item.log_task_id != ''">TASK</v-btn>
              </v-card-actions>
            </v-card>
           
          </v-card-text>
          <v-card-text v-else class="text-center" >
             <v-alert outlined color="purple">
              <div class="text-h6">-- Nincs találat --</div>
              <div>Nincs találat a beállított szűrésekre</div>
              <v-chip-group column class="mx-auto d-flex justify-center align-center flex-column">
              <v-chip x-small v-for="item in logItems.params" :key="item.type" @click="filterReset(item.filter, false)">
                <b>{{ item.type }}</b
                >: {{ item.value }}
              </v-chip>
            </v-chip-group>
            </v-alert>
          </v-card-text>

          <v-card-actions class="d-block pa-0 mb-1">
            <v-divider></v-divider>
            <v-row class="ma-0 mb-3 mr-2 pa-2 grey-lighten-3">
              <v-spacer></v-spacer>
            <v-chip-group column>
              <v-chip x-small v-for="item in logItems.params" :key="item.type" @click="filterReset(item.filter, true)">
                <b>{{ item.type }}</b
                >: {{ item.value }}
              </v-chip>
            </v-chip-group>
            <v-spacer></v-spacer>
            </v-row>
             <v-divider></v-divider>
            <v-row class="ma-0 grey lighten-4 pa-0">
              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-pagination total-visible="7" class="text--caption pa-1" v-model="paginationpage" :length="logItems.response.all_pages"></v-pagination>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            
            <v-row class="ma-0 mb-3 mr-2 grey-lighten-3   ">
              <v-col cols="12 d-flex align-center flex-row justify-center">
                <v-btn small class="purple lighten-1 white--text mr-1 outline elevation-0" @click="setLogsWatched('actual_page')"><i class="fas fa-share-square mr-1"></i> {{JSON.parse(logItems.response.pids).length}}</v-btn>
                <v-btn small class="green lighten-1 white--text mr-1 outline elevation-0" @click="setLogsWatched('filtered')"><i class="fas fa-check-double mr-1"></i> Összes</v-btn>
                <v-btn small class="orange lighten-2 mr-1 outline elevation-0 white--text" :loading="loadingLogs" @click="filterReset('log_max_date', true)"><i class="fas fa-history"></i></v-btn>
                <v-btn small class="grey lighten-2 outline elevation-0" @click="closeLogDialog()"><i class="fas fa-window-close mr-1"></i> Bezárás</v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <div v-if="!loadingCategories" style="margin-bottom: 100px;">
      <v-row v-if="Object.keys(globalFilters).length > 0">
        <v-col cols="12" class="my-2">
          <span style="font-size: 10px; cursor: pointer" class="pa-1 mx-1 text-center d-inline-block grey lighten-2" v-for="(item, index) in globalFilters" :key="index" @click="filterReset(index)">
            <b>{{ index }}</b
            >: {{ item }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" class="text-center text-md-left">
          <v-switch class="mr-3" style="display: inline-block" v-model="globalFilters.filterLogCategories" true-value="true" @click="switchFilterLogCategories()" label="Kategóriák szűrése?"></v-switch>

          <v-switch class="mr-3" style="display: inline-block" v-model="globalFilters.log_unwatched" true-value="true" @click="switchShowUnwatched()" label="Olvasottakat is mutassa?"></v-switch>
        </v-col>
       
      </v-row>
     <v-card elevation="1" class="grey darken-2 white--text text-center pa-1 mt-2"  @click="setCategoryAndTypeFilter('', '', true)" >
          <v-card-subtitle class="white--text"><i v-if="!globalFilters.log_category && !globalFilters.log_type" class="fas fa-arrow-circle-right"></i> Összes</v-card-subtitle>
     </v-card>
     
        <v-card elevation="0" class="row pa-0 my-4 white" v-for="(item, index) in logCategories.data" :key="index">
           <v-card-subtitle style="cursor: pointer" class="col-12 text-caption text-center " :class="logCategoryClass(index)" @click="setCategoryAndTypeFilter(index, '', true)" @dblclick="showLogs()" ><i v-if="globalFilters.log_category && globalFilters.log_category == index && (!globalFilters.log_type || globalFilters.log_type == '')" class="fas fa-arrow-circle-right mr-1"></i> {{ index }}</v-card-subtitle>
          

         <v-card class="text-center col ma-1 px-0  py-0 pb-1" elevation="1" min-width="300" v-for="(type, i) in item" :key="i"  @click="setCategoryAndTypeFilter(index, type.log_type, true)" :class="logTypeClass(type.log_type, item.category)" >
          <v-card-title class="text-caption pa-0 mb-3 mt-1" style="font-size: 0.6rem !important">
          <v-spacer></v-spacer>
          <i v-if="globalFilters.log_type && globalFilters.log_type == type.log_type && globalFilters.log_category && globalFilters.log_category == index" class="fas fa-arrow-circle-right mr-1"></i> <b>{{ type.log_type }}</b> 
          <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle class="text-caption pa-0">
          <MomentAgo  :date="type.last_log_date"></MomentAgo> 
          </v-card-subtitle>
          <v-card-text class="text-caption pa-0">
           <span class="px-3 orange darken-2 white--text">{{ type.unwatched }}</span>
           <span v-if="globalFilters.log_unwatched" class="ml-1 px-3 grey darken-2 white--text">{{ type.watched }}</span>
          </v-card-text>
         </v-card>
        </v-card>

      <v-footer fixed class="text-center" :style="this.$vuetify.breakpoint.mobile ? 'margin-bottom: 48px;' : 'margin-bottom: 0px'">
        <v-spacer></v-spacer>
          <v-btn small class="green lighten-1 white--text mr-1 outline elevation-0" @click="setLogsWatched('all')"><i class="fas fa-check-double mr-1"></i> Összes</v-btn>
          <v-btn small class=" green lighten-1 mx-1 white--text" @click="showLogs()"><i class="fas fa-eye"></i></v-btn>
          <v-btn small class="purple lighten-2 mx-1 white--text" :loading="loadingCategories" @click="filterReset('log_max_date', false)"><i class="fas fa-sync-alt"></i></v-btn>
         <v-spacer></v-spacer>
      </v-footer>
    </div>
    <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
  </div>
</template>

<script>
import global_mixin from '../mixins/global_mixin'

export default {
   mixins: [global_mixin],
  data() {
    return {
      logPage: 1,
      logCategories: {},
      loadingCategories: false,
      loadingLogs: false,
      logItems: {},
      showLogDialog: false,
      getLogsShow: false,
      startSetLogWatched: false,
      set_log_watched_loading_text: '',
      set_log_watched_class: '',
      paginationpage: 1
      
    }
  },

  methods: {
    
    filterReset(type, showlogs = false) {

      

      switch (type) {
        case 'log_max_date':
          
          //currentfilters.log_max_date = this.mysqlDateString()
          
          this.globalFilters = { ...this.globalFilters, ...this.getQueryParams() }
          this.globalFilters.log_max_date = this.mysqlDateString()
          this.getLogCategories()
          if(showlogs === true){
          this.getLogsShow = true
          //this.logPage = 0
          //this.setlogPage(1, true)
          this.getLogs()
          }
          
          break

        default:
          this.$delete(this.globalFilters, type)
          if (this.globalFilters.filterLogCategories) {
            this.getLogCategories()
          }
      }

      if(showlogs === true){
        
           // this.showLogs()
      }
    },

    switchShowUnwatched() {
      if (this.globalFilters.log_unwatched == true) {
        this.globalFilters.log_unwatched = 'true'
        // this.globalFilters.filterLogCategories = 'true'
        this.getLogCategories()
      } else {
        this.$delete(this.globalFilters, 'log_unwatched')
        this.getLogCategories()
      }
    },
    switchFilterLogCategories() {
      if (this.globalFilters.filterLogCategories == true) {
        this.globalFilters.filterLogCategories = 'true'
        this.getLogCategories()
      } else {
        this.$delete(this.globalFilters, 'filterLogCategories')
        this.getLogCategories()
      }
    },
    closeLogDialog() {
      this.showLogDialog = false
      this.$delete(this.globalFilters, 'show_log_dialog')
      this.setlogPage(1, false)
      this.getLogsShow = false

    },

    setlogPage(page, showlog = false){

      this.logPage = page

      if(showlog === true){

        this.showLogs()

      }


    },

    setCategoryAndTypeFilter(category, type, show = false) {
      if (category !== '') {
        this.$set(this.globalFilters, 'log_category', category)
      } else {
        this.$delete(this.globalFilters, 'log_category')
      }
      if (type !== '') {
        this.$set(this.globalFilters, 'log_type', type)
      } else {
        this.$delete(this.globalFilters, 'log_type')
      }

      if (this.globalFilters.filterLogCategories) {
        this.getLogCategories()
      }

      //this.globalFilters.log_max_date = 
      if(show === true){
        

        this.getLogs(true)
      }
    },

    showLogs() {
      //this.logPage = 1
      this.getLogs()
    },

    setLogsWatched(set) {
      this.set_log_watched_loading_text = 'Kérlek várj ... az olvasottnak jelölés folyamatban'
      this.set_log_watched_class = 'blue lighten-1'
      this.startSetLogWatched = true

      var queryString = ''
      var show_logs_again = false

      if (set == 'filtered') {
        queryString = new URLSearchParams(this.globalFilters).toString() + '&function=set_log_watched'
        this.set_log_watched_loading_text = this.set_log_watched_loading_text + ' (Szűrés szerint - Beállított dátumig)'
      }else if (set == 'actual_page') {
        if(this.logItems.response.all_pages > this.logItems.response.actual_page ){

          show_logs_again = true

        }
        queryString = '&function=set_log_watched&pids=' + JSON.parse(this.logItems.response.pids).toString()
        this.set_log_watched_loading_text = this.set_log_watched_loading_text + ' (Aktuális oldal)'

      } else if (set == 'all') {
        queryString = 'function=set_log_watched&log_max_date=' + this.globalFilters.log_max_date + ''
        this.set_log_watched_loading_text = this.set_log_watched_loading_text + ' (Összes - Beállított dátumig)'
      } 
      
      this.axios.get('log/search?' + queryString).then((result) => {
        if (result.data.error) {
          this.set_log_watched_loading_text = 'Hiba történt a log olvasottnak jelölése közben!'
          this.set_log_watched_class = 'red lighten-1'
        } else {
          this.set_log_watched_loading_text = 'Úgy tűnik Sikerült az olvasottnak jelölés ->  ' + result.data.message + ' -> Az ablak hamarosan beázródik ... '
          this.set_log_watched_class = 'green lighten-1'

          

        if(show_logs_again){

          this.showLogs()

        }else{
          this.showLogDialog = false
          this.logItems = {}
          this.globalFilters.log_max_date = this.mysqlDateString()
          this.getLogCategories()
          }
        }

        setTimeout(() => {
          this.startSetLogWatched = false
          this.$store.commit('getNotifications')
          
        }, 1000)

        
      })

      //this.logItems = {}
    },
    getLogs(show = false) {
      this.loadingLogs = true
      this.showLogDialog = true
      this.logItems = {}
      

      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.axios.get('log/search?' + queryString + '&page=' + this.logPage).then((result) => {
        this.logItems = result.data
        
        //console.log()
        if(show === true){

          this.getLogsShow = true

        }

        this.loadingLogs = false
      })
    },
    
    getLogCategories() {
      this.loadingCategories = true
      this.logCategories = {}

      let queryString

      if (this.globalFilters.filterLogCategories) {
        queryString = new URLSearchParams(this.globalFilters).toString()
      } else {
        queryString = this.globalFilters.log_unwatched ? '&log_unwatched=true' : ''
      }

      this.axios.get('log/categories2?' + queryString).then((result) => {
        if (Object.prototype.hasOwnProperty.call(result.data, 'response')) {
          this.logCategories = result.data.response
        } else {
          this.logCategories = {}
        }

        this.loadingCategories = false
      })
    },
    logCategoryClass(category, lighten = 'lighten-4') {
      switch (category) {
        case 'CURL':
          return 'orange ' + lighten
       
        case 'SYSTEM INFO':
          return 'blue ' + lighten
        case 'CI4 EXCEPTION':
          return 'red ' + lighten
        case 'TASK':
          return 'purple ' + lighten
        case 'Pushover':
          return 'yellow ' + lighten
        case 'Termék változás':
          return 'blue ' + lighten
        default:
          return 'grey ' + "lighten-2"
      }
    },

    logTypeClass(type, category, lighten = 'lighten-5') {
      switch (type) {
        
        case 'ShopUrl Task elindítva':
          return 'green ' + lighten
         case 'SHOPURL PARSER ERROR':
          return 'red ' + lighten
      case 'SHOPURL PARSER -Nincs találat':
      return 'orange ' + lighten
      case 'URL ELTÉRÉS':
      return 'purple ' + lighten
      
        case 'ShopUrl Task folyamatban':
          return 'orange ' + lighten
        case 'ShopUrl Task befejezve':
          return 'purple ' + lighten
        case 'SESSION CRITICAL PARSER ERROR BREAK':
          return 'red ' + lighten
          case 'CURL Hiba':
          return 'red ' + lighten
          case 'SESSION CONNECT ERROR TRY CONTINUE':
          return 'orange ' + lighten
           case 'SESSION CONNECT ERROR TRY BREAK':
          return 'orange ' + lighten

          

        default:
          return 'grey lighten-5'
      }

      
    },
    
  },
  mounted() {
    this.getLogCategories()
    
    
  },
  created() {


  },

  watch: {
    globalFilters: {
      deep: true,
      handler(newd) {


        
        if (!Object.keys(this.globalFilters).includes('log_max_date')) {
          
         this.$set(this.globalFilters, 'log_max_date', this.mysqlDateString())
        }

        if (Object.keys(this.globalFilters).includes('log_type') && this.globalFilters.log_type == '') {
          
          alert('log type kivéve')
          this.$delete(this.globalFilters, 'log_type')

        }

        if (Object.keys(newd).includes('show_log_dialog')) {
          
          //this.getLogs()  
        }

         if (Object.keys(this.globalFilters).includes('show_log_dialog')) {
          
           this.showLogs()
           this.$delete(this.globalFilters, 'show_log_dialog')
            //this.getLogs()
           
          
          }

       //this.logPage = 1
      },
    },
    

   
    paginationpage: {
      deep: true,
      handler(newd) {
        
          
          this.setlogPage(newd, true)
          
      },
    },
    showLogDialog: {
      deep: true,
      handler() {
        if (this.showLogDialog === true) {
         // this.getLogs()
          
        }
      },
    },
    $route (){
     

      if (Object.keys(this.getQueryParams()).includes('show_log_dialog')) {
          
         this.globalFilters = { ...this.globalFilters, ...this.getQueryParams() }
         //this.globalFilters.show_log_dialog = 
         
          //this.showLogs()  
        }

    }
  },
}
</script>
<style>
/deep/.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 20px !important;
}
/deep/ .v-pagination__item,
.v-pagination__navigation {
  font-size: 12px !important;
  height: 24px !important;
  min-width: 24px !important;
  line-height: 24px !important;
}

.wrap-cols {
  display: flex !important;
  flex-grow: 1 !important;
  max-width: 100% !important;
}
.v-dialog--scrollable > .v-card > .v-card__text, .v-dialog--scrollable > form > .v-card > .v-card__text{
  -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

</style>
