<template>
  <v-dialog max-width="600" v-model="this.$parent.showAddEditDialog" @click:outside="$emit('emitFunc', { function: 'closeSettingsAddEditDialog' })" persistent scrollable>
    <v-card>
      <v-card-title v-if="functionChoose == 'add'" class="text-h5 green lighten-3"> Beállítás hozzáadása </v-card-title>
      <v-card-title v-else-if="functionChoose == 'edit'" class="text-h5 deep-orange lighten-2 white--text mb-3"> Beállítás Szerkesztése </v-card-title>
      <v-card-text class="py-3">
        <v-form class="px-4" @submit.prevent="">
          <!-- SETTINGS TITLE -->
          <label class="font-weight-bold" for="settings_title">Settings Title:</label>
          <v-text-field outlined id="settings_title" v-model="editSettingsData.settings_title" :value="editSettingsData.settings_title"></v-text-field>

          <!-- SETTINGS CATEGORY TITLE 
        <v-text-field outlined id="settings_category_title" v-model="editSettingsData.settings_category_title" :value="editSettingsData.settings_category_title"></v-text-field>
-->
          <label class="font-weight-bold" for="settings_category_title">Settings Category Title:</label>

          <v-combobox v-model="editSettingsData.settings_category_title" :items="settingsCategories" solo outlined dense></v-combobox>

          <!-- SETTINGS PARAM -->
          <label class="font-weight-bold" for="settings_param">Settings Param:</label>
          <v-text-field outlined id="settings_param" v-model="editSettingsData.settings_param" :value="editSettingsData.settings_param"></v-text-field>

          <!-- SETTINGS VALUE -->
          <label class="font-weight-bold" for="settings_value">Settings value:</label>
          <v-textarea outlined id="settings_value" v-model="editSettingsData.settings_value" :value="editSettingsData.settings_value"></v-textarea>

          <!-- SETTINGS HINT -->
          <label class="font-weight-bold" for="settings_hint">Settings Hint:</label>
          <v-text-field outlined id="settings_hint" v-model="editSettingsData.settings_hint" :value="editSettingsData.settings_hint"></v-text-field>

          <v-divider></v-divider>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="$emit('emitFunc', { function: 'closeSettingsAddEditDialog' })"> Bezárás </v-btn>

        <v-btn class="green white--text" @click="addSetting()" v-if="functionChoose == 'add'" :loading="funcSubmitLoading">Hozzáadás</v-btn>
        <v-btn class="orange white--text" @click="editSetting()" v-if="functionChoose == 'edit'" :loading="funcSubmitLoading">Szerkesztés</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    settingsData: {
      type: Object,
    },
    functionChoose: String,
  },
  data() {
    return {
      show: true,
      validationErrorsList: false,
      funcSubmitLoading: false,
      settingsCategories: [],
    }
  },
  methods: {
    getSettingsCategories() {
      this.funcSubmitLoading = true

      this.axios
        .get('settings/categories')
        /* eslint-disable no-unused-vars */
        .then((result) => {
          /* eslint-disable no-unused-vars */
          //this.settingsCategories = Object.entries(result.data.response.data).map(([key, value]) => value)

          for (const [key, value] of Object.entries(result.data.response.data)) {
            this.settingsCategories.push(result.data.response.data[key].settings_category_title)

            //this.settingsCategories.prototype.push(result.data.response.data[key].settings_category_title)

            // this.settingsCategories =
          }

          //this.settingsCategories = this.convertIntObj(result.data.response.data.settings_category_title)
         

          this.funcSubmitLoading = false
        })
        
    },

    verrMsg(verrs) {
      var html = ''

      for (const [key, value] of Object.entries(verrs)) {
        html = html + '<b>' + key + '</b> - ' + value + '<br/>'
      }

      return html
    },
    setSubmitLoading() {
      if (this.funcSubmitLoading === true) {
        setTimeout(() => {
          this.funcSubmitLoading = false
        })
      } else {
        this.funcSubmitLoading = true
      }
    },
    editSetting() {
      this.setSubmitLoading()

      this.$confirm({
        message: `Szerkeszted ezt a beállítást?`,
        button: {
          no: 'Mégse',
          yes: 'Szerkesztés',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            
            
            this.axios
              .post('settings/edit', {
                data: this.editSettingsData,
              })
              .then((result) => {
                this.setSubmitLoading()

                
                if (result.data.error) {
                  if (result.data.validation_errors) {
                    this.$store.state.snackbar = { show: true, message: '<i class="fas fa-exclamation-triangle orange--text"></i> <b>Nem megfelelő adatok</b><p class="pa-3">' + this.verrMsg(result.data.validation_errors) + '</p>' }
                  } else {
                    this.$store.state.snackbar = { show: true, message: '<i class="fas fa-exclamation-triangle orange--text"></i> ' + result.data.message + '' }
                  }
                } else {

                  this.funcSubmitLoading = true

                  this.$emit('emitFunc', { function: 'settingsEdited', saved_data: result.data.saved_data })

                 // this.$store.state.snackbar = { show: true, message: '<i class="fas fa-check-circle green--text"></i> ' + result.data.message + '' }

                  this.$toast.success(result.data.message)

                 
                }


              })

          } else {
            this.setSubmitLoading()
          }
        },
      })
    },
    addSetting() {
      this.setSubmitLoading()

      this.$confirm({
        message: `Hozzáadod ezt a beállítást?`,
        button: {
          no: 'Mégse',
          yes: 'Hozzáadás',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {


          if (confirm) {
            this.axios
              .post('settings/add', {
                data: this.editSettingsData,
              })
              .then((result) => {
                this.setSubmitLoading()

                  if (result.data.error) {
                    if (result.data.validation_errors) {
                      this.$store.state.snackbar = { show: true, message: '<i class="fas fa-exclamation-triangle orange--text"></i> <b>Nem megfelelő adatok</b><p class="pa-3">' + this.verrMsg(result.data.validation_errors) + '</p>' }
                    } else {
                      this.$store.state.snackbar = { show: true, message: '<i class="fas fa-exclamation-triangle orange--text"></i> ' + result.data.message + '' }
                    }
                  } else {
                    this.funcSubmitLoading = true

                    this.$emit('emitFunc', { function: 'settingsAdded', saved_data: result.data.saved_data })

                   this.$store.state.snackbar = { show: true, message: '<i class="fas fa-check-circle green--text"></i> ' + result.data.message + '' }

                  }
              })
              .catch((err) => {
              
                this.setSubmitLoading()
              })
          } else {
            this.setSubmitLoading()
          }
        },
      })
    },
  },

  computed: {
    //msgState() {return this.$store.state.messageDialog},
    editSettingsData: function () {
      if (this.functionChoose == 'edit') {
        return JSON.parse(JSON.stringify(this.settingsData))
      } else {
        return {
          settings_title: '',
          settings_category_title: '',
          settings_hint: '',
          settings_param: '',
          settings_value: '',
        }
      }
    },
    functionChoosed: function () {
      return this.functionChoose
    },
  },
  created() {},
  mounted() {
    this.getSettingsCategories()
    
  },
}
</script>
<style scoped>
.v-err-item {
  min-height: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
</style>
