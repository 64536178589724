import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notification_count: 0,
    settings: false,
    snackbar: {
      color: false,
      message: false,
      show: false,
      timeout: 2000,
    
    },
    ismobile: (window.innerWidth <= 560 ? true : false)
  },
  mutations: {
    getSettings (state) {

     axios.get("settings/all").then((result) => {
      if(result.data.error === false){
        state.settings = result.data.response
      }
    })
    },
    getNotifications(state){

      axios
      .get('log/notifications')
      .then(result => {
          if(Object.prototype.hasOwnProperty.call(result.data, "unread_notifications")){
              state.notification_count = result.data.unread_notifications
              
           }
           if(Object.prototype.hasOwnProperty.call(result.data, "log_types")){
                state.notification_log_types = JSON.parse(result.data.log_types)
                
                
           }

        })

    }
    

  }
})
