<template>
  <div>
   
      <v-card   class="pa-0 pt-3 elevation-0 scrollable mb-2" max-height="200px" v-if="Object.keys(hasProblems).length > 0" >
       <v-card-title dense class="pa-0 px-2 grey lighten-2 text-body-1"><v-progress-circular indeterminate v-if="loading" size="15" class="mr-2 green--text"></v-progress-circular> Túl sok lekérés </v-card-title>
      
        
        <v-card-text  class="pa-0"  >
          <v-card-title  v-for="(cms, index) in hasProblems" :key="index" class="pa-0 my-2 text-caption red lighten-5 dotted">
          <i class="fas fa-exclamation red--text" v-for="index in 3" :key="index"></i> <span class="mx-2">TOO MANY CONNECTIONS:</span> 
          
             
           [ <MomentAgo :date="cms.too_many_connections"></MomentAgo> ]
           <v-img class="ml-1" v-if="$store.state.settings['' + cms.site_cms + '_pushover_icon']" :src="$store.state.settings['' + cms.site_cms + '_pushover_icon']" max-width="30px"  />
          <span class=" font-weight-bold">{{cms.site_cms.toUpperCase()}} </span>
          
          <v-spacer></v-spacer>
          <v-btn x-small class="red white--text" @click="resetTooManyConnections(cms.site_cms)">RESET</v-btn>
          </v-card-title>
        </v-card-text>
      
        
      </v-card>
     
    
  

</div>
</template>
<script>
import MomentAgo from '../components/settings/Momentago'

export default {
  components: {
    MomentAgo,
   
  },

  
  data() {
    return {
      hasProblems: false,
      loading: false,
    }
  },

  methods: {

    
      resetTooManyConnections(cms){

            

            this.$http.get('shopurls/resettoomanyconnections?site_cms=' + cms).then((result) => {
              if (result.data.error === true) {
                alert('Hiba történt')
              } else {
                
                 this.$toast.success('RESET: ' + cms + ' - Túl sok lekérés', {
                  timeout: 2000,
                })
                this.getProblems()
              }

              
          });

          },

    getProblems() {
      this.loading = true

      this.$http.get('shopurls/gettoomanyconnections?').then((result) => {
        if (result.data.error === true) {
          alert('Hiba történt')
        } else {
          
          this.hasProblems = result.data.all_cms
        }

        setTimeout(() => {

            
        this.loading = false

        },1000)
      })
    },
  },
  mounted() {
    

    setInterval(() => {
      this.getProblems()
    }, 10000)
  },
  created(){
      this.getProblems()
  }
}
</script>