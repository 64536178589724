<template>
  <div>


    <v-card>
      <v-toolbar color="">
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Your Dashboard</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-tabs="title"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :value="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
     </v-card>

  </div>
</template>

<script>
 // import LineChart from '../components/charts/Pie.vue'

  export default {
    components: {
   //   LineChart
    },
    data () {
      return {
        datacollection: {},
        tab: null,
        items: [
          'web', 'shopping', 'videos', 'images', 'news',
        ],
        txt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
        
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>

