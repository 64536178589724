import MomentAgo from '../components/settings/Momentago'
import TimeDiff from '../components/settings/TimeDiff'
export default {
  components: {
    MomentAgo,
    TimeDiff
  },

  data() {
    return {
      
      loading_all_unique_data: false,
      allCms: [],
      allSitePrefix: [],
      allCategory: [],
      globalFilters: {},
      scroll: true,
      scroll_value: 0,
      ismobile: (window.innerWidth <= 560 ? true : false)
     
    }
  },
  methods: {

   deleteShareList(name){

      this.$confirm({
              message: `Elmentett lista törlése?`,
              button: {
                no: 'Mégse',
                yes: 'Yap',
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: (confirm) => {
                if (confirm) {

                  this.axios.get('products/deletesharelist?name=' + name)
                  .then(result => {
                    
      
                    if(result.data.error == false){
      
                     this.$toast.success(result.data.message, {
                        timeout: 2000,
                      })
      
                      this.$store.commit('getSettings')
                    
      
      
                    }else if(result.data.error == true){
      
                      this.$toast.warning(result.data.message, {
                        timeout: 2000,
                      })
      
                    }else{
      
                       this.$toast.error("Hiba történt!", {
                        timeout: 2000,
                      })
      
                    }
                    
                  });


                }
              }
              });
      
      
          },
    productNameToUrl(pname){


      pname = pname.replace('-','')
      pname = pname.trim()
      pname = pname.split(', ')
      
      
      //pname = pname[0].split(' - ')
      pname = pname[0]

      return pname

    }, 
     deepEntries( obj ){
      'use-strict';
      var allkeys, curKey = '[', len = 0, i = -1, entryK;
  
      function formatKeys( entries ){
         entryK = entries.length;
         len += entries.length;
         while (entryK--)
           entries[entryK][0] = curKey+JSON.stringify(entries[entryK][0])+']';
         return entries;
      }
      allkeys = formatKeys( Object.entries(obj) );
  
      while (++i !== len)
          if (typeof allkeys[i][1] === 'object' && allkeys[i][1] !== null){
              curKey = allkeys[i][0] + '[';
              Array.prototype.push.apply(
                  allkeys,
                  formatKeys( Object.entries(allkeys[i][1]) )
              );
          }
      return allkeys;
  },
    updateGlobalParam(data){


      // table, where, where_value, param, value

      // SAMPLE

      // shop_products (table), id (where) = 1 (where_value), discount_price (param) = value (value)

     
        return this.axios.post('settings/updateglobalparam', data).then((result) => {
        
          return result
           
         })

    },

    mFormat(price) {
      return new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', minimumFractionDigits: 0 }).format(price)
    },
    consolLog(data){

      console.log(data)

    },
    goToUrl(url, target = "blank"){

      window.open(url, target);

    },
    toTop (duration_value = 1000) {
      this.$vuetify.goTo(0, {duration: duration_value})
    },
    getFieldList(table){

     return this.axios.get('fields?' + '&table=' + table).then((result) => {
        
       return result
        
      })

    },
     htmlDecode(input) {
      var doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    },


    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    
    mysqlDateString(date) {

      date = new Date()

      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
        ].join(':')
      );
    },

    emitFunc(emitData){

      

      this[emitData.function](emitData)

  },

   escapeHtml(text) {
    var map = {
      '&': '&amp;',
     
      '"': '&quot;',
      "'": '&#039;'
    };
    
    return text.replace(/[&"']/g, function(m) { return map[m]; });
  },

    getIndexOfObject(obj, property, index){


     // return obj.map(function(e) { return e[property]; }).indexOf(index);

      index = obj.findIndex(x => x[property] === index);

      return index

    },

    convertIntObj(obj) {
      let res = {}

      for (const [key, value] of Object.entries(obj)) {
        if (value === "" || value == "false" || value === false || value === null){
            //Ha nincs értéke, kihagyás
        }else if(value === true){

          res[key] = "1"

        }else if(value === false){

          res[key] = "0"

        } else {

          if (!isNaN(value)) {
            res[key] = parseInt(value)
          } else {
            res[key] = value
          }
        }
      }

      return res
    },

    async copyText(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.success("Kimásolva ...", {
          timeout: 2000
        });
        
      } catch($e) {

        this.$toast.warning("Nem sikerült vágólapra másolni", {
          timeout: 2000
        });
        
      }
    },

    setGlobalOnOffSwitch(){

      var state = (this.$store.state.settings.global_turn_off == 0 ? 1 : 0) 

      this.$http.get('settings/setglobalswitch?state=' + state).then((result) => {
        if (result.data.error === true) {
          this.$toast.error(result.data.message, {
            timeout: 2000,
          })
        } else {
          this.$toast.success(result.data.message, {
            timeout: 2000,
          })
        }

        this.$store.commit('getSettings')

      });




    },
    getAllShopUrlUniqueData() {

      this.loading_all_unique_data = true

      let queryString = new URLSearchParams(this.globalFilters).toString()

      this.$http.get('shopurls/getalluniquedata?' + queryString).then((result) => {
        if (result.data.error === true) {
          alert(result.data.message)
        } else {
          this.allCms = []
          result.data.response.all_cms.filter((cms) => {
            return this.allCms.push(cms.site_cms)
          })
          this.allSitePrefix = []
          result.data.response.all_site_prefix.filter((prefix) => {
            return this.allSitePrefix.push(prefix.site_prefix)
          })
          this.allCategory = []
          result.data.response.all_category.filter((category) => {
            return this.allCategory.push(category.category)
          })
          

          //console.log(this.selectedCategory.filter((el) => this.allFilteredCategory.includes(el)))

          // console.log(this.allFilteredCategory)

         
          this.alertMessage = false
        }

        this.loading_all_unique_data = false
      })
    },

    getQueryParams() {
      let urlParams = new URLSearchParams(location.search)
      let qsArray = Object.fromEntries(urlParams)
      let vqsArray = this.convertIntObj(qsArray)

      return vqsArray
    },
    setRouterFromQueryFilterParams(){

        this.$router.push({ query: this.convertIntObj(this.globalFilters) }).catch(err => {
            err
        })

    }
  },
  

  created() {
      
    this.globalFilters = { ...this.globalFilters, ...this.getQueryParams() }

  },
  mounted(){

    /*
    window.onresize = () => {
      this.ismobile = (window.innerWidth <= 560 ? true : false)
      this.$store.state.ismobile = (window.innerWidth <= 560 ? true : false)
    }
    window.onscroll = () => {
        
        this.scroll = (document.documentElement.scrollTop <= this.scroll_value ? true : false)
       // console.log(this.$store.state.scroll)
        this.scroll_value = document.documentElement.scrollTop
       // console.log(this.$store.state.scroll)
      }
    */
   
  },

  watch: {
    
    
    globalFilters: {
      deep: true,
      handler() {
        this.setRouterFromQueryFilterParams()
      },
    },
    $route (){
     // this.globalFilters = { ...this.globalFilters, ...this.getQueryParams() }
    }

    
  },
}
