import { render, staticRenderFns } from "./Teszt.vue?vue&type=template&id=1ef8b063"
import script from "./Teszt.vue?vue&type=script&lang=js"
export * from "./Teszt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../AppData/Roaming/nvm/v16.0.0/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports