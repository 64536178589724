import { render, staticRenderFns } from "./SettingItem.vue?vue&type=template&id=04878616"
import script from "./SettingItem.vue?vue&type=script&lang=js"
export * from "./SettingItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../AppData/Roaming/nvm/v16.0.0/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports