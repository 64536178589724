import AddEditSettingsDialog from '../components/settings/AddEditSettingsDialog.vue'

import SettingItem from '../components/settings/SettingItem.vue'

export default {
  components: {
    AddEditSettingsDialog,
    SettingItem,
  },

  data() {
    return {
      showAddEditDialog: false,
      addEditFunction: '',
      addEditData: {},
    }
  },
  methods: {
    closeSettingsAddEditDialog() {
      this.showAddEditDialog = false
      this.addEditData = {}
    },
    SettingsAddEditDialog(func, data) {
      this.showAddEditDialog = true
      this.addEditFunction = func
      this.addEditData = data
     
    },

    editThisSetting(data) {
      this.showAddEditDialog = true
      this.addEditFunction = 'edit'
      this.addEditData = data.setting_data

      
    },

    deleteThisSetting(emitData) {
      this.$confirm({
        message: `Biztosan törlöd ezt a beállítást? (` + emitData.setting_data.settings_param + `)`,
        button: {
          no: 'Mégse',
          yes: 'Törlés',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.axios
              .post('settings/delete', {
                id: emitData.setting_data.id,
              })
              .then(result => {
                if (result.data.error) {
                  this.$notify.error(result.data.message)
                } else {

                  this.$store.commit('getSettings')

                  this.$toast.success(result.data.message)

                  const getIndex = this.getIndexOfObject(this.settingsList, 'id', emitData.setting_data.id)
                  this.$delete(this.settingsList, getIndex)

                  this.getSettingsCategories().then(() => {
                    if (this.settingsCategories[this.selectedTab]) {

                      this.setSelectedSettingsCategoriesTab(emitData.setting_data.settings_category)
                      this.loadSettingsByParams(this.settingsCategories[this.selectedTab].settings_category)

                    } else {

                      this.selectedTab = 0

                      if (this.settingsCategories.length > 0) {
                        this.loadSettingsByParams()
                      }
                      
                    }
                  })
                }
             
              })
              
          }
        },
      })
    },
  },
}
