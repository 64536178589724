import { render, staticRenderFns } from "./ShopUrls.vue?vue&type=template&id=01ab8d67"
import script from "./ShopUrls.vue?vue&type=script&lang=js"
export * from "./ShopUrls.vue?vue&type=script&lang=js"
import style0 from "./ShopUrls.vue?vue&type=style&index=0&id=01ab8d67&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../AppData/Roaming/nvm/v16.0.0/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports