<template>
  <div>
    <v-dialog v-model="function_add_edit.showdialog" max-width="800px" >

        <v-card >

            <v-card-title v-if="function_add_edit.function == 'edit' " class="deep-orange lighten-3 black--text">Szerkesztés</v-card-title>
            <v-card-title v-if="function_add_edit.function == 'add' " class="green lighten-3 black--text">Hozzáadás</v-card-title>
            <v-card-text class="pa-2" v-if="field_list.length > 0 && function_add_edit.form_data">

               <p v-for="(item, index) in field_list" :key="index">
                   <template>
                       <element v-if="item.type === 'mediumtext' || item.type === 'longtext'">

                           <v-textarea outlined :label="item.name" auto-grow v-model="function_add_edit.form_data[item.name]"></v-textarea>

                       </element>

                       <element v-if="item.type === 'int' && item.max_length === 1">

                           <v-checkbox v-model="function_add_edit.form_data[item.name]" true-value="1" false-value="0" :label="item.name" ></v-checkbox> 

                       </element>

                       <element v-if="item.type === 'varchar'">

                           <v-text-field v-model="function_add_edit.form_data[item.name]" :label="item.name" ></v-text-field>

                       </element>



                   </template>
               </p>
                
            </v-card-text>
            <v-card-actions >
                <v-btn outlined small @click="function_add_edit.showdialog = false"><i class="fas fa-edit"></i> Mégse</v-btn>
                <v-spacer></v-spacer>
                 <v-btn v-if="function_add_edit.function == 'edit'" color="deep-orange" outlined  @click="doEdit()"><i class="fas fa-edit"></i> Módosítás</v-btn>
                 <v-btn v-if="function_add_edit.function == 'add'" color="green" outlined  @click="doAdd()"><i class="fas fa-plus"></i> Hozzáadás</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
    <v-toolbar elevation="1" dense>
        
        <i class="fas fa-car mr-1"></i> <b>Használtauto.hu</b> - kereséseim (<span>{{ hahuSearchList.length }} </span >)
        <v-spacer></v-spacer> <v-btn outlined small @click="addDialog()" color="green"><i class="fas fa-edit"></i></v-btn>
    </v-toolbar>
    <v-toolbar class="grey lighten-2" elevation="1">
      <v-text-field placeholder="Gyorskeresés" class="shrink" v-model="filters.search" style="width: 300px; height: 30px;" outlined dense background-color="white">
        <v-icon slot="prepend" color="black">
          fab fa-searchengin
        </v-icon>
      </v-text-field>
      <v-checkbox class="ml-3 mt-7" v-model="filters.only_active" label="Csak aktív keresések"></v-checkbox>
    </v-toolbar>
        
    <template>
      <v-list v-if="!loading && hahuSearchList.length > 0" three-line>
        <v-list-item :class="index % 2 ? 'grey ligten-2' : 'grey lighten-3'" v-for="(item, index) in hahuSearchList" :key="index">
          <v-list-item-avatar class="mt-5" @click="toggleActive(item.id, index, 'active', item.active)">
            <i class="far fa-check-square fa-2x" :class="item.active === '1' ? 'green--text' : 'grey--text'"></i>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title v-html="item.description"></v-list-item-title>
            <v-list-item-subtitle v-html="item.last_refresh"></v-list-item-subtitle>
            <v-list-item-subtitle class="mt-2">
              <v-btn outlined small color="purple lighten-2">Frissítés</v-btn>
              <v-btn outlined small><i class="fas fa-list"></i></v-btn>
              <v-btn outlined small :href="item.url" target="_blank"><i class="fas fa-link"></i></v-btn>
              <v-btn outlined small @click="editDialog(index)"><i class="fas fa-edit"></i></v-btn>
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-alert class="mt-1" outlined prominent border="left" v-else-if="!loading && !hahuSearchList.length"> Úgy tűnik nincs találat! <span v-if="filters.length > 0">Esetleg töröld a beállított szűrőket</span> </v-alert>
    </template>

    <v-skeleton-loader v-if="loading" type="card-avatar, article, actions"></v-skeleton-loader>
  </div>
</template>

<script>
import global_mixin from '../mixins/global_mixin'
export default {
   mixins: [global_mixin],
  data() {
    return {
      function_add_edit: {},
      field_list: {},
      hahuSearchList: {},
      filters: {},
      loading: false,
    }
  },
  methods: {
    editDialog(index){


        this.$set(this.function_add_edit, "showdialog", true)
        this.$set(this.function_add_edit, "form_data",  JSON.parse(JSON.stringify(this.hahuSearchList[index])))
        this.$set(this.function_add_edit, "function", "edit")

    },
    addDialog(){

         this.$set(this.function_add_edit, "showdialog", true)
         this.$set(this.function_add_edit, "function", "add")
         this.$set(this.function_add_edit, "form_data",  {})

    },
    toggleActive(id, index, param, state) {

      var data = {
              id: id,
              param: 'active',
              new_state: state === '1' ? '0' : '1',
      }

      this.$confirm({
        message: `Biztos módosítod? [`+ param +` -> `+ data.new_state +` ]`,
        button: {
          no: 'Mégse',
          yes: 'Mehet',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            
            let queryString = new URLSearchParams(data).toString()

            this.axios.get('hahu/update-param?' + queryString).then(result => {
              if (result.data.error !== true) {
                this.hahuSearchList[index].active = data.new_state
              }
            })
          }
        },
      })
    },
    getFieldList(table){

      this.axios.get('fields?table=' + table).then(result => {
        if (Object.prototype.hasOwnProperty.call(result.data, 'fields')) {
            
          this.field_list = result.data.fields
          console.log(this.field_list)


        } else {
          this.field_list = {}
        }
      })


    },

    getSearches() {
      this.loading = true

      let queryString = new URLSearchParams(this.filters).toString()

      this.axios.get('hahu/getsearches?' + queryString).then(result => {
        if (Object.prototype.hasOwnProperty.call(result.data, 'response')) {
          this.hahuSearchList = result.data.response.data
        } else {
          this.hahuSearchList = {}
        }
      })

      this.loading = false
    },
  },
  mounted() {
    this.getSearches()
    this.getFieldList("hahu_search")
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getSearches()
      },
    },
  },
}
</script>
<style scoped>
/deep/ .v-list--three-line .v-list-item .v-list-item__content,
.v-list-item--three-line .v-list-item__content {
  align-self: auto !important;
}
</style>
