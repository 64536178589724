<template>
  <div>
    <v-card>
      <v-toolbar class="px-3" color="deep-orange lighten-2 white--text" dark dense>
        <v-toolbar-title><i class="fas fa-cog"></i> Beállítások</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white black--text" class="mr-1" small @click="getSettingsCategories()" :loading="loadingCategories"><i class="fas fa-sync-alt"></i></v-btn>
        <v-btn @click="SettingsAddEditDialog('add', {})" small color="green lighten-2"><i class="fas fa-plus"></i></v-btn>
      </v-toolbar>

      <v-toolbar color="deep-orange lighten-3 px-2 black--text" dark flat dense>
        <template>
          <v-tabs color="black" v-model="selectedTab" align-with-title show-arrows center-active next-icon="fas fa-angle-right " prev-icon="fas fa-angle-left small">
            <v-spacer></v-spacer>

            <v-tab class="black--text" v-for="(item, index) in settingsCategories" :key="index" @click="selectedTab = index">
              {{ item.settings_category_title }}
              <span class="pa-1"> ( {{ item.count_settings }} )</span>
            </v-tab>

            <v-spacer></v-spacer>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>

    <AddEditSettingsDialog v-if="showAddEditDialog" :showDialogProp="showAddEditDialog" :functionChoose="addEditFunction" :settingsData="addEditData" @emitFunc="emitFunc($event)" />

    <template v-if="settingsCategories.length == 0 && !loadingCategories">
      <v-alert text outlined  class="my-2 deep-orange lighten-4 " icon="mdi-fire red--text">
        Úgy tűnik nincsenek még létrehozva beállítások! Adj hozzá egyet most:  <v-btn @click="SettingsAddEditDialog('add', {})"  color="green darken-2" x-small ><i class="fas fa-plus white--text"></i></v-btn>
      </v-alert>
    </template>

    <template v-if="settingsList.length > 0">
      <SettingItem v-for="settings_item of settingsList" :key="settings_item.id" :prop_settings_data="settings_item" @emitFunc="emitFunc($event)" />
    </template>
    <template v-else>

      <v-skeleton-loader
          
          type="list-item-three-line"
        ></v-skeleton-loader>

    </template>
  </div>
</template>

<script>


import AddEditSettingsDialog from '../components/settings/AddEditSettingsDialog'
import SettingItem from '../components/settings/SettingItem.vue'
import settings_actions_mixin from '../mixins/settings_actions_mixin'
import global_mixin from '../mixins/global_mixin'

export default {
  mixins: [settings_actions_mixin, global_mixin],

  components: {
    AddEditSettingsDialog,
    SettingItem,
  },

  data() {
    return {
      selectedTab: 0,
      settingsCategories: [],
      settingsList: [],
      settingsListItemCounter: false,
      loadingCategories: false,
    }
  },
  methods: {
    settingsEdited(data){

      this.$store.commit('getSettings')
      


      this.showAddEditDialog = false

      this.getSettingsCategories().then(()=>{

        this.setSelectedSettingsCategoriesTab(data.saved_data.settings_category)

        this.loadSettingsByParams(this.settingsCategories[this.selectedTab].settings_category)

      })


    },
    settingsAdded(data) {

      this.$store.commit('getSettings')
      

      this.showAddEditDialog = false
      this.getSettingsCategories().then(() => {
        //this.selectedTab = this.getIndexOfObject(this.settingsCategories,'settings_category', data.saved_data.settings_category)
        

        this.setSelectedSettingsCategoriesTab(data.saved_data.settings_category)
        this.loadSettingsByParams(this.settingsCategories[this.selectedTab].settings_category)

      
      })
    },

   async getSettingsCategories() {
     
    return  this.axios
        .get('settings/categories')
        .then(result => {
          this.settingsCategories = result.data.response.data
          
          this.settingsList = {}
          this.loadSettingsByParams(this.settingsCategories[this.selectedTab].settings_category)
          this.loadingCategories = false
        })
    },

    setSelectedSettingsCategoriesTab(category) {

      let checkIndex = this.getIndexOfObject(this.settingsCategories, 'settings_category', category)


      if (checkIndex > -1 && checkIndex !== 'undefined') {
        this.selectedTab = checkIndex
      
      } else {
        this.selectedTab = 0

      
      }

    },

    async loadSettingsByParams(category = null, search = null) {
      
      return this.axios
        .get('settings/listby', {
          params: {
            settings_category: category,
            search: search,
          },
        })
        .then(result => {
          this.settingsList = result.data.response.data
        
        })
        
    },
  },
  created() {
    this.getSettingsCategories()
     
  },
  watch: {
    selectedTab: {
      deep: true,
      handler() {

          this.settingsList = {}
           this.loadSettingsByParams(this.settingsCategories[this.selectedTab].settings_category)
        
      },
    },
  },
}
</script>
