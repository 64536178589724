<template>
  <div class="container">
    <v-row class="mt-0">
      <v-col cols="12" class="text-center">
        <v-row class="grey lighten-4 mt-1"><v-col cols="12" class="text-center">URL</v-col></v-row>
        <v-text-field dense outlined autofocus class="centered-input mt-5" v-model="curlOptions.url"></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-0 text-center">
        <v-btn color="success" @click="startCurl()" :loading="loading">Mehet</v-btn>
        <v-btn v-if="curl_data" class="grey lighten-4 ml-2" @click="dialog = true">Előző lekérdezés eredménye</v-btn>
        <v-btn v-if="loading" class="purple lighten-4 ml-2" @click="loading = false">Mégse</v-btn>
      </v-col>
    </v-row>

    <v-alert class="mt-2 black--text" text outlined color="deep-orange" icon="mdi-fire" v-if="alertMessage">
      <p class="pa-0 ma-0 black--text">{{ alertMessage }}</p>
    </v-alert>
    <v-row class="pa-3 pt-0 mt-0">
      <v-col cols="12">
        <v-switch inset label="Proxy" v-model="curlOptions.use_proxy" class="v-swith--inline"></v-switch>
        <v-switch class="v-swith--inline" inset label="POST REQUEST" v-model="curlOptions.curl_method_post"></v-switch>
        <v-switch class="v-swith--inline" inset label="CURL-IMPERSONATE" v-model="curlOptions.curl_impersonate"></v-switch>
      </v-col>
      <v-col cols="12" md="6">
        <v-slider v-model="curlOptions.curl_connect_timeout" color="orange" :label="'Connection Timeout (' + (curlOptions.curl_connect_timeout ? curlOptions.curl_connect_timeout : this.$store.state.settings.default_curl_connect_timeout) + ')'" min="1" max="20" thumb-label></v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-slider v-model="curlOptions.curl_request_timeout" color="orange" :label="'Request Timeout (' + (curlOptions.curl_request_timeout ? curlOptions.curl_request_timeout : this.$store.state.settings.default_curl_request_timeout) + ')'" min="1" max="20" thumb-label></v-slider>
      </v-col>
    </v-row>
   
    <v-expansion-panels>
      <v-expansion-panel v-if="curlOptions.use_proxy" class="green lighten-5">
        <v-expansion-panel-header>
          PROXY SETTINGS
          <span class="ml-3" v-if="curlOptions.use_proxy && curlOptions.use_proxy != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-text-field dense outlined v-model="curlOptions.curl_proxy" label="Proxy IP - PORT"></v-text-field>

            <v-text-field dense outlined v-model="curlOptions.curl_proxy_user_pass" label="AUTH"></v-text-field>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="curlOptions.curl_method_post" class="purple lighten-5">
        <v-expansion-panel-header>
          CURL POST METHOD SETTINGS
          <span class="ml-3" v-if="curlOptions.curl_method_post && curlOptions.curl_method_post != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-textarea dense outlined v-model="curlOptions.curl_post_method_data" label="POST DATA"></v-textarea>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

       <v-expansion-panel>
        <v-expansion-panel-header>
          USER AGENT
          <span class="ml-3" v-if="curlOptions.curl_user_agent && curlOptions.curl_user_agent != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-text-field dense outlined  v-model="curlOptions.curl_user_agent"></v-text-field>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          HTTP REFERER
          <span class="ml-3" v-if="curlOptions.curl_http_referer && curlOptions.curl_http_referer != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-text-field dense outlined v-model="curlOptions.curl_http_referer"></v-text-field>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          CURL OPTIONS
          <span class="ml-3" v-if="curlOptions.curl_options && curlOptions.curl_options != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-textarea auto-grow v-model="curlOptions.curl_options" outlined></v-textarea>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          CURL HEADERS
          <span class="ml-3" v-if="curlOptions.curl_headers && curlOptions.curl_headers != ''">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-select style="width: 30%;" v-model="curlOptions.curl_header_type" @change="set_curl_headers()" dense :items="['html', 'json', 'alza']" outlined></v-select>
            <v-textarea auto-grow v-model="curlOptions.curl_headers" outlined></v-textarea>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          COOKIE
          <span class="ml-3" v-if="(curlOptions.curl_cookie_text && curlOptions.curl_cookie_text != '') || (curlOptions.curl_use_cookie_file && curlOptions.curl_use_cookie_file != '')">
            <i class="fas fa-user-edit orange--text"></i>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <b>COOKIE FÁJL NEVE? (valami.txt)</b>
          <v-text-field outlined dense v-model="curlOptions.curl_use_cookie_file"></v-text-field>
          <v-container fluid>
            <b>COOKIE TEXT (cookie: valami)</b>
            <v-textarea auto-grow v-model="curlOptions.curl_cookie_text" outlined></v-textarea>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-if="curl_data != false" ref="curldata">
      <v-dialog overlay-color="white" class="white" v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
       <v-toolbar dense class="grey darken-2 white--text">
       <v-spacer></v-spacer>  <button @click="dialog=false">Bezárás</button>
       </v-toolbar>
       <v-simple-table dense :class="!curl_data.error ? 'green lighten-4' : 'red lighten-4'">
          <tbody>
            <tr>
              <td class="text-center">
                <b>{{ curl_data.getinfo.url }}</b>
              </td>
            </tr>

            <tr>
              <td class="text-center">{{ curl_data.message }}</td>
            </tr>

            <tr>
              <td class="text-center">
                <span :class="curl_data.getinfo.http_code == '200' ? 'green' : 'orange'" class="ml-2 pa-1 white--text">{{ curl_data.getinfo.http_code }}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-toolbar dense><v-spacer></v-spacer><b>TITLE</b> - {{ curl_data.page_title }}<v-spacer></v-spacer></v-toolbar>
        <v-card class="white" elevation="0">
          <v-card-title v-if="curl_data.url != curl_data.getinfo.url" class="red lighten-2 text-center"> <b>URL ELTÉRÉS!</b> </v-card-title>

          <v-toolbar dense class="grey white--text">Információk</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in curl_data" :key="index">
                  <template v-if="index !== 'getinfo' && index !== 'curl_headers'">
                    <template v-if="index == 'response_file'">
                      <td class="text-left green lighten-4">{{ index }}</td>
                      <td class="text-left green lighten-4">
                        <a :href="'https://bb.supreme7.eu/log_files/' + item" target="_blank">{{ item }}</a>
                      </td>
                    </template>
                    <template v-else-if="index == 'saved_curl_response_json'">
                      <td class="text-left green lighten-4">{{ index }}</td>
                      <td class="text-left green lighten-4">
                        <a :href="'https://bb.supreme7.eu/log_files/' + item" target="_blank">{{ item }}</a>
                      </td>
                    </template>
                    <template v-else-if="index == 'curl_method_post' || index == 'curl_post_data'">
                      <td class="text-left yellow lighten-4">{{ index }}</td>
                      <td class="text-left yellow lighten-4">
                        <b>{{ item }}</b>
                      </td>
                    </template>
                    <template v-else-if="index == 'use_proxy' || index == 'curl_proxy'">
                      <td class="text-left orange lighten-4">{{ index }}</td>
                      <td class="text-left orange lighten-4">
                        <b>{{ item }}</b>
                      </td>
                    </template>
                    <template v-else-if="index == 'cookie_file' || index == 'cookie_text'">
                      <td class="text-left purple lighten-4">{{ index }}</td>
                      <td class="text-left purple lighten-4">
                        <b>{{ item }}</b>
                      </td>
                    </template>

                    <template v-else>
                      <td class="text-left">{{ index }}</td>
                      <td class="text-left">{{ item }}</td>
                    </template>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-toolbar dense class="grey white--text">CURL Headers</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in curl_data.curl_headers" :key="index">
                  <td class="text-left">{{ item }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-toolbar dense class="grey white--text">CURL GetInfo</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in curl_data.getinfo" :key="index">
                  <td class="text-left">{{ index }}</td>
                  <td class="text-left">{{ item }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import global_mixin from '../mixins/global_mixin'
export default {
   mixins: [global_mixin],
  data() {
    return {
      curlOptions: {
        curl_header_type: "html", 
        curl_http_referer: this.$store.state.settings.default_curl_http_referer,
        curl_options: this.$store.state.settings.default_curl_settings,
        curl_headers: this.$store.state.settings.default_curl_html_headers,
        curl_user_agent: this.$store.state.settings.default_curl_user_agent,
        curl_proxy: this.$store.state.settings.default_curl_proxy,
        curl_proxy_user_pass: this.$store.state.settings.default_curl_proxy_user_pass,
        curl_connect_timeout: this.$store.state.settings.default_curl_connect_timeout,
        curl_request_timeout: this.$store.state.settings.default_curl_request_timeout,
      },
      loading: false,
      curl_data: false,
      alertMessage: false,
      dialog: false,
    }
  },
  methods: {
    set_curl_headers(){

      switch(this.curlOptions.curl_header_type) {
        case "html":
          this.curlOptions.curl_headers = this.$store.state.settings.default_curl_html_headers
          break;
        case "json":
         this.curlOptions.curl_headers = this.$store.state.settings.default_curl_json_headers
          break;
        case "alza":
         this.curlOptions.curl_headers = this.$store.state.settings.default_alza_http_headers
          break;
        default:
         this.curlOptions.curl_headers = this.$store.state.settings.default_curl_html_headers
      }

    },
    startCurl() {
      this.loading = true

      this.curl_data = false

      let queryString = new URLSearchParams(this.curlOptions).toString()

      this.$http.get('curl?' + queryString + '').then((result) => {
        if (result.data.error === true) {
          this.alertMessage = result.data.message
        } else {
          this.curl_data = result.data.curl_data
          this.alertMessage = false
          this.dialog = true
        }

        this.loading = false
      })
    },
  },

}
</script>
<style>
.centered-input input,
.centered-input .v-label {
  text-align: center !important;
}
.v-swith--inline {
  display: inline-block !important;
  margin-left: 1rem !important;
}
</style>